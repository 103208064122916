import { IconsName } from "@Icons/type";

import Icon from "@Core/Icon";
import React from "react";
import Box from "./core/Box";
import Pressable from "./core/Pressable";
import Text from "./core/Text";

type SettingsItemProps = {
  title: string;
  icon: IconsName;
  onPress: () => void;
  rightIcon?: IconsName;
  variant?: "default" | "danger" | "valid" | "warning";
};

const SettingsItem = ({
  title,
  icon,
  onPress,
  rightIcon,
  variant = "default",
}: SettingsItemProps) => {
  const colors = {
    default: {
      bg: "grey1",
      icon: "grey4",
      text: "grey8",
    },
    danger: {
      bg: "red1",
      icon: "red4",
      text: "red8",
    },
    valid: {
      bg: "green1",
      icon: "green4",
      text: "green8",
    },
    warning: {
      bg: "yellow1",
      icon: "yellow4",
      text: "yellow8",
    },
  } as const;

  const { bg, icon: iconColor, text } = colors[variant];

  return (
    <Pressable
      paddingVertical="4"
      alignItems="center"
      onPress={onPress}
      flexDirection="row"
      style={({ pressed }) => ({ opacity: pressed ? 0.7 : 1 })}
    >
      <Box borderRadius="m" p="2" backgroundColor={bg}>
        <Icon name={icon} size={20} color={iconColor} />
      </Box>
      <Text ml="2" color={text}>
        {title}
      </Text>
      <Box flex={1} />
      {rightIcon && <Icon name={rightIcon} size={20} color={iconColor} />}
      <Icon name="chevron-forward" size={24} color="grey8" />
    </Pressable>
  );
};
export default SettingsItem;
