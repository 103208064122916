import { create } from "zustand";

type QRCodeStore = {
  isQRCodeButtonDisplayed: boolean;
  isQrCodeOpen: boolean;
  userPreference: "qrCode" | "badge";
  setIsQrCodeOpen: (isQrCodeOpen: boolean) => void;
  setUserPreference: (preference: "qrCode" | "badge") => void;
  openQRCode: () => void;
  openBadge: () => void;
  showQRCodeButton: () => void;
  hideQRCodeButton: () => void;
};
const useQRCodeStore = create<QRCodeStore>((set) => ({
  isQRCodeButtonDisplayed: true,
  userPreference: "qrCode",
  isQrCodeOpen: false,
  setIsQrCodeOpen: (isQrCodeOpen) => set({ isQrCodeOpen }),
  setUserPreference: (preference) => set({ userPreference: preference }),
  openQRCode: () => set({ isQrCodeOpen: true, userPreference: "qrCode" }),
  openBadge: () => set({ isQrCodeOpen: true, userPreference: "badge" }),
  showQRCodeButton: () => set({ isQRCodeButtonDisplayed: true }),
  hideQRCodeButton: () => set({ isQRCodeButtonDisplayed: false }),
}));

export default useQRCodeStore;
