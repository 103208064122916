export const TRACKING_EVENTS = {
  clickOnProductDetails: (properties: { name: string; id: number }) => ({
    name: "click-on-product-details",
    properties,
  }),
  clickOnAskOpinion: (properties: { opinion: number; orderId: number }) => ({
    name: "click-on-ask-opinion",
    properties,
  }),
  clickOnSendOpinion: (properties: { opinion: number; orderId: string }) => ({
    name: "click-on-send-opinion",
    properties,
  }),
  screenView: (properties: { screenName: string }) => ({
    name: "screen-view",
    properties,
  }),
} as const;

export type EventName = keyof typeof TRACKING_EVENTS;
