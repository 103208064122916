import FootprintByCaloriesGraph from "@Components/FootprintByCaloriesGraph";
import FootprintMealEmission from "@Components/FootprintMealEmission";
import FootprintMonthlyGraph from "@Components/FootprintMonthlyGraph";
import FootprintSummary from "@Components/FootprintSummary";
import Box from "@Core/Box";
import Footer from "@Core/Footer";
import React from "react";
import { ScrollView, StatusBar } from "react-native";

const CarbonFootprint = () => {
  const carbon = {
    saved: 4500000,
    footprint: 7500000,
  };

  const monthSaved = [
    { month: "07/23", carbon: 80 },
    { month: "06/23", carbon: 1500 },
    { month: "05/23", carbon: 100 },
    { month: "04/23", carbon: 1200 },
    { month: "03/23", carbon: 0 },
    { month: "02/23", carbon: 150 },
    { month: "01/23", carbon: 800 },
    { month: "12/22", carbon: 1350 },
  ].reverse();

  const footprintByCalories = { user: 2.7, ideel: 2.1, market: 3.5 };

  const mealTray = {
    material: 517,
    transport: 73,
    packaging: 18,
    storage: 36,
    fridge: 16,
  };

  return (
    <Box bg="grey1" flex={1}>
      <ScrollView>
        <FootprintSummary footprint={carbon.footprint} saved={carbon.saved} />

        <FootprintMonthlyGraph monthSaved={monthSaved} />

        <FootprintByCaloriesGraph
          user={footprintByCalories.user}
          ideel={footprintByCalories.ideel}
          market={footprintByCalories.market}
        />

        <FootprintMealEmission
          material={mealTray.material}
          transport={mealTray.transport}
          packaging={mealTray.packaging}
          storage={mealTray.storage}
          fridge={mealTray.fridge}
        />
        <Footer />
      </ScrollView>
      <StatusBar
        barStyle="light-content"
        backgroundColor="transparent"
        translucent
      />
    </Box>
  );
};

export default CarbonFootprint;
