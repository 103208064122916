import AsyncStorage from "@react-native-async-storage/async-storage";
import { createJSONStorage } from "zustand/middleware";

export const PERSIST_NAMES = {
  user: "user",
};

export const getStorage = <T>(store: keyof typeof PERSIST_NAMES) => ({
  name: PERSIST_NAMES[store] as keyof typeof PERSIST_NAMES,
  storage: createJSONStorage<T>(() => AsyncStorage),
});
