export const groupBy = <T, K extends keyof any>(
  list: T[],
  getKey: (item: T) => K
) =>
  list.reduce((previous, currentItem) => {
    const group = getKey(currentItem);
    // eslint-disable-next-line no-param-reassign
    if (!previous[group]) previous[group] = [];
    previous[group].push(currentItem);
    return previous;
  }, {} as Record<K, T[]>);

export const capitalizeFirstLetter = (string = "") =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const priceToString = (price = 0, noDecimal = false) => {
  const wholePart = Math.floor(price);
  const decimalPart = Math.round((price - wholePart) * 100);

  if (noDecimal) return `${wholePart}€`;
  return `${wholePart},${decimalPart < 10 ? `${decimalPart}0` : decimalPart}€`;
};

export const footPrintToString = (footPrint = 0, isEquivalent = false) => {
  const keyEnd = isEquivalent ? "co2" : "";
  if (footPrint < 1000)
    return {
      i18nKey: `CarbonFootprint.g${keyEnd}`,
      tOptions: { value: footPrint },
    };

  const wholePart = Math.floor(footPrint / 1000);

  const decimalPart = Math.round((footPrint / 1000 - wholePart) * 100);
  const tOptions = { value: wholePart, decimal: decimalPart };
  return {
    i18nKey: `CarbonFootprint.kg${keyEnd}${wholePart >= 100 ? "e" : ""}`,
    tOptions,
  };
};
