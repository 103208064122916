import Container from "@Components/Container";
import Box from "@Core/Box";
import Icon from "@Core/Icon";
import Text from "@Core/Text";
import { fonts } from "@Utils/theme";
import { Trans, useTranslation } from "react-i18next";

const FootprintByCaloriesGraph = ({
  market,
  ideel,
  user,
}: {
  market: number;
  ideel: number;
  user: number;
}) => {
  const { t } = useTranslation();

  const maxFootprint = Math.max(market, ideel, user);

  const FOOTPRINT_HEIGHT = 200;

  const height = {
    user: (user / maxFootprint) * FOOTPRINT_HEIGHT,
    ideel: (ideel / maxFootprint) * FOOTPRINT_HEIGHT,
    market: (market / maxFootprint) * FOOTPRINT_HEIGHT,
  };

  return (
    <Container pt="6" title={t("CarbonFootprint.understandTitle")}>
      <Box flexDirection="row">
        <Box height={FOOTPRINT_HEIGHT} width={2} bg="grey5" />
        <Box height={FOOTPRINT_HEIGHT} flex={1} justifyContent="flex-end">
          <Box
            ml="8"
            height={height.user}
            bg="purple4"
            borderTopRightRadius="l"
            borderTopLeftRadius="l"
            width={64}
          >
            <Text m="1" textAlign="center" color="white">
              {user}
            </Text>
          </Box>
          <FootprintLine
            bottom={height.market}
            color="red"
            text={`${market} - ${t("CarbonFootprint.understandMarketAverage")}`}
          />
          <FootprintLine
            bottom={height.ideel}
            color="green"
            text={`${ideel} - ${t("CarbonFootprint.understandIdeelAverage")}`}
          />
          <Box
            height={2}
            bg="grey5"
            position="absolute"
            bottom={0}
            left={0}
            right={0}
            borderRadius="full"
          />
        </Box>
      </Box>
      <Box mt="1" ml="8" width={64} alignItems="center">
        <Text color="grey8">{t("CarbonFootprint.you")}</Text>
      </Box>
      <Text
        mt="3"
        textAlign="center"
        fontFamily={fonts.regularItalic}
        color="grey8"
      >
        {t("CarbonFootprint.understandLegend")}
      </Text>
      <FootprintCompare
        i18nKey="CarbonFootprint.understandCompetitor"
        value={Math.floor((user - market) * 100) / 100}
      />
      <FootprintCompare
        i18nKey="CarbonFootprint.understandIdeel"
        value={Math.floor((user - ideel) * 100) / 100}
      />
    </Container>
  );
};

const FootprintLine = ({
  bottom,
  color,
  text,
}: {
  bottom: number;
  color: "red" | "green";
  text: string;
}) => (
  <Box
    flexDirection="row"
    gap="2"
    position="absolute"
    alignItems="center"
    bottom={bottom - 8}
  >
    <Box ml="4" height={2} width={98} bg={`${color}5`} borderRadius="full" />
    <Text variant="legend" color={`${color}7`}>
      {text}
    </Text>
  </Box>
);

const FootprintCompare = ({
  value,
  i18nKey,
}: {
  value: number;
  i18nKey: string;
}) => {
  const { t } = useTranslation();
  return (
    <Box flexDirection="row" alignItems="center" flex={1} gap="2" mt="4">
      <Box
        height={40}
        width={40}
        borderRadius="full"
        bg={value > 0 ? "red1" : "green1"}
        justifyContent="center"
        alignItems="center"
      >
        <Icon
          name={value > 0 ? "trending-down" : "trending-up"}
          size={24}
          color={value > 0 ? "red6" : "green6"}
        />
      </Box>
      <Box flex={1}>
        <Text textAlign="justify">
          <Trans
            t={t}
            tOptions={{
              value: Math.abs(value),
              adj: value > 0 ? "plus" : "moins",
            }}
            i18nKey={i18nKey}
            components={{
              b: (
                <Text
                  fontFamily={fonts.bold}
                  color={value > 0 ? "red6" : "green6"}
                />
              ),
            }}
          />
        </Text>
      </Box>
    </Box>
  );
};
export default FootprintByCaloriesGraph;
