import { allSavedCardsQuery, registerCardQuery } from "@Api/wallet.api";
import Info from "@Components/Info";
import Box from "@Core/Box";
import Loader from "@Core/Loader";
import useHideQrCode from "@Hooks/useHideQrCode";
import { AUTH_NAMES, AuthStackScreenProp } from "@Navigation/routes";
import BankForm from "@SmartComponents/BankForm";
import { useConfettiStore } from "@SmartComponents/Confetti";
import { useShowToast } from "@SmartComponents/Toast";
import { useTheme } from "@Utils/theme";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useTranslation } from "react-i18next";
import { StatusBar } from "react-native";

type AddCreditCardProps = AuthStackScreenProp<"AddCreditCard">;
const AddCreditCard = ({ navigation }: AddCreditCardProps) => {
  const { data: cardData, isFetching } = useQuery(registerCardQuery);
  const queryClient = useQueryClient();
  const onStartAnimation = useConfettiStore((state) => state.onStartAnimation);
  const { t } = useTranslation();
  const theme = useTheme();
  const showToast = useShowToast();

  useHideQrCode();

  const url = cardData?.url;

  if (!url || isFetching) return <Loader />;

  return (
    <Box flex={1} bg="grey1">
      <Info m="4" icon="information-circle">
        {t("AddCreditCard.info")}
      </Info>
      <BankForm
        canGoBack={navigation.canGoBack}
        goBack={navigation.goBack}
        url={url}
        onError={() => {
          showToast({
            type: "error",
            icon: "close-circle",
            message: t("AddCreditCard.errorMessage"),
            title: t("AddCreditCard.errorTitle"),
          });
        }}
        onSuccess={() => {
          queryClient
            .refetchQueries(allSavedCardsQuery.queryKey)

            .finally(() => {
              if (navigation.canGoBack()) navigation.goBack();
              else {
                navigation.replace(AUTH_NAMES.HOME);
              }
              onStartAnimation();
            });
        }}
      />

      <StatusBar
        barStyle="light-content"
        translucent
        backgroundColor={theme.colors.green6}
      />
    </Box>
  );
};

export default AddCreditCard;
