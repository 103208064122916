import React from "react";
import { StyleSheet } from "react-native";

import {
  Canvas,
  Group,
  RoundedRect,
  runTiming,
  Skia,
  useComputedValue,
  useValue,
  vec,
} from "@shopify/react-native-skia";
import { WINDOW_DIMENSION } from "@Utils/dimensions";
import theme from "@Utils/theme";
import { processTransform3d, toMatrix3 } from "react-native-redash";
import { create } from "zustand";

const NUM_OF_CONFETTI = 70;

const { height, width } = WINDOW_DIMENSION;

const relativeSin = (yPosition: number, offsetId: number) => {
  const rand = Math.sin((yPosition - 500) * (Math.PI / 540));
  const otherrand = Math.cos((yPosition - 500) * (Math.PI / 540));
  return offsetId % 2 === 0 ? rand : -otherrand;
};

interface Offset {
  offsetId: string;
  startingXOffset: number;
  startingYOffset: number;
  colorCode: number;
  colors: string[];
}

const ConfettiPiece = ({
  startingXOffset,
  startingYOffset,
  offsetId,
  colors,
  colorCode,
}: Offset) => {
  const onEndAnimation = useConfettiStore((state) => state.onEndAnimation);
  const WIDTH = 10;
  const HEIGHT = 30;
  const seed = Math.random() * 4;

  const centerY = useValue(0);
  const yPosition = useValue(startingYOffset);

  const origin = useComputedValue(() => {
    centerY.current = yPosition.current + HEIGHT / 2;
    const centerX = startingXOffset + WIDTH / 2;
    return vec(centerX, centerY.current);
  }, [yPosition]);

  runTiming(yPosition, height * 3, { duration: 3000 }, onEndAnimation);

  const matrix = useComputedValue(() => {
    const sin = relativeSin(yPosition.current, Math.round(Number(offsetId)));
    const rotateZ = sin * seed * 2.5;
    const rotateY = sin * seed * 1.5;
    const rotateX = sin * seed * 1.5;
    const mat3 = toMatrix3(
      processTransform3d([{ rotateY }, { rotateX }, { rotateZ }])
    );

    return Skia.Matrix(mat3);
  }, [yPosition]);

  return (
    <Group matrix={matrix} origin={origin}>
      <RoundedRect
        r={8}
        x={startingXOffset}
        y={yPosition}
        height={WIDTH}
        width={HEIGHT}
        color={colors[colorCode]}
      />
    </Group>
  );
};
type ConfettiStore = {
  confettiPieces: Offset[];
  animationEnabled: boolean;
  onStartAnimation: () => void;
  onEndAnimation: () => void;
};
export const useConfettiStore = create<ConfettiStore>((set) => ({
  animationEnabled: false,
  confettiPieces: [],
  onStartAnimation: () => {
    const confettiPieces: Offset[] = [];
    const colors = [
      theme.colors.green2,
      theme.colors.green4,
      theme.colors.green6,
      theme.colors.green8,
    ];
    for (let i = 0; i < NUM_OF_CONFETTI; i += 1) {
      const startingXOffset = Math.random() * width;
      const startingYOffset = -Math.random() * (height * 3);
      const id = `${i + Math.random()}`;
      confettiPieces.push({
        offsetId: id,
        startingXOffset,
        startingYOffset,
        colorCode: i % 5,
        colors,
      });
    }
    set({ animationEnabled: true, confettiPieces });
  },
  onEndAnimation: () => {
    set({ animationEnabled: false, confettiPieces: [] });
  },
}));

const Confetti = () => {
  const { animationEnabled, confettiPieces } = useConfettiStore();

  return animationEnabled ? (
    <Canvas pointerEvents="none" style={styles.confetti}>
      {confettiPieces.map((offset) => (
        <ConfettiPiece key={offset.offsetId} {...offset} />
      ))}
    </Canvas>
  ) : null;
};

export default Confetti;

const styles = StyleSheet.create({
  confetti: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
  },
});
