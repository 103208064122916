import Container from "@Components/Container";
import Box from "@Core/Box";
import Button from "@Core/Button";
import Footer from "@Core/Footer";
import { ControlledInput } from "@Core/Input";
import { AUTH_NAMES, AuthStackScreenProp } from "@Navigation/routes";
import { useShowToast } from "@SmartComponents/Toast";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useCallback, useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ScrollView, TextInput } from "react-native";

import { postUser, userQueryInfo } from "@Api/user.api";
import useUserStore from "@Stores/user.store";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import Text from "@Core/Text";

const profileSchema = z.object({
  firstName: z
    .string({ required_error: "profile.firstNameEmpty" })
    .trim()
    .min(2, {
      message: "profile.firstNameError",
    }),
  lastName: z
    .string({ required_error: "profile.lastNameEmpty" })
    .trim()
    .min(2, {
      message: "profile.lastNameError",
    }),
});

type ProfileSchema = z.infer<typeof profileSchema>;

const Profile = ({
  navigation,
}: AuthStackScreenProp<typeof AUTH_NAMES.PROFILE>) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const showToast = useShowToast();

  const updateInfo = useUserStore((state) => state.updateInfo);

  const { data: user } = useQuery(userQueryInfo);

  const { mutate } = useMutation({
    mutationFn: postUser,
    onSuccess: (data) => {
      updateInfo(data);
      queryClient.refetchQueries(userQueryInfo.queryKey);
    },
  });

  const onValid = useCallback(
    (data: ProfileSchema) => {
      mutate(data);
      navigation.goBack();
      showToast({
        type: "success",
        message: t("profile.toastProfileMessage"),
        icon: "checkmark-circle-outline",
        title: t("profile.toastProfileTitle"),
      });
    },
    [navigation, showToast, mutate, t]
  );
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: user?.firstName ?? "",
      lastName: user?.lastName ?? "",
    },

    mode: "onBlur",
    resolver: zodResolver(profileSchema),
  });

  const firstNameRef = useRef<TextInput>(null);
  const lastNameRef = useRef<TextInput>(null);

  return (
    <Box bg="grey1" flex={1}>
      <ScrollView>
        <Box gap="6" pt="6">
          <Container gap="4" paddingVertical="8">
            <Box>
              <Text variant="button">{t("profile.emailLabel")}</Text>
              <Box height={48} justifyContent="center">
                <Text ml="2" variant="input">
                  {user?.email}
                </Text>
              </Box>
            </Box>

            <ControlledInput
              label={t("profile.firstNameLabel")}
              placeholder={t("profile.firstNamePlaceholder") ?? ""}
              error={t(errors.firstName?.message as string) ?? ""}
              control={control}
              name="firstName"
              autoCorrect={false}
              returnKeyType="next"
              inputRef={firstNameRef}
              blurOnSubmit={false}
              onSubmitEditing={() => lastNameRef.current?.focus()}
            />
            <ControlledInput
              label={t("profile.lastNameLabel")}
              placeholder={t("profile.lastNamePlaceholder") ?? ""}
              error={t(errors.lastName?.message as string) ?? ""}
              control={control}
              inputRef={lastNameRef}
              autoCorrect={false}
              name="lastName"
              onSubmitEditing={handleSubmit(onValid)}
            />

            <Box height={1} bg="grey2" mb="4" />
            <Button onPress={handleSubmit(onValid)}>
              {t("profile.confirm")}
            </Button>
          </Container>
        </Box>
        <Footer />
        <Footer />
      </ScrollView>
    </Box>
  );
};

export default Profile;
