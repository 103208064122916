import { create } from "zustand";

type SinUpStore = {
  email?: string;
  code?: string;
  setEmail: (email: string) => void;
  setPassword: (password: string) => void;
};

const useSignUpStore = create<SinUpStore>((set) => ({
  email: undefined,
  code: undefined,
  setEmail: (email) => set({ email }),
  setPassword: (code) => set({ code }),
}));

export default useSignUpStore;
