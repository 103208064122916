// eslint-disable-next-line no-restricted-imports
import { getDefaultHeaderHeight } from "@react-navigation/elements";
// eslint-disable-next-line no-restricted-imports
import { Dimensions, Platform } from "react-native";

export const defaultHeaderHeight = getDefaultHeaderHeight(
  Dimensions.get("window"),
  false,
  0
);

const getWidth = (width: number) => {
  if (Platform.OS === "web") {
    return width > 700 ? 700 : width - 2;
  }
  return width;
};

export const WINDOW_DIMENSION = {
  width: getWidth(Dimensions.get("window").width),
  height: Dimensions.get("window").height,
};

export const SCREEN_DIMENSION = {
  width: getWidth(Dimensions.get("screen").width),
  height: Dimensions.get("screen").height,
};
