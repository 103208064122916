import { userQueryInfo } from "@Api/user.api";
import Box from "@Core/Box";
import Icon from "@Core/Icon";
import Pressable from "@Core/Pressable";
import Text from "@Core/Text";
import navigationRef from "@Navigation/navigationRef";
import { AUTH_NAMES } from "@Navigation/routes";
import useQRCodeStore from "@Stores/QRCode.store";
import useUserStore from "@Stores/user.store";
import { WINDOW_DIMENSION } from "@Utils/dimensions";
import { useTheme } from "@Utils/theme";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Animated, {
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import QRCodeModal from "./QRCodeModal";

const AnimatedText = Animated.createAnimatedComponent(Text);

const AnimatedBox = Animated.createAnimatedComponent(Box);

const TEXT_DIMENSION = 158 + 30;
const ICON_DIMENSION = 24;
const TEXT_ANIMATION_DURATION = 300;

const QRCodeButton = () => {
  const { bottom } = useSafeAreaInsets();
  const { t } = useTranslation();
  const theme = useTheme();
  const isTextVisible = useSharedValue(true);

  const { data: userData } = useQuery(userQueryInfo);

  const uid = useUserStore((state) => state.uid);
  const pairingCode = useUserStore((state) => state.pairingCode);

  const setUidAndCode = useUserStore((state) => state.setUidAndCode);

  const isQRCodeButtonDisplayed = useQRCodeStore(
    (state) => state.isQRCodeButtonDisplayed
  );
  const isQrCodeOpen = useQRCodeStore((state) => state.isQrCodeOpen);
  const setIsQrCodeOpen = useQRCodeStore((state) => state.setIsQrCodeOpen);

  const translateXButton = useDerivedValue(() =>
    isQRCodeButtonDisplayed ? 0 : WINDOW_DIMENSION.width
  );

  navigationRef.addListener("state", () => {
    isTextVisible.value = navigationRef.isReady()
      ? navigationRef.getCurrentRoute()?.name === AUTH_NAMES.HOME
      : true;
  });

  const textStyle = useAnimatedStyle(() => ({
    color: theme.colors.white,
    opacity: withTiming(isTextVisible.value ? 1 : 0, {
      duration: TEXT_ANIMATION_DURATION,
    }),
  }));
  const containerStyle = useAnimatedStyle(() => ({
    marginHorizontal: withTiming(
      isTextVisible.value ? theme.spacing[8] : theme.spacing[4],
      { duration: TEXT_ANIMATION_DURATION }
    ),
    maxWidth: withTiming(
      isTextVisible.value ? TEXT_DIMENSION : ICON_DIMENSION,
      {
        duration: TEXT_ANIMATION_DURATION,
      }
    ),
  }));

  const rootStyle = useAnimatedStyle(() => ({
    transform: [
      {
        translateX: withTiming(translateXButton.value, {
          duration: TEXT_ANIMATION_DURATION,
        }),
      },
    ],
  }));

  useEffect(() => {
    if (
      userData?.uid &&
      (userData.uid !== uid || userData.pairingCode !== pairingCode)
    ) {
      setUidAndCode(userData.uid, userData.pairingCode);
    }
  }, [userData, setUidAndCode, uid, pairingCode]);

  return (
    <>
      <QRCodeModal
        closeQrCodeModal={() => {
          setIsQrCodeOpen(false);
        }}
        isQrCodeOpen={isQrCodeOpen}
      />
      <AnimatedBox
        style={rootStyle}
        position="absolute"
        bottom={bottom}
        right={0}
        mr="4"
        mb="6"
      >
        <Pressable
          borderRadius="full"
          onPress={() => setIsQrCodeOpen(!isQrCodeOpen)}
          style={(state) => ({
            backgroundColor: state.pressed
              ? theme.colors.green6
              : theme.colors.green5,
          })}
        >
          <AnimatedBox
            height={56}
            marginHorizontal="8"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            style={containerStyle}
          >
            <Icon name="qr-code-outline" size={24} color="white" />
            <AnimatedText
              variant="button"
              ml="2"
              numberOfLines={1}
              fontSize={20}
              style={textStyle}
            >
              {t("qrCodeButton")}
            </AnimatedText>
          </AnimatedBox>
        </Pressable>
      </AnimatedBox>
    </>
  );
};

export default QRCodeButton;
