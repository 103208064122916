import axios, { AxiosError, AxiosPromise } from "axios";

export interface ApiError {
  type: string;
  error: string;
  title: string;
}
export type ApiErrors = ApiError[] | ApiError;

// TODO: use env variables
export const digitizUrl =
  "https://api.preprod.macantine.ideelgarden.com/api/app";

export const digitizeClient = axios.create({
  baseURL: digitizUrl,
});

// TODO: use env variables
const boUrl = "https://api.ideelgarden.com:8080/";
export const boClient = axios.create({
  baseURL: boUrl,
});

export async function apiWrapper<T>(
  call: AxiosPromise<unknown>,
  zodParser?: (data: unknown) => T
): Promise<T> {
  try {
    const { data } = await call;
    return zodParser ? zodParser(data) : (data as T);
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      const error = err as AxiosError<ApiErrors>;
      if (error.response) throw Object.assign(error.response.data);
    }

    const error: ApiError = {
      error: "Unknown",
      title: "Mince, une erreur est survenue",
      type: "error",
    };
    throw Object.assign(new Error(error.error), error);
  }
}
