import Box from "@Core/Box";
import Image from "@Core/Image";
import Text from "@Core/Text";
import React, { ComponentProps } from "react";

type CarbonEquivalentProps = {
  equivalent: number;
  color: "green" | "purple" | "red" | "yellow";
  name: string;
  image: ComponentProps<typeof Image>["source"];
};
const CarbonEquivalent = ({
  equivalent,
  image,
  color,
  name,
}: CarbonEquivalentProps) => (
  <Box
    justifyContent="center"
    alignItems="center"
    opacity={equivalent === 0 ? 0.2 : 1}
  >
    <Box
      width={48}
      height={48}
      bg={`${color}1`}
      borderRadius="full"
      justifyContent="center"
      alignItems="center"
    >
      <Image source={image} height={28} width={28} />
    </Box>
    <Text mt="1" variant="headline" color={`${color}6`}>
      {equivalent}
    </Text>
    <Text color={`${color}6`}>{name}</Text>
  </Box>
);
export default CarbonEquivalent;
