import digitiz from "@Assets/image/digitiz.webp";
import nu from "@Assets/image/nu.webp";
import Box from "@Core/Box";
import Footer from "@Core/Footer";
import Image from "@Core/Image";
import Pressable from "@Core/Pressable";
import Text from "@Core/Text";
import { UN_AUTH_NAMES, UnAuthStackScreenProp } from "@Navigation/routes";
import { NU_LINK } from "@Utils/links";
import { useTheme } from "@Utils/theme";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Linking, ScrollView, StatusBar } from "react-native";

type FridgeSelectionProps = UnAuthStackScreenProp<"FridgeSelection">;
const FridgeSelection = ({ navigation }: FridgeSelectionProps) => {
  const { t } = useTranslation();
  const [heightContent, setHeightContent] = useState(0);

  return (
    <Box
      onLayout={(layout) => setHeightContent(layout.nativeEvent.layout.height)}
      flex={1}
      bg="grey1"
    >
      <ScrollView>
        <Box
          gap="4"
          px="4"
          py="4"
          justifyContent="space-between"
          flex={1}
          minHeight={heightContent}
        >
          <Text variant="title">
            <Trans
              t={t}
              i18nKey="FridgeSelection.ask"
              components={{
                u: <Text variant="title" textDecorationLine="underline" />,
              }}
            />
          </Text>

          <Fridge
            onPress={() => {
              navigation.navigate(UN_AUTH_NAMES.AUTH_EMAIL);
            }}
            image={digitiz}
          />
          <Fridge
            onPress={() => {
              Linking.openURL(NU_LINK);
            }}
            image={nu}
          />
          <StatusBar
            translucent
            barStyle="light-content"
            backgroundColor="transparent"
          />
          <Footer hasNoQrCodeMargin />
        </Box>
      </ScrollView>
    </Box>
  );
};

const Fridge = ({
  onPress,
  image,
}: {
  onPress: () => void;
  image: typeof nu;
}) => {
  const theme = useTheme();
  return (
    <Pressable
      bg="green1"
      p="6"
      borderWidth={2}
      borderColor="green6"
      borderRadius="l"
      alignItems="center"
      style={({ pressed }) => ({
        backgroundColor: pressed ? theme.colors.green3 : theme.colors.green1,
        transform: [{ scale: pressed ? 0.95 : 1 }],
      })}
      onPress={onPress}
    >
      <Image
        source={image}
        contentFit="contain"
        width={{
          small: 100,
          phone: 150,
        }}
        height={{
          small: 150,
          phone: 200,
        }}
      />
    </Pressable>
  );
};

export default FridgeSelection;
