import { allSavedCardsQuery, deleteSavedCard } from "@Api/wallet.api";
import Container from "@Components/Container";
import EmptyState from "@Components/EmptyState";
import BottomModal from "@Core/BottomModal";
import Box from "@Core/Box";
import Button from "@Core/Button";
import Icon from "@Core/Icon";
import Loader from "@Core/Loader";
import Pressable from "@Core/Pressable";
import Text from "@Core/Text";
import useHideQrCode from "@Hooks/useHideQrCode";
import { AUTH_NAMES, AuthStackScreenProp } from "@Navigation/routes";
import { useShowToast } from "@SmartComponents/Toast";
import { cardIsCC, cardIsValid } from "@Utils/card";
import { fonts } from "@Utils/theme";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView } from "react-native";
import Animated, { Layout, ZoomIn, ZoomOut } from "react-native-reanimated";
import { useSafeAreaInsets } from "react-native-safe-area-context";

type Props = AuthStackScreenProp<"CreditCardList">;

const AnimatedBox = Animated.createAnimatedComponent(Box);

const CreditCardList = ({ navigation }: Props) => {
  const { t } = useTranslation();
  const { bottom } = useSafeAreaInsets();
  const showToast = useShowToast();

  const [idDeleteCard, setIdDeleteCard] = useState<number | null>(null);
  const [isReplacingCard, setIsReplacingCard] = useState(false);

  const {
    data: allSavedCardsData,
    isLoading,
    refetch,
  } = useQuery({
    ...allSavedCardsQuery,
    staleTime: 0,
    refetchOnMount: true,
  });

  const { mutate, isLoading: isDeleting } = useMutation({
    mutationFn: deleteSavedCard,
    onSettled: () => refetch(),
    onSuccess: () => {
      if (isReplacingCard) {
        navigation.navigate(AUTH_NAMES.ADD_CREDIT_CARD);
      }

      showToast({
        icon: "checkmark-circle",
        message: t("CreditCardList.deleteCreditCardSuccessMessage"),
        title: t("CreditCardList.deleteCreditCardSuccessTitle"),
        type: "success",
      });
    },
  });

  const onHasClosed = useCallback(() => {
    setIsReplacingCard(false);
  }, []);
  useHideQrCode();

  if (isLoading) return <Loader />;

  const creditCards = (allSavedCardsData?.filter(cardIsCC) || []).map(
    (card) => ({
      id: card.id,
      isValid: cardIsValid(card),
      text: card.pan.includes("X")
        ? card.pan.match(/.{1,4}/g)?.join(" ")
        : `XXXX XXXX XXXX ${card.pan}`,
      expirationDate: card.expirationDate,
    })
  );

  return (
    <Box flex={1} bg="grey1" gap="4" pt="4">
      {creditCards.length === 0 ? (
        <Box flex={1} p="6">
          <EmptyState
            icon="card-outline"
            title={t("CreditCardList.emptyStateTitle")}
            text={t("CreditCardList.emptyStateText")}
          />
        </Box>
      ) : (
        <ScrollView style={{ flex: 1 }}>
          <Container flex={1}>
            {creditCards.map((card) => (
              <AnimatedBox
                entering={ZoomIn}
                exiting={ZoomOut}
                layout={Layout.delay(200)}
                key={card.id}
                borderWidth={1}
                borderColor={!card.isValid ? "red3" : "grey2"}
                bg={!card.isValid ? "red4" : "grey8"}
                height={180}
                my="2"
                p="4"
                gap="4"
                alignItems="flex-end"
                justifyContent="flex-end"
                borderRadius="l"
              >
                <Pressable
                  borderWidth={1}
                  borderColor="white"
                  borderRadius="m"
                  p="1"
                  onPress={() => setIdDeleteCard(card.id)}
                >
                  <Icon name="trash-outline" color="white" size={24} />
                </Pressable>
                <Box flex={1} />
                <Box alignSelf="flex-start">
                  <Text color="white" variant="button">
                    {card.text}
                  </Text>
                </Box>
                <Text variant="legend" color="white">
                  <Text variant="legend" fontFamily={fonts.bold} color="white">
                    {t("CreditCardList.expirationDate")}
                  </Text>
                  {card.expirationDate.format("MM/YY")}
                </Text>
              </AnimatedBox>
            ))}
          </Container>
        </ScrollView>
      )}
      <Button
        mx="4"
        isLoading={isDeleting}
        disabled={isDeleting}
        onPress={() => {
          if (creditCards.length === 0) {
            navigation.navigate(AUTH_NAMES.ADD_CREDIT_CARD);
          } else {
            setIsReplacingCard(true);
            setIdDeleteCard(creditCards[0].id);
          }
        }}
      >
        {creditCards.length === 0
          ? t("CreditCardList.addCreditCard")
          : t("CreditCardList.replaceCreditCard")}
      </Button>
      <Box height={bottom} />
      <BottomModal
        visible={idDeleteCard !== null}
        onClose={() => setIdDeleteCard(null)}
        borderRadius={8}
        onHasClosed={onHasClosed}
      >
        <Box
          flex={1}
          py="6"
          px="6"
          gap="3"
          justifyContent="center"
          alignItems="center"
        >
          <Text variant="title" textAlign="center">
            {isReplacingCard
              ? t("CreditCardList.deleteCreditCardReplaceTitle")
              : t("CreditCardList.deleteCreditCardTitle")}
          </Text>
          <Text>
            {isReplacingCard
              ? t("CreditCardList.deleteCreditCardReplaceMessage")
              : t("CreditCardList.deleteCreditCardMessage")}
          </Text>
          <Button
            variant={isReplacingCard ? "secondary" : "errorSecondary"}
            onPress={() => {
              setIdDeleteCard(null);
              setIsReplacingCard(false);
            }}
          >
            {t("CreditCardList.deleteCreditCardCancel")}
          </Button>
          <Button
            variant={isReplacingCard ? "primary" : "errorPrimary"}
            onPress={() => {
              if (idDeleteCard) mutate({ id: idDeleteCard });
              setIdDeleteCard(null);
            }}
          >
            {t("CreditCardList.deleteCreditCardDelete")}
          </Button>
        </Box>
      </BottomModal>
    </Box>
  );
};

export default CreditCardList;
