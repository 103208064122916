import Leaf from "@Icons/Leaf";

import React, { useEffect } from "react";
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withTiming,
} from "react-native-reanimated";
import { Theme, useTheme } from "@Utils/theme";
import Box from "./Box";

type Props = {
  borderColor?: keyof Theme["colors"];
  leafColor?: keyof Theme["colors"];
  /** leaf size default 48 */
  size?: number;
  /** padding default 20 */
  padding?: number;

  noLeaf?: boolean;
};

const DEFAULT_SIZE = 48;
const DEFAULT_PADDING = 20;
const SCALE_MAX = 1.2;
const SCALE_MIN = 1;
const ROTATION_MAX = 360;
const ROTATION_MIN = 0;
const DURATION = 1000;

const AnimatedBox = Animated.createAnimatedComponent(Box);

const Loader = ({ noLeaf, ...props }: Props) => {
  const theme = useTheme();

  const defaultProps = {
    borderColor: "grey3",
    leafColor: "yellow5",
    size: DEFAULT_SIZE,
    padding: DEFAULT_PADDING,
  } satisfies Props;

  const { borderColor, leafColor, size, padding } = {
    ...defaultProps,
    ...props,
  };

  const rotation = useSharedValue(ROTATION_MIN);
  const scale = useSharedValue(SCALE_MIN);

  useEffect(() => {
    rotation.value = withRepeat(
      withTiming(ROTATION_MAX, { duration: DURATION, easing: Easing.linear }),
      -1
    );
    scale.value = withRepeat(
      withTiming(SCALE_MAX, { duration: DURATION }),
      -1,
      true
    );
  }, [rotation, scale]);

  const borderStyle = useAnimatedStyle(() => ({
    transform: [{ rotate: `${rotation.value}deg` }],
  }));

  const leafStyle = useAnimatedStyle(() => ({
    transform: [{ scale: scale.value }],
  }));
  

  return (
    <Box flex={1} justifyContent="center" alignItems="center">
      <AnimatedBox
        height={size + 2 * padding}
        width={size + 2 * padding}
        borderRadius="full"
        borderWidth={2}
        borderRightWidth={0}
        position="absolute"
        borderColor={borderColor}
        style={borderStyle}
      />
      <AnimatedBox style={leafStyle}>
        {!noLeaf && <Leaf size={size} color={theme.colors[leafColor]} />}
      </AnimatedBox>
    </Box>
  );
};

export default Loader;
