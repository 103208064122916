import React from "react";
import Text from "@Core/Text";
import Box, { BoxProps } from "./core/Box";

type ContainerProps = {
  children: React.ReactNode;
  title?: string | null;
} & BoxProps;
const Container = ({ children, title, ...props }: ContainerProps) => (
  <Box flexDirection="column">
    {title ? (
      <Text variant="button" ml="4" mb="2" mt="8">
        {title}
      </Text>
    ) : null}
    <Box
      bg="white"
      p="4"
      borderTopWidth={1}
      borderBottomWidth={1}
      borderColor="grey2"
      {...props}
    >
      {children}
    </Box>
  </Box>
);

export default Container;
