import { deleteUser } from "@Api/user.api";
import { allSavedCardsQuery, walletBalanceQuery } from "@Api/wallet.api";
import Container from "@Components/Container";
import SettingsItem from "@Components/SettingsItem";
import BottomModal from "@Core/BottomModal";
import Box from "@Core/Box";
import Button from "@Core/Button";
import Footer from "@Core/Footer";
import Text from "@Core/Text";
import { AUTH_NAMES, AuthStackScreenProp } from "@Navigation/routes";
import useQRCodeStore from "@Stores/QRCode.store";
import useUserStore from "@Stores/user.store";
import { getCCStatus } from "@Utils/card";
import { POLICY_LINK } from "@Utils/links";
import { fonts, useTheme } from "@Utils/theme";
import { useMutation, useQuery } from "@tanstack/react-query";
import { nativeApplicationVersion } from "expo-application";
import { updateId } from "expo-updates";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Linking, ScrollView, StatusBar } from "react-native";

const Settings = ({
  navigation,
}: AuthStackScreenProp<typeof AUTH_NAMES.SETTINGS>) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const loggedOut = useUserStore((state) => state.loggedOut);
  const openQRCode = useQRCodeStore((state) => state.openQRCode);
  const openBadge = useQRCodeStore((state) => state.openBadge);
  const { mutate } = useMutation({
    mutationFn: deleteUser,
    onSuccess: () => {
      loggedOut();
    },
  });

  const { data: cardsData } = useQuery(allSavedCardsQuery);
  const { data: walletData } = useQuery(walletBalanceQuery);

  const [isModalVisible, setModalVisible] = useState(false);

  const ccStatus = getCCStatus(cardsData, (walletData?.balance || 0) && 0);

  const walletStatus =
    ((walletData?.balance || 0) && 0) > 0 ? "valid" : "default";

  const cardItems = {
    noCard: {
      variant: "default",
      rightIcon: undefined,
    },
    validCC: {
      variant: "valid",
      rightIcon: "checkmark-circle",
    },
    invalidCC: {
      variant: "warning",
      rightIcon: "warning",
    },
    noCC: {
      variant: "danger",
      rightIcon: "warning",
    },
  } as const;

  return (
    <Box bg="grey1" flex={1}>
      <ScrollView>
        <Box gap="6" pt="6">
          <Container>
            <SettingsItem
              title={t("settings.profile")}
              icon="person"
              onPress={() => navigation.navigate(AUTH_NAMES.PROFILE)}
            />
            <SettingsItem
              title={t("settings.history")}
              icon="cart"
              onPress={() => navigation.navigate(AUTH_NAMES.HISTORY)}
            />
            <SettingsItem
              title={t("qrCode.title")}
              icon="qr-code"
              onPress={openQRCode}
            />
            <SettingsItem
              title={t("badge.title")}
              icon="card-outline"
              onPress={openBadge}
            />
          </Container>

          <Container>
            <SettingsItem
              title={t("settings.payment")}
              variant={cardItems[ccStatus].variant}
              icon="card"
              rightIcon={cardItems[ccStatus].rightIcon}
              onPress={() => navigation.navigate(AUTH_NAMES.CREDIT_CARD_LIST)}
            />
            <SettingsItem
              title={t("settings.restaurant")}
              icon="wallet"
              onPress={() => {}}
            />
            <SettingsItem
              title={t("settings.wallet")}
              icon="cash"
              variant={walletStatus}
              rightIcon={
                walletStatus === "valid" ? "checkmark-circle" : undefined
              }
              onPress={() => navigation.navigate(AUTH_NAMES.WALLET)}
            />
          </Container>
          <Container>
            <SettingsItem
              title={t("settings.issues")}
              icon="help"
              onPress={() => navigation.navigate(AUTH_NAMES.CRISP_CHAT)}
            />
            {/*   <SettingsItem
              title={t("settings.subventions")}
              icon="search"
              onPress={() => {}}
            /> */}
            <SettingsItem
              title={t("settings.policy")}
              icon="book"
              onPress={() => Linking.openURL(POLICY_LINK)}
            />
          </Container>
          <Container>
            <SettingsItem
              title={t("settings.logout")}
              icon="log-out-outline"
              onPress={() => {
                loggedOut();
              }}
            />
            <SettingsItem
              title={t("settings.delete")}
              icon="trash"
              onPress={() => setModalVisible(true)}
              variant="danger"
            />
          </Container>
          <Box px="4" gap="1" flexDirection="row">
            <Text variant="legend">
              <Text variant="legend" fontFamily={fonts.bold}>
                {t("ideel")}
              </Text>

              {nativeApplicationVersion
                ? ` • ${nativeApplicationVersion}V`
                : null}
              {updateId ? ` • ${updateId}U` : null}
            </Text>
          </Box>
          <Footer />
          <StatusBar
            barStyle="light-content"
            translucent
            backgroundColor={theme.colors.green6}
          />
        </Box>
      </ScrollView>
      <BottomModal
        hasCloseButton
        visible={isModalVisible}
        onClose={() => setModalVisible(false)}
      >
        <Box
          flex={1}
          pt={{ phone: "10", small: "6" }}
          pb="6"
          px="6"
          gap="3"
          justifyContent="center"
          alignItems="center"
        >
          <Text variant="title" textAlign="center">
            {t("deleteAccount.title")}
          </Text>
          <Text>{t("deleteAccount.text")}</Text>
          <Button
            variant="errorSecondary"
            onPress={() => setModalVisible(false)}
          >
            {t("deleteAccount.cancel")}
          </Button>
          <Button
            variant="errorPrimary"
            onPress={() => {
              mutate();
              setModalVisible(false);
            }}
          >
            {t("deleteAccount.delete")}
          </Button>
        </Box>
      </BottomModal>
    </Box>
  );
};
export default Settings;
