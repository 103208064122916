import {
  TransitionPresets,
  createStackNavigator,
} from "@react-navigation/stack";

import CrispChat from "@Screens/CrispChat";
import History from "@Screens/History";
import Home from "@Screens/Home";
import ProductDetails from "@Screens/ProductDetails";
import Profile from "@Screens/Profile";
import Settings from "@Screens/Settings";
import QRCodeButton from "@SmartComponents/QRCodeButton";
import {
  PathConfig,
  PathConfigMap,
  useNavigation,
} from "@react-navigation/native";
import { cardIsCC } from "@Utils/card";
import Loader from "@Core/Loader";
import useInternetRefresh from "@Hooks/useInternetRefresh";
import AddCreditCard from "@Screens/AddCreditCard";
import CarbonFootprint from "@Screens/CarbonFootprint";
import CreditCardList from "@Screens/CreditCardList";
import NoWifi from "@Screens/NoWifi";
import OnboardingCreditCard from "@Screens/OnboardingCreditCard";
import SignUpName from "@Screens/SignUpName";
import UserOpinion from "@Screens/UserOpinion";
import Wallet from "@Screens/Wallet";
import WalletCharge from "@Screens/WalletCharge";
import useUserStore from "@Stores/user.store";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { allSavedCardsQuery } from "@Api/wallet.api";
import {
  useGetScreenName,
  useHeaderGreen,
  useHeaderGreenModal,
} from "./header.helper";
import { AUTH_NAMES, AuthStackParamList } from "./routes";

const Stack = createStackNavigator<AuthStackParamList>();

const pathConfigMap: PathConfigMap<AuthStackParamList> = {
  [AUTH_NAMES.HOME]: "/",
  [AUTH_NAMES.SETTINGS]: "settings",
  [AUTH_NAMES.PROFILE]: "profile",
  [AUTH_NAMES.HISTORY]: "history",
  [AUTH_NAMES.PRODUCT_DETAILS]: "product-details",
  [AUTH_NAMES.USER_OPINION]: "opinion",
  [AUTH_NAMES.SIGN_UP_NAME]: "sign-up-name",
  [AUTH_NAMES.CARBON_FOOTPRINT]: "carbon-footprint",
};

export const configAuthNavigator = {
  screens: pathConfigMap,
  path: "/",
  initialRouteName: AUTH_NAMES.HOME,
} satisfies PathConfig<AuthStackParamList>;

const AuthNavigator = () => {
  const headerStyle = useHeaderGreen();
  const modalHeaderStyle = useHeaderGreenModal();
  const queryClient = useQueryClient();
  const navigation = useNavigation();

  const { data: dataSavedCards, isLoading: isLoadingCards } =
    useQuery(allSavedCardsQuery);

  const firstName = useUserStore((state) => state.signInUser?.firstName);
  const token = useUserStore((state) => state.signInUser?.token);
  const isFirstUsed = useUserStore((state) => state.firstUsed);
  const setFirstUsed = useUserStore((state) => state.setFirstUsed);

  const { getScreenName } = useGetScreenName();

  const { isOnline, isTokenValid } = useInternetRefresh();

  const hasFirstName = !!firstName?.trim()?.length;
  const hasCB = dataSavedCards?.some(cardIsCC);
  const shouldDisplayFirstName = !hasFirstName;

  useEffect(() => {
    if (isLoadingCards || !token || shouldDisplayFirstName) return;
    if (!isFirstUsed) return;

    setFirstUsed();
    if (hasCB) return;

    navigation.navigate("auth", {
      screen: AUTH_NAMES.ON_BOARDING_CREDIT_CARD,
    });
  }, [
    navigation,
    isFirstUsed,
    hasCB,
    isLoadingCards,
    setFirstUsed,
    shouldDisplayFirstName,
    token,
  ]);

  useEffect(
    () => () => {
      queryClient.clear();
    },
    [queryClient]
  );

  if (!isOnline)
    return (
      <>
        <NoWifi />
        <QRCodeButton />
      </>
    );

  if (!isTokenValid || isLoadingCards) {
    return <Loader />;
  }

  return (
    <>
      <Stack.Navigator
        screenOptions={{
          ...headerStyle,
          ...TransitionPresets.SlideFromRightIOS,
        }}
        initialRouteName={
          shouldDisplayFirstName ? AUTH_NAMES.SIGN_UP_NAME : AUTH_NAMES.HOME
        }
      >
        {shouldDisplayFirstName && (
          <Stack.Screen
            name={AUTH_NAMES.SIGN_UP_NAME}
            component={SignUpName}
            options={getScreenName(AUTH_NAMES.SIGN_UP_NAME)}
          />
        )}
        {!shouldDisplayFirstName && (
          <Stack.Screen
            name={AUTH_NAMES.HOME}
            component={Home}
            options={{
              headerShown: false,
              ...getScreenName(AUTH_NAMES.HOME),
            }}
          />
        )}
        <Stack.Screen
          name={AUTH_NAMES.SETTINGS}
          component={Settings}
          options={getScreenName(AUTH_NAMES.SETTINGS)}
        />
        <Stack.Screen
          name={AUTH_NAMES.PROFILE}
          component={Profile}
          options={getScreenName(AUTH_NAMES.PROFILE)}
        />
        <Stack.Screen
          name={AUTH_NAMES.HISTORY}
          component={History}
          options={getScreenName(AUTH_NAMES.HISTORY)}
        />
        <Stack.Screen
          name={AUTH_NAMES.PRODUCT_DETAILS}
          component={ProductDetails}
          options={{
            headerShown: false,
            ...getScreenName(AUTH_NAMES.PRODUCT_DETAILS),
          }}
        />
        <Stack.Screen
          name={AUTH_NAMES.CRISP_CHAT}
          component={CrispChat}
          options={getScreenName(AUTH_NAMES.CRISP_CHAT)}
        />
        <Stack.Screen
          name={AUTH_NAMES.USER_OPINION}
          component={UserOpinion}
          options={getScreenName(AUTH_NAMES.USER_OPINION)}
        />
        <Stack.Screen
          name={AUTH_NAMES.WALLET}
          component={Wallet}
          options={getScreenName(AUTH_NAMES.WALLET)}
        />
        <Stack.Screen
          name={AUTH_NAMES.ADD_CREDIT_CARD}
          component={AddCreditCard}
          options={getScreenName(AUTH_NAMES.ADD_CREDIT_CARD)}
        />
        <Stack.Screen
          name={AUTH_NAMES.CREDIT_CARD_LIST}
          component={CreditCardList}
          options={getScreenName(AUTH_NAMES.CREDIT_CARD_LIST)}
        />
        <Stack.Screen
          name={AUTH_NAMES.WALLET_CHARGE}
          component={WalletCharge}
          options={getScreenName(AUTH_NAMES.WALLET_CHARGE)}
        />
        <Stack.Screen
          name={AUTH_NAMES.CARBON_FOOTPRINT}
          component={CarbonFootprint}
          options={getScreenName(AUTH_NAMES.CARBON_FOOTPRINT)}
        />
        <Stack.Screen
          name={AUTH_NAMES.ON_BOARDING_CREDIT_CARD}
          component={OnboardingCreditCard}
          options={{
            ...getScreenName(AUTH_NAMES.ON_BOARDING_CREDIT_CARD),
            ...modalHeaderStyle,
          }}
        />
      </Stack.Navigator>
      <QRCodeButton />
    </>
  );
};

export default AuthNavigator;
