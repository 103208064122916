import { ApiError } from "@Config/axios.config";
import { SignInUser, postSignIn } from "@Api/user.api";
import Box from "@Core/Box";
import Button from "@Core/Button";
import { ControlledInput } from "@Core/Input";
import { useShowApiErrorToast } from "@SmartComponents/Toast";
import useUserStore from "@Stores/user.store";
import { signUpPasswordSchema } from "@Types/users";
import { useTheme } from "@Utils/theme";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { StatusBar } from "react-native";
import { z } from "zod";
import useSignUpStore from "../stores/signUp.store";

type Payload = {
  email: string;
  code: string;
};

const AuthCode = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const showToast = useShowApiErrorToast();

  const defaultPassword = useSignUpStore((state) => state.code);
  const email = useSignUpStore((state) => state.email);
  const loggedIn = useUserStore((state) => state.loggedIn);

  const [showPassword, setShowPassword] = useState(false);

  type FormValues = z.infer<typeof signUpPasswordSchema>;

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    mode: "onBlur",
    defaultValues: { code: defaultPassword },
    resolver: zodResolver(signUpPasswordSchema),
  });

  const { mutate, isLoading } = useMutation<SignInUser, ApiError, Payload>({
    mutationFn: postSignIn,
    onSuccess: (data) => loggedIn(data),
    onError: (error) => {
      if (error.type === "authenticationFailure")
        setError("code", {
          type: "manual",
          message: "SignUpPassword.error",
        });
      else showToast();
    },
  });

  const onSubmit = useCallback(
    () =>
      handleSubmit(({ code }) => {
        if (email) {
          mutate({ code, email });
        }
      })(),
    [handleSubmit, email, mutate]
  );

  return (
    <Box flex={1} bg="white" p="4">
      <ControlledInput
        autoFocus
        autoCapitalize="characters"
        autoCorrect={false}
        onSubmitEditing={onSubmit}
        control={control}
        name="code"
        error={t(errors.code?.message as string)}
        label={t("SignUpPassword.label")}
        underlineLabel={t("SignUpPassword.underlineLabel")}
        labelVariant="large"
        placeholder={t("SignUpPassword.placeholder")}
        onPressIconRight={() => setShowPassword(!showPassword)}
      />
      <Button
        flex={0}
        isLoading={isLoading}
        variant={isValid ? "primary" : "disabled"}
        alignSelf="flex-end"
        onPress={onSubmit}
      >
        {t("SignUpPassword.next")}
      </Button>
      <StatusBar
        translucent
        barStyle="light-content"
        backgroundColor={theme.colors.green6}
      />
    </Box>
  );
};

export default AuthCode;
