import rate1 from "@Assets/image/reactions/1.png";
import rate2 from "@Assets/image/reactions/2.png";
import rate3 from "@Assets/image/reactions/3.png";
import rate4 from "@Assets/image/reactions/4.png";
import rate5 from "@Assets/image/reactions/5.png";
import Image from "@Core/Image";
import Pressable from "@Core/Pressable";
import { useTheme } from "@Utils/theme";
import React from "react";
import Animated, {
  FadeInLeft,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";

const RATING = [
  { image: rate1, rate: 1 },
  { image: rate2, rate: 2 },
  { image: rate3, rate: 3 },
  { image: rate4, rate: 4 },
  { image: rate5, rate: 5 },
];

type Props = {
  onRate: (rating: number) => void;
  selectedRating?: number;
  animated?: boolean;
};

const AnimatedPressable = Animated.createAnimatedComponent(Pressable);

const Ratings = ({ onRate, selectedRating, animated = false }: Props) => {
  const hasSelectedRating = selectedRating !== undefined;

  return (
    <>
      {RATING.map((item, index) => (
        <Rating
          key={`${index + 1}`}
          onRate={onRate}
          animated={animated}
          isTransparent={hasSelectedRating && selectedRating !== item.rate}
          item={item}
        />
      ))}
    </>
  );
};

const Rating = ({
  onRate,
  isTransparent,
  item,
  animated,
}: {
  onRate: (rating: number) => void;
  isTransparent: boolean;
  animated: boolean;
  item: { image: number; rate: number };
}) => {
  const theme = useTheme();
  const pressed = useSharedValue<boolean>(false);

  const style = useAnimatedStyle(() => ({
    transform: [
      {
        scale: withTiming(pressed.value ? 1.5 : 1, { duration: 200 }),
      },
    ],
    opacity: isTransparent ? 0.5 : 1,
    backgroundColor:
      isTransparent && !pressed.value
        ? theme.colors.white
        : theme.colors.purple1,
  }));

  return (
    <AnimatedPressable
      onPress={() => onRate(item.rate)}
      borderRadius="full"
      entering={
        animated
          ? FadeInLeft.duration(200).delay((item.rate - 1) * 200)
          : undefined
      }
      style={style}
      onPressIn={() => {
        pressed.value = true;
      }}
      onPressOut={() => {
        pressed.value = false;
      }}
    >
      <Image source={item.image} width={48} height={48} />
    </AnimatedPressable>
  );
};

export default Ratings;
