import { z } from "zod";

const lastNameSchema = z
  .string({ required_error: "profile.lastNameEmpty" })
  .trim()
  .min(2, {
    message: "profile.lastNameError",
  });

const firstNameSchema = z
  .string({ required_error: "profile.firstNameEmpty" })
  .trim()
  .min(2, {
    message: "profile.firstNameError",
  });

const emailSchema = z
  .string({ required_error: "profile.emailEmpty" })
  .trim()
  .email({ message: "profile.emailError" });

const codeSchema = z
  .string({
    required_error: "profile.passwordEmpty",
  })
  .min(6, {
    message: "profile.passwordError",
  })
  .max(6, {
    message: "profile.passwordError",
  });

const passwordConfirmSchema = z.string().optional();

export const changePasswordSchema = z
  .object({
    password: codeSchema,
    passwordConfirm: passwordConfirmSchema,
  })
  .refine((data) => data.password === data.passwordConfirm, {
    message: "profile.passwordConfirmError",
    path: ["passwordConfirm"],
  });

export const profileSchema = z.object({
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  email: emailSchema,
});

export const signUpEmailSchema = z.object({
  email: emailSchema,
});

export const signUpPasswordSchema = z.object({
  code: codeSchema,
});

export const signUpNameSchema = z.object({
  firstName: firstNameSchema,
  lastName: lastNameSchema,
});

export const signInSchema = z.object({
  email: emailSchema,
  password: codeSchema,
});

export const forgetPasswordSchema = z.object({
  email: emailSchema,
});
