
import { Theme } from "@Utils/theme";
import { AllProps, all, createRestyleComponent } from "@shopify/restyle";
import React from "react";
// eslint-disable-next-line
import { Pressable as RNPressable } from "react-native";

const Pressable = createRestyleComponent<
  React.ComponentProps<typeof RNPressable> & AllProps<Theme>,
  Theme
  // @ts-ignore
>([all], RNPressable);

export default Pressable;
