import Box, { BoxProps } from "@Core/Box";
import Icon from "@Core/Icon";
import Text from "@Core/Text";
import { IconsName } from "@Icons/type";
import { ReactNode } from "react";

type InfoProps = {
  children: ReactNode;
  icon: IconsName;
} & BoxProps;
const Info = ({ icon, children, ...props }: InfoProps) => (
  <Box
    alignItems="center"
    justifyContent="flex-start"
    bg="purple1"
    borderRadius="l"
    p="2"
    g="2"
    flexDirection="row"
    {...props}
  >
    <Icon name={icon} size={24} color="purple6" />
    <Box flex={1}>
      <Text textAlign="justify" color="purple6">
        {children}
      </Text>
    </Box>
  </Box>
);

export default Info;
