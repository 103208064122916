import { Colors, useTheme } from "@Utils/theme";
// eslint-disable-next-line no-restricted-imports
import { Ionicons } from "@expo/vector-icons";
import React, { ComponentProps } from "react";

type Props = ComponentProps<typeof Ionicons> & {
  color: Colors;
};

const Icon = ({ color, ...props }: Props) => {
  const theme = useTheme();
  return <Ionicons color={theme.colors[color]} {...props} />;
};

export default Icon;
