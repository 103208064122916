import { Trans, useTranslation } from "react-i18next";
import Text from "@Core/Text";
import { footPrintToString } from "@Utils/fn";
import { Theme, fonts } from "@Utils/theme";
import Box from "@Core/Box";
import car from "@Assets/image/car.svg";
import plant from "@Assets/image/plant.svg";
import tShirt from "@Assets/image/t-shirt.svg";
import Container from "./Container";
import CarbonLeaf from "./CarbonLeaf";
import CarbonEquivalent from "./CarbonEquivalent";

const FootprintSummary = ({
  footprint,
  saved,
}: {
  footprint: number;
  saved: number;
}) => {
  const { t } = useTranslation();
  const CAR_EQUIVALENT = 191000;
  const T_SHIRT_EQUIVALENT = 6000;
  const VEGETARIAN = 500;

  const carEquivalent = Math.floor(saved / CAR_EQUIVALENT);
  const tShirtEquivalent = Math.floor(saved / T_SHIRT_EQUIVALENT);
  const vegetarianEquivalent = Math.floor(saved / VEGETARIAN);

  return (
    <Container gap="4" title={t("CarbonFootprint.summaryTitle")}>
      <CarbonText carbon={footprint} color="grey8" />
      <Text>{t("CarbonFootprint.summaryText")}</Text>
      <Box flexDirection="row" gap="2">
        <CarbonLeaf />
        <CarbonText carbon={saved} color="green6" />
      </Box>
      <Text>{t("CarbonFootprint.summaryText2")}</Text>
      <Box flexDirection="row" gap="2" justifyContent="space-around">
        <CarbonEquivalent
          equivalent={carEquivalent}
          image={car as any as string}
          name="Paris/Nice"
          color="purple"
        />
        <CarbonEquivalent
          equivalent={tShirtEquivalent}
          image={tShirt as any as string}
          name="T-shirts"
          color="yellow"
        />
        <CarbonEquivalent
          equivalent={vegetarianEquivalent}
          image={plant as any as string}
          name="Repas végé"
          color="green"
        />
      </Box>
    </Container>
  );
};
const CarbonText = ({
  carbon,
  color,
}: {
  carbon: number;
  color: keyof Theme["colors"];
}) => {
  const { t } = useTranslation();

  return (
    <Text fontSize={24} textAlign="center" lineHeight={58} color={color}>
      <Trans
        t={t}
        {...footPrintToString(carbon, true)}
        components={{
          b: (
            <Text
              fontFamily={fonts.bold}
              fontSize={48}
              lineHeight={58}
              color={color}
            />
          ),
        }}
      />
    </Text>
  );
};

export default FootprintSummary;
