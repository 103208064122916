import Box from "@Core/Box";
import { WINDOW_DIMENSION } from "@Utils/dimensions";
import React, { ReactNode } from "react";
import { Platform } from "react-native";

const WebWrapper = ({ children }: { children: ReactNode }) =>
  Platform.OS === "web" ? (
    <Box flex={1} alignItems="center" bg="grey2">
      <Box
        overflow="hidden"
        maxWidth={WINDOW_DIMENSION.width}
        style={{
          // @ts-ignore - web only
          boxShadow: "0 25px 50px -12px rgb(0 0 0 / 0.25)",
          position: "relative",
        }}
        width="100%"
        flex={1}
      >
        {children}
      </Box>
    </Box>
  ) : (
    <Box flex={1}>{children}</Box>
  );

export default WebWrapper;
