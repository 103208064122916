import React, { useEffect, useRef } from "react";
import { Platform } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import WebView from "react-native-webview";

type Props = {
  goBack: () => void;
  onError: () => void;
  onSuccess: () => void;
  canGoBack: () => boolean;
  url: string;
};

const WebViewWrapper = ({
  url,
  onUrlChange,
}: {
  url: string;
  onUrlChange: (url: string) => boolean;
}) => {
  const { bottom } = useSafeAreaInsets();
  const ref = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.contentWindow?.addEventListener("unload", () => {
        // eslint-disable-next-line no-console
        console.log(ref.current?.contentWindow?.location.href);

        // eslint-disable-next-line no-console
        console.log(ref.current?.contentWindow?.location);

        onUrlChange(ref.current?.contentWindow?.location.href || "");
      });

      // add a listener to the iframe when the url changes
      ref.current.contentWindow?.addEventListener("message", (event) => {
        // eslint-disable-next-line no-console
        console.log(event);
      });
    }
  }, [onUrlChange]);

  return Platform.OS !== "web" ? (
    <WebView
      onShouldStartLoadWithRequest={({ url: newUrl }) => onUrlChange(newUrl)}
      source={{ uri: url }}
      originWhitelist={["*"]}
      style={{ flex: 1, marginBottom: bottom }}
    />
  ) : (
    <iframe
      src={url}
      title="BankForm"
      ref={ref}
      style={{ flex: 1, marginBottom: bottom }}
    />
  );
};

const BankForm = ({ canGoBack, goBack, onError, onSuccess, url }: Props) => (
  <WebViewWrapper
    url={url}
    onUrlChange={(newUrl) => {
      if (newUrl.includes("localhost")) {
        if (newUrl.includes("error") || newUrl.includes("cancel")) {
          onError();
          if (canGoBack()) goBack();
        } else if (newUrl.includes("success")) {
          onSuccess();
        }
        return false;
      }
      return true;
    }}
  />
);
export default BankForm;
