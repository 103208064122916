import React from "react";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import Box, { BoxProps } from "./Box";

export const FOOTER_HEIGHT = 54 + 32 + 16;
const Footer = ({
  hasNoQrCodeMargin = false,
  ...props
}: {
  hasNoQrCodeMargin?: boolean;
} & BoxProps) => {
  const { bottom } = useSafeAreaInsets();
  return (
    <Box height={bottom + (hasNoQrCodeMargin ? 0 : FOOTER_HEIGHT)} {...props} />
  );
};

export default Footer;
