import logo from "@Assets/image/logo.png";
import Box from "@Core/Box";
import Icon from "@Core/Icon";
import Image from "@Core/Image";
import Pressable from "@Core/Pressable";
import { defaultHeaderHeight } from "@Utils/dimensions";
import { useTheme } from "@Utils/theme";
import { useSafeAreaInsets } from "react-native-safe-area-context";

const HomeHeader = ({ onPress }: { onPress: () => void }) => {
  const { top } = useSafeAreaInsets();
  const theme = useTheme();

  return (
    <Box
      bg="white"
      style={{
        paddingTop: top,
      }}
      height={defaultHeaderHeight + top + 1}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      paddingHorizontal="4"
      borderBottomWidth={1}
      borderBottomColor="grey2"
    >
      <Image width={168} height={32} source={logo} />
      <Pressable
        height={36}
        width={36}
        hitSlop={10}
        borderRadius="full"
        bg="green2"
        alignItems="center"
        style={({ pressed }) => ({
          backgroundColor: pressed ? theme.colors.green3 : theme.colors.green2,
        })}
        justifyContent="center"
        onPress={onPress}
      >
        <Icon name="person" size={20} color="green6" />
      </Pressable>
    </Box>
  );
};

export default HomeHeader;
