import { ordersQuery, postRate } from "@Api/order.api";
import Container from "@Components/Container";
import ProductRating from "@Components/ProductRating";
import Ratings from "@Components/Ratings";
import Box from "@Core/Box";
import Button from "@Core/Button";
import Footer from "@Core/Footer";
import Input from "@Core/Input";
import Text from "@Core/Text";
import useTracking from "@Hooks/useTracking";
import { AuthStackScreenProp } from "@Navigation/routes";
import { useShowToast } from "@SmartComponents/Toast";
import { useTheme } from "@Utils/theme";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  StatusBar,
} from "react-native";
import { create } from "zustand";

type Props = AuthStackScreenProp<"UserOpinion">;

type UserOpinionState = {
  init: (initialRating?: number) => void;
  rating: number;
  productsOpinion: (
    | {
        digitiz_order_combination_id?: number;
        order_detail_id: number;
        value: number;
      }
    | undefined
  )[];
  setProductOpinion: (
    index: number,
    info: {
      digitiz_order_combination_id?: number;
      order_detail_id: number;
      value: number;
    }
  ) => void;

  comment: string;
  setComment: (comment: string) => void;
  setRating: (rating: number) => void;
};

const useUserOpinion = create<UserOpinionState>((set) => ({
  init: (initialRating) => {
    set({
      rating: initialRating,
      productsOpinion: [],
      comment: "",
    });
  },
  rating: 0,
  setRating: (rating) => set({ rating }),
  productsOpinion: [],
  setProductOpinion: (index, infos) => {
    set((state) => {
      const productsOpinion = [...state.productsOpinion];
      productsOpinion[index] = infos;
      return {
        productsOpinion,
      };
    });
  },
  comment: "",
  setComment: (comment) => set({ comment }),
}));

const UserOpinion = ({
  route: {
    params: { initialRating, order },
  },
  navigation,
}: Props) => {
  const { trackEvent } = useTracking();
  const theme = useTheme();
  const { t } = useTranslation();
  const showToast = useShowToast();
  const queryClient = useQueryClient();
  const {
    init,
    rating,
    setRating,
    comment,
    setComment,
    productsOpinion,
    setProductOpinion,
  } = useUserOpinion();

  const { mutate, isLoading } = useMutation({
    mutationFn: postRate,
    onSettled: () => {
      navigation.goBack();
      queryClient.invalidateQueries(ordersQuery.queryKey);
      showToast({
        message: t("UserOpinion.toastMessage"),
        type: "success",
        icon: "happy-outline",
        title: t("UserOpinion.toastTitle"),
      });
    },
  });

  const products: {
    name: string;
    order_detail_id: number;
    digitiz_order_combination_id?: number;
  }[] = order.products.flatMap((product) =>
    !product.content
      ? [
          {
            name: product.name,
            order_detail_id: product.order_detail_id,
          },
        ]
      : product.content.map(({ digitiz_order_combination_id, name }) => ({
          name: name!,
          digitiz_order_combination_id,
          order_detail_id: product.order_detail_id,
        }))
  );

  useEffect(() => {
    init(initialRating);
  }, [initialRating, init]);

  const scrollViewRef = useRef<ScrollView>(null);

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener(
      "keyboardDidShow",
      ({ endCoordinates: { height } }) => {
        if (Platform.OS !== "ios") return;
        scrollViewRef.current?.scrollTo({
          y: height,
        });
      }
    );
    const keyboardDidHideListener = Keyboard.addListener(
      "keyboardDidHide",
      () => {}
    );

    return () => {
      keyboardDidShowListener.remove();
      keyboardDidHideListener.remove();
    };
  }, []);

  return (
    <KeyboardAvoidingView
      style={{ flex: 1 }}
      behavior={Platform.OS === "ios" ? "padding" : undefined}
    >
      <Box flex={1} bg="grey1">
        <ScrollView ref={scrollViewRef}>
          <Container g="2">
            <Text variant="headline">
              {initialRating
                ? t("UserOpinion.title")
                : t("UserOpinion.titleNoRate")}
            </Text>
            <Box
              mt="4"
              bg={rating === undefined ? "purple1" : undefined}
              borderRadius="full"
              flexDirection="row"
              gap="2"
              alignSelf="center"
              alignItems="center"
            >
              <Ratings onRate={setRating} selectedRating={rating} />
            </Box>
            {products.map(
              (
                { name, order_detail_id, digitiz_order_combination_id },
                index
              ) => (
                <ProductRating
                  onRate={(productRating) =>
                    setProductOpinion(index, {
                      order_detail_id,
                      value: productRating,
                      digitiz_order_combination_id,
                    })
                  }
                  key={digitiz_order_combination_id ?? order_detail_id}
                  name={name}
                  rating={productsOpinion[index]?.value}
                />
              )
            )}

            <Input
              gap="2"
              style={{
                height: 100,
              }}
              label={t("UserOpinion.comment")}
              placeholder={t("UserOpinion.commentPlaceholder")}
              multiline
              textAlignVertical="top"
              numberOfLines={4}
              value={comment}
              onChangeText={setComment}
            />

            <Button
              disabled={rating === undefined || isLoading}
              isLoading={isLoading}
              variant={rating === undefined ? "disabled" : "primary"}
              onPress={() => {
                mutate({
                  id: order.id,
                  payload: {
                    score: rating,
                    products: productsOpinion.filter(Boolean),
                  },
                });

                trackEvent("clickOnSendOpinion", {
                  opinion: rating,
                  orderId: "1",
                });
              }}
            >
              {t("UserOpinion.send")}
            </Button>
          </Container>

          <Footer />
        </ScrollView>
        <StatusBar
          translucent
          barStyle="light-content"
          backgroundColor={theme.colors.green6}
        />
      </Box>
    </KeyboardAvoidingView>
  );
};

export default UserOpinion;
