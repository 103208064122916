import { SavedCard } from "@Api/wallet.api";
import dayjs from "dayjs";

export const cardIsValid = (card: SavedCard) =>
  card.expirationDate.isAfter(dayjs());

export const cardIsCC = (card: SavedCard) => card.type === "CB";

export const cardIsValidCC = (card: SavedCard) =>
  cardIsValid(card) && cardIsCC(card);

export const cardIsInvalidCC = (card: SavedCard) =>
  !cardIsValid(card) && cardIsCC(card);
export const numberOfValidCC = (cards: SavedCard[]) =>
  cards.filter(cardIsValidCC).length;

export const getCCStatus = (cards?: SavedCard[], walletBalance?: number) => {
  if (!cards) return "noCard";

  if (cards.some(cardIsValidCC)) return "validCC";

  if (cards.some(cardIsInvalidCC)) return "invalidCC";

  return walletBalance ? "noCard" : "noCC";
};
