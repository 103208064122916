import Box from "@Core/Box";
import Button from "@Core/Button";
import { ControlledInput } from "@Core/Input";
import { UN_AUTH_NAMES, UnAuthStackScreenProp } from "@Navigation/routes";
import { signUpEmailSchema } from "@Types/users";
import { useTheme } from "@Utils/theme";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Linking, StatusBar } from "react-native";
import { z } from "zod";
import { useMutation } from "@tanstack/react-query";
import { postSendMail } from "@Api/user.api";
import { useShowApiErrorToast } from "@SmartComponents/Toast";
import Text from "@Core/Text";
import { POLICY_LINK } from "@Utils/links";
import useSignUpStore from "../stores/signUp.store";

type AuthEmailProps = UnAuthStackScreenProp<"AuthEmail">;

const AuthEmail = ({ navigation }: AuthEmailProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const defaultEmail = useSignUpStore((state) => state.email);
  const setEmail = useSignUpStore((state) => state.setEmail);

  const showErrorToast = useShowApiErrorToast();

  const { mutate, isLoading } = useMutation({
    mutationFn: postSendMail,
    onSuccess: () => navigation.navigate(UN_AUTH_NAMES.AUTH_CODE),
    onError: () => showErrorToast(),
  });

  type FormValues = z.infer<typeof signUpEmailSchema>;

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    mode: "onBlur",
    defaultValues: { email: defaultEmail ?? "" },
    resolver: zodResolver(signUpEmailSchema),
  });
  const onSubmit = useCallback(
    () =>
      handleSubmit(({ email }) => {
        mutate({ email });
        setEmail(email);
      })(),
    [handleSubmit, mutate, setEmail]
  );
  const goToGeneralConditions = () => Linking.openURL(POLICY_LINK);

  return (
    <Box flex={1} bg="white" p="4">
      <ControlledInput
        autoFocus
        autoCapitalize="none"
        autoCorrect={false}
        keyboardType="email-address"
        autoComplete="email"
        control={control}
        onSubmitEditing={onSubmit}
        name="email"
        error={t(errors.email?.message as string)}
        label={t("SignUpEmail.label")}
        underlineLabel={t("SignUpEmail.underlineLabel")}
        labelVariant="large"
        placeholder={t("SignUpEmail.placeholder")}
      />

      <Text variant="legend" mt="1" mb="4">
        {t("SignUpEmail.generalConditions")}
        <Text variant="legend" color="green6" onPress={goToGeneralConditions}>
          {t("SignUpEmail.generalConditionsLink")}
        </Text>
      </Text>

      <Button
        flex={0}
        isLoading={isLoading}
        variant={isValid ? "primary" : "disabled"}
        alignSelf="flex-end"
        onPress={onSubmit}
      >
        {t("SignUpEmail.next")}
      </Button>
      <StatusBar
        translucent
        barStyle="light-content"
        backgroundColor={theme.colors.green6}
      />
    </Box>
  );
};

export default AuthEmail;
