import { userQueryInfo } from "@Api/user.api";
import Box from "@Core/Box";
import useHideQrCode from "@Hooks/useHideQrCode";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { Platform } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import WebView from "react-native-webview";

const IFRAME_STYLE = { width: "100%", height: "100%", border: "none" };

const CrispChat = () => {
  useHideQrCode();

  const { data: userData } = useQuery({ ...userQueryInfo });
  const { bottom } = useSafeAreaInsets();

  const email = userData?.email;
  const name = `${userData?.firstName} ${userData?.lastName}`;
  const id = userData?.uid;

  if (!userData) return null;

  const crispUri = `https://go.crisp.chat/chat/embed/?website_id=80557bc5-0098-49b2-8648-5dec9d99610d&locale=fr&user_email=${email}&user_nickname=${name}&token_id=${id}`;

  return (
    <Box bg="white" flex={1}>
      {Platform.OS === "web" ? (
        <iframe title="Crisp Chat" src={crispUri} style={IFRAME_STYLE} />
      ) : (
        <WebView source={{ uri: crispUri }} scalesPageToFit={false} />
      )}

      <Box height={bottom} />
    </Box>
  );
};

export default CrispChat;
