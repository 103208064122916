import Box from "@Core/Box";
import Icon from "@Core/Icon";
import Text from "@Core/Text";
import { IconsName } from "@Icons/type";

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Gesture, GestureDetector } from "react-native-gesture-handler";
import Animated, {
  runOnJS,
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
  withDelay,
  withSpring,
  withTiming,
} from "react-native-reanimated";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { create } from "zustand";

type ToastType = {
  message: string;
  title: string;
  icon: IconsName;
  type: "success" | "error";
};

type ToastStore = {
  toast?: ToastType;
  displayToast: boolean;
  showToast: (toast: ToastType) => void;

  hideToast: () => void;
};

export const useToastStore = create<ToastStore>((set) => ({
  toast: undefined,
  displayToast: false,
  showToast: (toast) => set({ toast, displayToast: true }),
  hideToast: () => set({ displayToast: false }),
}));

const AnimatedBox = Animated.createAnimatedComponent(Box);
const Toast = () => {
  const { toast, displayToast, hideToast } = useToastStore();
  const { bottom } = useSafeAreaInsets();

  const DURATION = 5000;
  const VISIBLE = 0;
  const HIDDEN = bottom + 200;
  const position = useSharedValue(HIDDEN);

  useDerivedValue(() => {
    if (position.value <= VISIBLE) {
      runOnJS(hideToast)();
    }
  });

  useEffect(() => {
    if (displayToast && position.value !== VISIBLE) {
      position.value = withSpring(VISIBLE, { damping: 12 });
    }
    if (!displayToast) {
      position.value = withDelay(
        DURATION,
        withTiming(HIDDEN, { duration: 300 })
      );
    }
  }, [displayToast, position, HIDDEN]);

  const containerStyle = useAnimatedStyle(() => ({
    transform: [
      {
        translateY: position.value,
      },
    ],
  }));

  const pan = Gesture.Pan()
    .onChange((event) => {
      if (event.translationY) {
        position.value = withSpring(event.translationY);
      }
    })
    .onEnd((event) => {
      if (event.translationY > 0 || !displayToast) {
        position.value = withTiming(
          HIDDEN,
          { duration: 300 },
          runOnJS(hideToast)
        );
      } else {
        position.value = withTiming(0, { duration: 100 });
      }
    });

  const typeToColor = {
    success: {
      bg: "purple1",
      content: "purple6",
    },
    error: {
      bg: "red1",
      content: "red6",
    },
  } as const;

  const { bg, content } = typeToColor[toast?.type || "success"];

  return (
    <AnimatedBox
      style={containerStyle}
      position="absolute"
      left={0}
      right={0}
      bottom={bottom}
      p="4"
      zIndex={1000}
    >
      <GestureDetector gesture={pan}>
        <AnimatedBox
          collapsable={false}
          bg={bg}
          borderLeftWidth={4}
          borderColor={content}
          borderRadius="m"
          gap="2"
          p="4"
          flexDirection="row"
          alignItems="center"
        >
          {!!toast && <Icon name={toast.icon} size={24} color={content} />}
          <Box flex={1} gap="1">
            <Text variant="button" color={content}>
              {toast?.title}
            </Text>
            <Text color={content}>{toast?.message}</Text>
          </Box>
        </AnimatedBox>
      </GestureDetector>
    </AnimatedBox>
  );
};

export const useShowToast = () => useToastStore((state) => state.showToast);

export const useShowApiErrorToast = () => {
  const showToast = useShowToast();
  const { t } = useTranslation();

  return (error?: Partial<ToastType>) => {
    showToast({
      type: error?.type ?? "error",
      message: error?.message ?? t("common.unexpectedErrorText"),
      title: error?.title ?? t("common.unexpectedErrorTitle"),
      icon: error?.icon ?? "cloud",
    });
  };
};

export default Toast;
