import Info from "@Components/Info";
import { OrSeparator } from "@Components/Separator";
import Box from "@Core/Box";
import Button from "@Core/Button";
import Footer from "@Core/Footer";
import Text from "@Core/Text";
import useHideQrCode from "@Hooks/useHideQrCode";
import { AUTH_NAMES, AuthStackScreenProp } from "@Navigation/routes";
import { useTheme } from "@Utils/theme";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { StatusBar } from "react-native";

type OnboardingCreditCardProps = AuthStackScreenProp<"OnBoardingCreditCard">;
const OnboardingCreditCard = ({ navigation }: OnboardingCreditCardProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  useHideQrCode();

  return (
    <>
      <Box bg="white" flex={1} g="6" p="4">
        <Text variant="title">
          <Trans
            t={t}
            i18nKey="OnBoardingCreditCard.title"
            components={{
              u: <Text variant="title" textDecorationLine="underline" />,
            }}
          />
        </Text>

        <Text textAlign="justify" color="grey6">
          {t("OnBoardingCreditCard.text")}
        </Text>
        <Info icon="information-circle">
          <Trans
            t={t}
            i18nKey="OnBoardingCreditCard.text2"
            components={{
              s: <Text variant="button" color="purple6" />,
            }}
          />
        </Info>

        <Box flex={1} />
        <Button
          variant="primary"
          onPress={() => {
            navigation.replace(AUTH_NAMES.ADD_CREDIT_CARD);
          }}
        >
          {t("OnBoardingCreditCard.buttonCreditCard")}
        </Button>

        <OrSeparator flex={0} />
        <Button
          variant="secondary"
          onPress={() => {
            navigation.replace(AUTH_NAMES.WALLET);
          }}
        >
          {t("OnBoardingCreditCard.buttonWallet")}
        </Button>
      </Box>
      <Footer hasNoQrCodeMargin bg="white" />
      <StatusBar
        barStyle="light-content"
        translucent
        backgroundColor={theme.colors.green6}
      />
    </>
  );
};

export default OnboardingCreditCard;
