import { getProductsQuery } from "@Api/product.api";
import noImage from "@Assets/image/noImage.png";
import Container from "@Components/Container";
import ProductHeader from "@Components/ProductHeader";
import Separator from "@Components/Separator";
import Box from "@Core/Box";
import Footer from "@Core/Footer";
import Icon from "@Core/Icon";
import Image from "@Core/Image";
import Loader from "@Core/Loader";
import Pressable from "@Core/Pressable";
import Text from "@Core/Text";
import Alcohol from "@Icons/Alcohol";
import Pork from "@Icons/Pork";
import { AuthStackScreenProp } from "@Navigation/routes";
import { useTheme } from "@Utils/theme";
import { useQuery } from "@tanstack/react-query";
import React, { ReactNode, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ScrollView, StatusBar } from "react-native";
import Animated, {
  useAnimatedStyle,
  useSharedValue,
} from "react-native-reanimated";
import { useSafeAreaInsets } from "react-native-safe-area-context";

const AnimatedPressable = Animated.createAnimatedComponent(Pressable);

const ProductDetails = ({
  navigation,
  route,
}: AuthStackScreenProp<"ProductDetails">) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { top } = useSafeAreaInsets();
  const statusBarHeight = StatusBar.currentHeight || top;

  const { image, price, name, id } = route.params;

  const { data: productData, isLoading } = useQuery(getProductsQuery(id));

  const scrollOffset = useSharedValue(0);

  const animatedStyle = useAnimatedStyle(() => ({
    transform: [
      {
        translateY: scrollOffset.value > 0 ? scrollOffset.value : 0,
      },
    ],
  }));

  const [showMore, setShowMore] = useState(false);

  const product = productData || {
    hasAlcohol: false,
    hasPork: false,
    kind: "unknown",
    weight: 0,
    allergens: [],
    id: 0,
    name: "",
    ingredients: [],
    warmUpKind: "unknown",
  };

  const conditions = [
    {
      condition: product.hasAlcohol,
      icon: <Alcohol size={17} color={theme.colors.red7} />,
      text: t("productDetails.alcohol"),
    },
    {
      condition: product.hasPork,
      icon: <Pork size={17} color={theme.colors.red7} />,
      text: t("productDetails.pork"),
    },
  ];

  const warmUpKindToReheat = {
    cold: "cold",
    hot: "reheat",
    either: "either",
    unknown: "unknown",
  } as const;

  const nutritionFacts = [
    {
      key: "productDetails.energyKcal",
      value100: `${479}kcal`,
      valuePortion: `${638}kcal`,
    },
    {
      key: "productDetails.energyKj",
      value100: `${1991}kj`,
      valuePortion: `${2649}kj`,
    },
    {
      key: "productDetails.protein",
      value100: `${6.8}g`,
      valuePortion: `${9.0}g`,
    },
    {
      key: "productDetails.carbohydrate",
      value100: `${12}g`,
      valuePortion: `${12}g`,
    },
    {
      key: "productDetails.sugar",
      value100: `${12}g`,
      valuePortion: `${12}g`,
    },
    {
      key: "productDetails.fat",
      value100: `${12}g`,
      valuePortion: `${12}g`,
    },
    {
      key: "productDetails.saturatedFat",
      value100: `${12}g`,
      valuePortion: `${12}g`,
    },
    {
      key: "productDetails.fiber",
      value100: `${12}g`,
      valuePortion: `${12}g`,
    },
  ];
  return (
    <Box top={0} flex={1} bg="grey1">
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        height={statusBarHeight}
        zIndex={10}
        bg="grey10"
        opacity={0.4}
      />
      <ScrollView
        scrollEventThrottle={1}
        onScroll={({
          nativeEvent: {
            contentOffset: { y },
          },
        }) => {
          scrollOffset.value = y;
        }}
      >
        <Image
          source={image ? { uri: image } : noImage}
          width="100%"
          height={240 + top}
        />
        <Container g="4">
          <ProductHeader
            name={name}
            price={price}
            isLoading={isLoading}
            type={product.kind}
            reheat={warmUpKindToReheat[product.warmUpKind]}
            weight={product.weight}
          />
          {isLoading ? (
            <Box height={200}>
              <Loader />
            </Box>
          ) : (
            <>
              {(product.hasAlcohol || product.hasPork) && (
                <Section isShown title={t("productDetails.contains")}>
                  <Box flexDirection="row" gap="4">
                    {conditions.map(({ condition, icon, text }) =>
                      condition ? (
                        <Box
                          gap="1"
                          key={text}
                          alignItems="center"
                          flexDirection="row"
                        >
                          <Box
                            borderRadius="full"
                            width={32}
                            height={32}
                            justifyContent="center"
                            alignItems="center"
                            bg="red1"
                          >
                            {icon}
                          </Box>
                          <Text color="red7">{text}</Text>
                        </Box>
                      ) : null
                    )}
                  </Box>
                </Section>
              )}

              <Section
                isShown={product.allergens.length !== 0}
                title={t("productDetails.allergens")}
              >
                <Text color="grey8">{product.allergens.join(" • ")}</Text>
              </Section>
              <Section
                title={t("productDetails.ingredients")}
                isShown={product.ingredients.length !== 0}
              >
                <Text color="grey8">
                  {product.ingredients
                    .filter((_, index) => index < 3 || showMore)
                    .join(" • ")}
                  {" • "}
                  <Text
                    onPress={() => setShowMore(!showMore)}
                    color="grey10"
                    textDecorationLine="underline"
                  >
                    {showMore
                      ? t("productDetails.showLess")
                      : t("productDetails.showMore")}
                  </Text>
                </Text>
              </Section>
              <Section
                title={t("productDetails.carbonImpact")}
                isShown={product.ingredients.length !== 0}
              >
                <Text color="grey8">
                  <Trans
                    t={t}
                    tOptions={{
                      impact: 475,
                    }}
                    i18nKey="productDetails.carbonImpactText"
                    components={{
                      b: <Text color="green6" variant="headline" />,
                    }}
                  />
                </Text>
                <Text color="grey8">
                  <Trans
                    t={t}
                    tOptions={{
                      impact: 35,
                    }}
                    i18nKey="productDetails.carbonImpactText2Less"
                    components={{
                      b: <Text variant="headline" />,
                    }}
                  />
                </Text>
              </Section>
              <Section
                title={t("productDetails.nutrition")}
                isShown={product.ingredients.length !== 0}
              >
                <Box gap="2">
                  <Box
                    pb="2"
                    borderBottomColor="grey2"
                    flexDirection="row"
                    gap="2"
                  >
                    <Box flex={2} />
                    <Box flex={1} borderRightWidth={1} borderRightColor="grey2">
                      <Text variant="legend" color="grey8">
                        {t("productDetails.per100g")}
                      </Text>
                    </Box>
                    <Box flex={1}>
                      <Text variant="legend" color="grey8">
                        {t("productDetails.perPortion")}
                      </Text>
                    </Box>
                  </Box>
                  {nutritionFacts.map(({ key, value100, valuePortion }) => (
                    <Box key={key} pt="1" flexDirection="row" gap="2">
                      <Box flex={2}>
                        <Text variant="legend" color="grey8">
                          {t(key)}
                        </Text>
                      </Box>
                      <Box
                        flex={1}
                        borderRightWidth={1}
                        borderRightColor="grey2"
                      >
                        <Text>{value100}</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>{valuePortion}</Text>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Section>
            </>
          )}
        </Container>
        <Footer />
        <AnimatedPressable
          onPress={() => navigation.goBack()}
          position="absolute"
          bg="green6"
          top={top + 16}
          left={16}
          style={animatedStyle}
          alignItems="center"
          justifyContent="center"
          height={40}
          width={40}
          borderRadius="full"
        >
          <Icon name="arrow-back-outline" size={24} color="white" />
        </AnimatedPressable>
      </ScrollView>
      <StatusBar
        translucent
        backgroundColor="rgba(0, 0, 0, 0.3)"
        barStyle="light-content"
      />
    </Box>
  );
};
const Section = ({
  title,
  children,
  isShown,
}: {
  isShown: boolean;
  title: string;
  children: ReactNode;
}) =>
  isShown ? (
    <>
      <Separator />
      <Box gap="2">
        <Text color="grey6" variant="legend">
          {title}
        </Text>
        {children}
      </Box>
    </>
  ) : null;
export default ProductDetails;
