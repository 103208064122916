import EmptyState from "@Components/EmptyState";
import HomeHeader from "@Components/HomeHeader";
import Box from "@Core/Box";
import { useShowToast } from "@SmartComponents/Toast";
import React from "react";
import { useTranslation } from "react-i18next";
import { StatusBar } from "react-native";

const NoWifi = () => {
  const showToast = useShowToast();
  const { t } = useTranslation();

  return (
    <Box flex={1} bg="grey1">
      <HomeHeader
        onPress={() => {
          showToast({
            message: t("NoWifi.toastMessage"),
            icon: "wifi",
            title: t("NoWifi.toastTitle"),
            type: "success",
          });
        }}
      />
      <Box flex={1} px="4">
        <EmptyState
          icon="wifi"
          title={t("NoWifi.title")}
          text={t("NoWifi.text")}
        />
      </Box>
      <StatusBar translucent barStyle="dark-content" backgroundColor="white" />
    </Box>
  );
};
export default NoWifi;
