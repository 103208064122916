import {
  QueryClient,
  onlineManager,
  focusManager,
} from "@tanstack/react-query";
import NetInfo from "@react-native-community/netinfo";
import { useEffect } from "react";
import { AppState, Platform } from "react-native";
import type { AppStateStatus } from "react-native";

function onAppStateChange(status: AppStateStatus) {
  if (Platform.OS !== "web") {
    focusManager.setFocused(status === "active");
  }
}

export const useHandleFocus = () => {
  useEffect(() => {
    const subscription = AppState.addEventListener("change", onAppStateChange);

    return () => subscription.remove();
  }, []);
};

// refetch on reconnect to internet
onlineManager.setEventListener((setOnline) =>
  NetInfo.addEventListener((state) => {
    setOnline(!!state.isConnected);
  })
);

export const staleTimes = {
  fiveMinutes: 5 * 60 * 1000,
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: staleTimes.fiveMinutes,
      refetchOnWindowFocus: false,
    },
  },
});

export default queryClient;
