import useQRCodeStore from "@Stores/QRCode.store";
import { useEffect } from "react";

const useHideQrCode = () => {
  const isQRCodeButtonDisplayed = useQRCodeStore(
    (state) => state.isQRCodeButtonDisplayed
  );
  const showQRCodeButton = useQRCodeStore((state) => state.showQRCodeButton);
  const hideQRCodeButton = useQRCodeStore((state) => state.hideQRCodeButton);
  useEffect(() => {
    hideQRCodeButton();
    return () => {
      showQRCodeButton();
    };
  }, [hideQRCodeButton, showQRCodeButton, isQRCodeButtonDisplayed]);
};

export default useHideQrCode;
