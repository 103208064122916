import Box from "@Core/Box";
import Icon from "@Core/Icon";
import { useTheme } from "@Utils/theme";
import React from "react";
import { useTranslation } from "react-i18next";
import noImage from "@Assets/image/noImage.png";
import { priceToString } from "@Utils/fn";
import Image from "./core/Image";
import Pressable from "./core/Pressable";
import Text from "./core/Text";

type ProductCardProps = {
  /** Product name */
  name: string;
  /** Product price */
  price: number;
  /** Product image */
  image: string;
  /** Product stock */
  stock: number;
  /** Product weight */
  weight?: number;
  /** Product callback on press */
  onPress?: () => void;
  /** hasBorderTop */
  hasBorderTop?: boolean;
  /** hasBorderBottom */
  hasBorderBottom?: boolean;

  /** isVegetarian */
  isVegetarian?: boolean;
};

const ProductCard = ({
  image,
  name,
  price,
  isVegetarian,
  stock,
  onPress,
  weight,
  hasBorderBottom,
  hasBorderTop,
}: ProductCardProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Pressable
      onPress={onPress}
      style={({ pressed }) => ({
        backgroundColor: pressed ? theme.colors.grey2 : theme.colors.white,
      })}
      flexDirection="row"
      p="4"
      gap="2"
      borderBottomWidth={hasBorderBottom ? 1 : 0}
      borderTopWidth={hasBorderTop ? 1 : 0}
      borderColor="grey2"
    >
      <Box flex={1}>
        <Text variant="button" mb="1" numberOfLines={2} color="grey10">
          {name}
        </Text>
        <Text color="grey8">{priceToString(price)}</Text>
        <Box flex={1} />
        <Text color="grey6">
          {isVegetarian && (
            <>
              <Icon name="leaf-outline" color="green6" size={16} /> •{" "}
            </>
          )}
          {weight ? `${t("productCard.weight", { weight })} • ` : null}
          <Text color="grey6">
            {t("productCard.remaining", { count: stock })}
          </Text>
        </Text>
      </Box>
      <Image
        source={image ? { uri: image } : noImage}
        recyclingKey={image}
        height={82}
        width={82}
        borderRadius="l"
      />
      <Box
        position="absolute"
        right={0}
        left={0}
        marginHorizontal="4"
        bottom={0}
        height={1}
        bg="grey2"
        opacity={hasBorderBottom ? 0 : 1}
      />
    </Pressable>
  );
};

export default ProductCard;
