import { walletBalanceQuery } from "@Api/wallet.api";
import Container from "@Components/Container";
import Box from "@Core/Box";
import Button from "@Core/Button";
import Footer from "@Core/Footer";
import Loader from "@Core/Loader";
import Text from "@Core/Text";
import useHideQrCode from "@Hooks/useHideQrCode";
import { AUTH_NAMES, AuthStackScreenProp } from "@Navigation/routes";
import { useShowToast } from "@SmartComponents/Toast";
import { priceToString } from "@Utils/fn";
import { useIsFocused } from "@react-navigation/native";
import { useQuery } from "@tanstack/react-query";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type WalletProps = AuthStackScreenProp<"Wallet">;

const AMOUNTS = [5, 10, 15, 20, 30, 40, 50];
const Wallet = ({ navigation }: WalletProps) => {
  const isFocused = useIsFocused();
  const {
    data: walletData,
    isFetching,
    refetch,
  } = useQuery(walletBalanceQuery);

  useEffect(() => {
    if (isFocused) refetch();
  }, [isFocused, refetch]);

  const { t } = useTranslation();
  const showToast = useShowToast();
  const [selectedAmount, setAmount] = useState(0);

  useHideQrCode();
  if (!walletData) return <Loader />;

  const balance = walletData.balance ?? 0;
  return (
    <Box flex={1} bg="grey1" gap="4">
      <Container title={t("Wallet.balance")}>
        <Text opacity={isFetching ? 0.5 : 1} variant="button">
          {priceToString(balance)}
        </Text>
      </Container>

      <Container
        title={t("Wallet.selectAmount")}
        flexDirection="row"
        flexWrap="wrap"
        g="2"
      >
        {AMOUNTS.map((amount) => (
          <Button
            width={60}
            key={amount}
            variant={selectedAmount === amount ? "primary" : "secondary"}
            onPress={() => setAmount(amount)}
          >
            {priceToString(amount, true)}
          </Button>
        ))}
      </Container>
      <Box flex={1} />
      <Button
        m="4"
        variant={selectedAmount ? "primary" : "disabled"}
        onPress={() => {
          if (!selectedAmount) {
            showToast({
              type: "error",
              icon: "close-circle",
              message: t("Wallet.toastNoAmountMessage"),
              title: t("Wallet.toastNoAmountTitle"),
            });
          } else {
            navigation.navigate(AUTH_NAMES.WALLET_CHARGE, {
              amount: selectedAmount,
            });
          }
        }}
      >
        {t("Wallet.validate", {
          amount: selectedAmount,
        })}
      </Button>
      <Footer hasNoQrCodeMargin />
    </Box>
  );
};

export default Wallet;
