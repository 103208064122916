import { z } from "zod";
import { apiWrapper, digitizeClient } from "../config/axios.config";

// -------------------------------- SCHEMAS --------------------------------

const UserFridgeSchema = z.object({
  id: z.number(),
  type: z.string().nullable(),
  name: z.string(),
});

const UserSchema = z
  .object({
    firstname: z.string(),
    lastname: z.string(),
    email: z.string(),
    pairing_code: z.string(),
    uid: z.string(),
    newsletter: z.boolean(),
    devices: z.array(UserFridgeSchema),
  })
  .transform((data) => ({
    email: data.email,
    uid: data.uid,
    newsletter: data.newsletter,
    devices: data.devices,
    firstName: data.firstname,
    lastName: data.lastname,
    pairingCode: data.pairing_code,
  }));

const SignInUserSchema = z
  .object({
    token: z.string(),
    refresh_token: z.string(),
    firstname: z.string(),
    lastname: z.string(),
    email: z.string(),
  })
  .transform((data) => ({
    token: data.token,
    email: data.email,
    firstName: data.firstname,
    lastName: data.lastname,
    refreshToken: data.refresh_token,
  }));

// -------------------------------- TYPES --------------------------------

export type UserFridge = z.infer<typeof UserFridgeSchema>;

export type User = z.infer<typeof UserSchema>;

export type SignInUser = z.infer<typeof SignInUserSchema>;

// -------------------------------- API --------------------------------

const getUser = () =>
  apiWrapper(digitizeClient.get("/auth/user_info"), UserSchema.parse);

export const postSignIn = (paylod: { email: string; code: string }) =>
  apiWrapper(
    digitizeClient.post("/auth/verify", paylod),
    SignInUserSchema.parse
  );

export const postSendMail = (paylod: { email: string }) =>
  apiWrapper(digitizeClient.post("/auth/send_email", paylod));

export const deleteUser = () =>
  apiWrapper(digitizeClient.delete("/auth/delete"));

export const postRefreshToken = (payload: { refresh_token: string }) =>
  apiWrapper(
    digitizeClient.post("/auth/refresh", payload),
    SignInUserSchema.parse
  );

export type UpdateUser = {
  firstName: string;
  lastName: string;
};

export const postUser = (payload: UpdateUser) =>
  apiWrapper(
    digitizeClient.post("/auth/user_info/perso", {
      firstname: payload.firstName,
      lastname: payload.lastName,
    }),
    UserSchema.parse
  );

// -------------------------------- QUERIES --------------------------------

export const userQueryInfo = {
  refetchOnMount: true,
  queryKey: ["user"],
  queryFn: getUser,
};
