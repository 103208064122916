import { userQueryInfo } from "@Api/user.api";
import { OrSeparator } from "@Components/Separator";
import BottomModal from "@Core/BottomModal";
import Box from "@Core/Box";
import Button from "@Core/Button";
import Text from "@Core/Text";
import usePaymentStatus from "@Hooks/usePaymentStatus";
import { AUTH_NAMES } from "@Navigation/routes";
import useQRCodeStore from "@Stores/QRCode.store";
import useUserStore from "@Stores/user.store";
import { useNavigation } from "@react-navigation/native";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import QRCode from "react-native-qrcode-svg";

const QR_CODE_DIMENSION = 250;

const QRCodeModal = ({
  isQrCodeOpen,
  closeQrCodeModal,
}: {
  isQrCodeOpen: boolean;
  closeQrCodeModal: () => void;
}) => {
  const { data: userData } = useQuery(userQueryInfo);
  const uid = useUserStore((state) => state.uid);
  const pairingCode = useUserStore((state) => state.pairingCode);
  const setUidAndCode = useUserStore((state) => state.setUidAndCode);

  const { ccStatus, has2InvalidOrders, walletStatus, isPaymentLoading } =
    usePaymentStatus();
  const { userPreference } = useQRCodeStore();
  useEffect(() => {
    if (
      userData?.uid &&
      (userData.uid !== uid || userData.pairingCode !== pairingCode)
    ) {
      setUidAndCode(userData.uid, userData.pairingCode);
    }
  }, [userData, setUidAndCode, uid, pairingCode]);

  const isPaymentInvalid =
    !isPaymentLoading &&
    ((ccStatus !== "validCC" && walletStatus === "inactive") ||
      has2InvalidOrders);

  const invalidTextKey = has2InvalidOrders ? "Order" : "NoCreditCard";
  return (
    <BottomModal
      hasCloseButton
      onClose={closeQrCodeModal}
      visible={isQrCodeOpen}
    >
      <Box
        flex={1}
        pt={{ phone: "10", small: "6" }}
        pb="6"
        px="6"
        gap="3"
        justifyContent="center"
        alignItems="center"
      >
        {!isPaymentInvalid && userPreference === "qrCode" && <QRCodeContent />}
        {!isPaymentInvalid && userPreference === "badge" && <BadgeContent />}
        {isPaymentInvalid && (
          <ErrorContent
            error={invalidTextKey}
            closeQrCodeModal={closeQrCodeModal}
          />
        )}
      </Box>
    </BottomModal>
  );
};

const QRCodeContent = () => {
  const { t } = useTranslation();
  const uid = useUserStore((state) => state.uid);
  const setUserPreference = useQRCodeStore((state) => state.setUserPreference);
  return (
    <>
      <Text variant="title">{t("qrCode.title")}</Text>
      <Text textAlign="center">{t("qrCode.text")}</Text>
      <QRCode value={uid} size={QR_CODE_DIMENSION} />
      <OrSeparator width={QR_CODE_DIMENSION} />

      <Box gap="2">
        <Text>{t("qrCode.noQrCode")}</Text>

        <Text
          variant="button"
          color="green5"
          textAlign="center"
          onPress={() => setUserPreference("badge")}
        >
          {t("qrCode.link")}
        </Text>
      </Box>
    </>
  );
};

const BadgeContent = () => {
  const { t } = useTranslation();
  const pairingCode = useUserStore((state) => state.pairingCode);
  const setUserPreference = useQRCodeStore((state) => state.setUserPreference);
  return (
    <>
      <Text variant="title">{t("badge.title")}</Text>
      <Text>{t("badge.text")}</Text>
      <Box bg="purple1" p="8" width={QR_CODE_DIMENSION} borderRadius="xl">
        <Text variant="headline" color="purple6" textAlign="center">
          {pairingCode}
        </Text>
      </Box>
      <OrSeparator width={QR_CODE_DIMENSION} />
      <Box gap="2">
        <Text>{t("badge.noBadge")}</Text>
        <Text
          variant="button"
          color="green5"
          textAlign="center"
          onPress={() => setUserPreference("qrCode")}
        >
          {t("badge.link")}
        </Text>
      </Box>
    </>
  );
};

const ErrorContent = ({
  error,
  closeQrCodeModal,
}: {
  error: "Order" | "NoCreditCard";
  closeQrCodeModal: () => void;
}) => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  return (
    <>
      <Text variant="title">{t("qrCode.errorTitle")}</Text>
      <Text>
        <Trans
          i18nKey={`qrCode.error${error}Text`}
          components={{ b: <Text color="red6" /> }}
        />
        {"\n"}
      </Text>
      <Button
        variant="primary"
        onPress={() => {
          navigation.navigate("auth", {
            screen: AUTH_NAMES.CREDIT_CARD_LIST,
          });
          closeQrCodeModal();
        }}
      >
        {t("qrCode.errorButtonCC")}
      </Button>
      <Button
        variant="secondary"
        onPress={() => {
          navigation.navigate("auth", {
            screen: AUTH_NAMES.WALLET,
          });
          closeQrCodeModal();
        }}
      >
        {t("qrCode.errorButtonWallet")}
      </Button>
      <OrSeparator />
      <Text
        variant="button"
        color="green5"
        textAlign="center"
        onPress={() => {
          navigation.navigate("auth", {
            screen: AUTH_NAMES.CRISP_CHAT,
          });
          closeQrCodeModal();
        }}
      >
        {t("qrCode.errorContactUs")}
      </Text>
    </>
  );
};
export default QRCodeModal;
