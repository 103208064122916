import { SerializedOrderApi, ordersQuery } from "@Api/order.api";
import Box from "@Core/Box";
import Icon from "@Core/Icon";
import Loader from "@Core/Loader";
import Text from "@Core/Text";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Platform } from "react-native";
import Container from "../components/Container";
import Ratings from "../components/Ratings";

type Props = {
  onRate: (rate: number, order: SerializedOrderApi) => void;
};

const ASK_OPINION_SIZE = 155;

const AskOpinion = ({ onRate }: Props) => {
  const { t } = useTranslation();

  const { data: ordersData, isLoading } = useQuery(ordersQuery);

  const alreadyAnswered = typeof ordersData?.[0]?.rate === "number";

  const date = dayjs(ordersData?.[0]?.date).format("DD/MM/YYYY");

  const order = ordersData?.[0];

  if (!isLoading && !order) {
    return null;
  }

  return (
    <Box gap="2">
      <Container
        title={t("Opinion.title")}
        justifyContent="center"
        height={ASK_OPINION_SIZE}
      >
        {isLoading && <Loader />}
        {alreadyAnswered && !isLoading && (
          <Box gap="2" flexDirection="row" alignItems="center">
            <Box
              width={40}
              height={40}
              alignItems="center"
              justifyContent="center"
              borderRadius="full"
              bg="purple1"
            >
              <Icon name="checkmark-done" size={24} color="purple6" />
            </Box>
            <Box flex={1}>
              <Text textAlign="justify">{t("Opinion.textAlreadySent")}</Text>
            </Box>
          </Box>
        )}
        {!alreadyAnswered && !isLoading && (
          <>
            <Text pb="2" textAlign="justify">
              <Trans
                tOptions={{ date }}
                t={t}
                i18nKey="Opinion.text"
                components={{ s: <Text variant="button" /> }}
              />
            </Text>
            <Box
              bg="purple1"
              borderRadius="full"
              flexDirection="row"
              gap="2"
              alignSelf="center"
              alignItems="center"
            >
              <Ratings
                onRate={(rate) =>
                  order &&
                  onRate(rate, {
                    ...order,
                    date: order.date.format("DD/MM/YYYY"),
                  })
                }
                animated={Platform.OS !== "web"}
              />
            </Box>
          </>
        )}
      </Container>
    </Box>
  );
};

export default AskOpinion;
