import { postRefreshToken } from "@Api/user.api";
import { digitizeClient } from "@Config/axios.config";
import useUserStore from "@Stores/user.store";
import { getIsTokenValid, getTokenExpDelay, parseJwt } from "@Utils/api";
import { useNetInfo } from "@react-native-community/netinfo";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";

/**
 * @name useInternetRefresh
 * @description
 * This hook has the following features:
 * 1. It will refresh the token when the user is online and the token is about to expire
 * 2. It returns the information about the internet connection and the token expiration
 */
const useInternetRefresh = () => {
  const { isInternetReachable } = useNetInfo();

  const { mutate } = useMutation({
    mutationFn: postRefreshToken,
    retry: 3,
    onSuccess: (data) => {
      onLoggedIn(data);
    },
    onError: () => {
      onLoggedOut();
    },
  });

  const refreshToken = useUserStore((state) => state.signInUser?.refreshToken);

  const onLoggedIn = useUserStore((state) => state.loggedIn);

  const onLoggedOut = useUserStore((state) => state.loggedOut);

  const token = useUserStore((state) => state.signInUser?.token);

  const decodedToken = useMemo(
    () => (token ? parseJwt(token) : undefined),
    [token]
  );

  const isTokenValid = getIsTokenValid(decodedToken);
  const isOnline = isInternetReachable === null ? true : isInternetReachable;

  if (token) {
    digitizeClient.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  useEffect(() => {
    if (!decodedToken || !isOnline || !refreshToken) return;

    const expirationDelay = getTokenExpDelay(decodedToken);
    let timeout: NodeJS.Timeout | undefined;
    if (!isTokenValid && refreshToken) {
      mutate({ refresh_token: refreshToken });
    } else {
      timeout = setTimeout(() => {
        if (refreshToken) mutate({ refresh_token: refreshToken });
      }, Math.max(expirationDelay - 5000, 0));
    }

    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(timeout);
    };
  }, [decodedToken, isOnline, isTokenValid, mutate, refreshToken]);

  return {
    isOnline,
    isTokenValid,
  };
};

export default useInternetRefresh;
