import logo from "@Assets/image/logo-welcome.png";
import Box from "@Core/Box";
import Button from "@Core/Button";
import Image from "@Core/Image";
import Text from "@Core/Text";
import { UN_AUTH_NAMES, UnAuthStackScreenProp } from "@Navigation/routes";
import { WINDOW_DIMENSION } from "@Utils/dimensions";
import { useTranslation } from "react-i18next";
import { StatusBar } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

type WelcomeProps = UnAuthStackScreenProp<"Welcome">;

const Welcome = ({ navigation }: WelcomeProps) => {
  const { top, bottom } = useSafeAreaInsets();
  const { t } = useTranslation();

  return (
    <Box flex={1} bg="white" gap="6" px="4">
      <Box height={top} />
      <Box flex={1} alignItems="center" justifyContent="center">
        <Image
          source={logo}
          width={WINDOW_DIMENSION.width - 32}
          height={WINDOW_DIMENSION.width - 32}
          contentFit="cover"
        />
      </Box>
      <Box>
        <Text variant="title" textAlign="center" fontSize={48} lineHeight={58}>
          {t("welcome.title")}
        </Text>
        <Text textAlign="center">{t("welcome.text")}</Text>
      </Box>
      <Button
        onPress={() => {
          navigation.navigate(UN_AUTH_NAMES.FRIDGE_SELECTION);
        }}
      >
        {t("welcome.next")}
      </Button>

      <Box height={bottom} />
      <StatusBar
        translucent
        barStyle="dark-content"
        backgroundColor="transparent"
      />
    </Box>
  );
};

export default Welcome;
