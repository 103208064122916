import { ordersQuery } from "@Api/order.api";
import { allSavedCardsQuery, walletBalanceQuery } from "@Api/wallet.api";
import { getCCStatus } from "@Utils/card";
import { useQuery } from "@tanstack/react-query";

const usePaymentStatus = (): {
  isPaymentLoading: boolean;
  walletStatus: "active" | "inactive";
  ccStatus: ReturnType<typeof getCCStatus>;
  has2InvalidOrders: boolean;
} => {
  const { data: savedCards, isLoading: isSavedCardsLoading } =
    useQuery(allSavedCardsQuery);

  const { data: wallet, isLoading: isWalletLoading } =
    useQuery(walletBalanceQuery);

  const { data: orders, isLoading: isOrdersLoading } = useQuery(ordersQuery);

  const isPaymentLoading =
    isSavedCardsLoading || isWalletLoading || isOrdersLoading;

  const balance = wallet?.balance || 0;

  const walletStatus = balance > 0 ? "active" : "inactive";

  const ccStatus = getCCStatus(savedCards, balance);

  const has2InvalidOrders =
    (orders?.filter((order) => order.status === "processing").length || 0) >= 2;

  return {
    isPaymentLoading,
    walletStatus,
    ccStatus,
    has2InvalidOrders,
  };
};

export default usePaymentStatus;
