import { IconsName } from "@Icons/type";
import { Theme, fonts, useTheme } from "@Utils/theme";
import { AllProps, all, createRestyleComponent } from "@shopify/restyle";
import React, { RefObject } from "react";
import { Control, Controller, Path } from "react-hook-form";
import { TextInput } from "react-native";
import Box from "./Box";
import Icon from "./Icon";
import Pressable from "./Pressable";
import Text from "./Text";

const hitSlop = { top: 8, bottom: 8, left: 8, right: 8 };

type InputProps = Omit<
  React.ComponentProps<typeof TextInput>,
  "placeholder"
> & {
  error?: string | null;
  label: string;
  underlineLabel?: string | null;
  inputRef?: RefObject<TextInput>;
  iconRight?: IconsName;
  onPressIconRight?: () => void;
  labelVariant?: "default" | "large";
  gap?: keyof Theme["spacing"];
  placeholder?: string | null;
};
const Input = ({
  error,
  iconRight,
  gap,
  onPressIconRight,
  inputRef,
  label,
  underlineLabel,
  labelVariant = "default",
  placeholder,
  ...props
}: InputProps) => {
  const theme = useTheme();
  return (
    <Box flexDirection="column" pb="4">
      <Text
        pb={labelVariant === "default" ? undefined : "3"}
        variant={labelVariant === "default" ? "button" : "title"}
      >
        {label}
        <Text
          variant={labelVariant === "default" ? "button" : "title"}
          textDecorationLine="underline"
        >
          {underlineLabel}
        </Text>
      </Text>
      <Box mt={gap ?? "1"}>
        <RestyleInput
          bg="grey1"
          fontSize={14}
          px="2"
          lineHeight={17}
          py="3"
          borderRadius="m"
          ref={inputRef}
          fontFamily={fonts.regular}
          placeholderTextColor={theme.colors.grey4}
          placeholder={placeholder ?? ""}
          {...props}
        />
        {iconRight ? (
          <Pressable
            position="absolute"
            right={8}
            top={0}
            hitSlop={hitSlop}
            bottom={0}
            justifyContent="center"
            onPress={onPressIconRight}
            style={({ pressed }) => ({
              opacity: pressed ? 0.5 : 1,
            })}
          >
            <Icon name={iconRight} size={24} color="grey6" />
          </Pressable>
        ) : null}
      </Box>
      {error && (
        <Box position="absolute" bottom={0}>
          <Text variant="legend" color="red6">
            {error}
          </Text>
        </Box>
      )}
    </Box>
  );
};

export const ControlledInput = <FieldValues extends Record<string, any>>({
  control,
  name,
  ...props
}: InputProps & {
  control: Control<FieldValues, any>;
  name: Path<FieldValues>;
}) => (
  <Controller
    name={name}
    control={control}
    rules={{ required: true }}
    render={({ field: { onChange, onBlur, value } }) => (
      <Input
        {...{
          onBlur,
          onChangeText: (text) => onChange(text),
          value,
        }}
        {...props}
      />
    )}
  />
);

const RestyleInput = createRestyleComponent<
  React.ComponentProps<typeof TextInput> & AllProps<Theme>,
  Theme
  // @ts-ignore
>([all], TextInput);
export default Input;
