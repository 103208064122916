import leaf from "@Assets/image/leaf.svg";
import Box from "@Core/Box";
import Image from "@Core/Image";
import React from "react";

const CarbonLeaf = () => (
  <Box
    height={64}
    alignItems="center"
    justifyContent="center"
    width={64}
    bg="green1"
    borderRadius="full"
  >
    <Image source={leaf as any as string} height={42} width={42} />
  </Box>
);
export default CarbonLeaf;
