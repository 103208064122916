import { apiWrapper, digitizeClient } from "@Config/axios.config";
import dayjs from "dayjs";
import { z } from "zod";

// ----------------------- SCHEMAS -----------------------

const WalletBalanceSchema = z.object({
  balance: z.number().nullable(),
});

const WalletRechargeSchema = z.object({
  messages: z.string(),
  url: z.string(),
  status: z.string(),
});

const WalletRegisterSchema = z.object({
  messages: z.string(),
  url: z.string(),
  status: z.string(),
});

const AllSavedCardsSchema = z.array(
  z
    .object({
      id: z.number(),
      token: z.string(),
      type: z.string(),
      pan: z.string(),
      expiration_date: z.string().nullable(),
    })
    .transform(({ expiration_date, ...rest }) => ({
      ...rest,
      expirationDate: dayjs(expiration_date, ["M/YYYY", "MMYY"])
        .add(1, "month")
        .subtract(1, "day"),
    }))
);

const DeleteCardSchema = z.object({
  message: z.string(),
  success: z.boolean(),
});

// ----------------------- TYPES -----------------------

export type WalletBalance = Zod.infer<typeof WalletBalanceSchema>;

export type WalletRecharge = Zod.infer<typeof WalletRechargeSchema>;

export type WalletRegister = Zod.infer<typeof WalletRegisterSchema>;

export type AllSavedCards = Zod.infer<typeof AllSavedCardsSchema>;

export type SavedCard = AllSavedCards[number];

// ----------------------- API -----------------------

const getWalletBalance = () =>
  apiWrapper(digitizeClient.get("/wallet"), WalletBalanceSchema.parse);

const postRechargeWallet = (amount: number) =>
  apiWrapper(
    digitizeClient.post("/wallet/charge", {
      amount,
    }),
    WalletRechargeSchema.parse
  );

const postRegisterWallet = () =>
  apiWrapper(
    digitizeClient.get("/wallet/cards/register"),
    WalletRegisterSchema.parse
  );

const getAllSavedCards = () =>
  apiWrapper(digitizeClient.get("/wallet/cards"), AllSavedCardsSchema.parse);

export const deleteSavedCard = ({ id }: { id: number }) =>
  apiWrapper(
    digitizeClient.delete(`/wallet/cards/${id}`),
    DeleteCardSchema.parse
  );

// ----------------------- QUERY -----------------------

export const walletBalanceQuery = {
  queryKey: ["walletBalance"],
  queryFn: getWalletBalance,
  refetchOnFocus: true,
  staleTime: 0,
};

export const rechargeWalletQuery = (amount: number) => ({
  queryKey: ["rechargeWallet", amount],
  queryFn: () => postRechargeWallet(amount),
  staleTime: 0,
  cacheTime: 0,
  refetchOnMount: true,
});

export const registerCardQuery = {
  queryKey: ["registerWallet"],
  queryFn: postRegisterWallet,
  staleTime: 0,
  cacheTime: 0,
  refetchOnMount: true,
};

export const allSavedCardsQuery = {
  queryKey: ["allSavedCards"],
  queryFn: getAllSavedCards,
};
