import Icon from "@Core/Icon";
import { fonts, useTheme } from "@Utils/theme";
import { useNavigation } from "@react-navigation/native";
import {
  StackNavigationOptions,
  TransitionPresets,
} from "@react-navigation/stack";
import { useTranslation } from "react-i18next";

export const useHeaderGreen = () => {
  const theme = useTheme();
  const headerStyle = {
    headerStyle: { backgroundColor: theme.colors.green6 },
    headerTintColor: theme.colors.white,
    headerTitleAlign: "center",
    headerShadowVisible: false,
    animationEnabled: true,
    headerBackTitleVisible: false,
    headerTitleStyle: {
      fontFamily: fonts.bold,
      fontSize: 16,
    },
  } satisfies StackNavigationOptions;
  return headerStyle;
};

export const useHeaderGreenModal = () => {
  const theme = useTheme();
  const navigation = useNavigation();
  const headerStyle = {
    headerStyle: { backgroundColor: theme.colors.green6 },
    headerTintColor: theme.colors.white,
    headerTitleAlign: "center",
    headerShadowVisible: false,
    headerLeft: () => null,
    animationEnabled: true,
    headerBackTitleVisible: false,
    presentation: "modal",
    headerTitleStyle: {
      fontFamily: fonts.bold,
      fontSize: 16,
    },
    ...TransitionPresets.ModalSlideFromBottomIOS,
    headerRight: ({ canGoBack }) => (
      <Icon
        name="close"
        color="white"
        size={24}
        onPress={() => {
          if (canGoBack) navigation.goBack();
        }}
      />
    ),
    cardStyle: { backgroundColor: theme.colors.green6 },
  } satisfies StackNavigationOptions;
  return headerStyle;
};

export const useGetScreenName = () => {
  const { t } = useTranslation();

  const getScreenName = (routeName: string) => ({
    title: t(`navigation.${routeName}`) ?? routeName,
  });

  return { getScreenName };
};
