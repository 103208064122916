export const MEAL_TYPES = {
  veggie: "veggie",
  meat: "meat",
  fish: "fish",
  vegan: "vegan",
  unknown: "unknown",
} as const;

export type MealType = (typeof MEAL_TYPES)[keyof typeof MEAL_TYPES];

export type MealReheatOption = "reheat" | "cold" | "either" | "unknown";

export type ProductTypology = "main" | "starter" | "dessert" | "snack";
