import { z } from "zod";
import { apiWrapper, boClient } from "../config/axios.config";

// -------------------------------- SCHEMAS --------------------------------
const ProductSchema = z
  .object({
    id: z.number(),
    ref: z.number(),
    name: z.string(),
    kind: z.enum(["veggie", "meat", "fish", "vegan", "unknown"]),
    hasAlcohol: z.boolean(),
    hasPork: z.boolean(),
    warmUpKind: z.enum(["cold", "hot", "either", "unknown"]),
    weight: z.number().transform((data) => Math.round(data)),
    allergens: z.array(z.string()),
    ingredients: z.string(),
  })
  .transform((data) => ({
    ...data,
    ingredients: data.ingredients.split(",").filter(Boolean),
  }));

// -------------------------------- TYPES --------------------------------

export type Product = z.infer<typeof ProductSchema>;

// -------------------------------- API --------------------------------

export const getProducts = (id: number) =>
  apiWrapper(boClient.get(`/products/infos/${id}`), ProductSchema.parse);

// -------------------------------- QUERIES --------------------------------

export const getProductsQuery = (id: number) => ({
  queryKey: ["products", id],
  queryFn: () => getProducts(id),
});
