import rate2 from "@Assets/image/reactions/2.png";
import rate3 from "@Assets/image/reactions/3.png";
import rate4 from "@Assets/image/reactions/4.png";
import Box from "@Core/Box";
import Image from "@Core/Image";
import Pressable from "@Core/Pressable";
import Text from "@Core/Text";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  name: string;
  rating?: number;
  onRate: (rating: number) => void;
};
const RATINGS = [
  { label: "UserOpinion.dislikeProduct", value: -1, image: rate2 },
  { label: "UserOpinion.noOpinion", value: 0, image: rate3 },
  { label: "UserOpinion.likeProduct", value: 1, image: rate4 },
];
const WIDTH_OPINION = 105;
const ProductRating = ({ name, rating, onRate }: Props) => {
  const { t } = useTranslation();
  return (
    <Box my="4" gap="2">
      <Text variant="button">{name}</Text>
      <Box flexDirection="row" justifyContent="space-between">
        {RATINGS.map(({ label, image, value }) => (
          <Pressable
            p="2"
            width={WIDTH_OPINION}
            onPress={() => onRate?.(value)}
            key={label}
            borderRadius="l"
            bg={rating === value ? "purple1" : undefined}
            alignItems="center"
          >
            <Image source={image} width={48} height={48} />
            <Text
              variant="button"
              color={rating === value ? "purple7" : "grey7"}
            >
              {t(label)}
            </Text>
          </Pressable>
        ))}
      </Box>
    </Box>
  );
};

export default ProductRating;
