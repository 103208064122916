import React from "react";
import Box from "./core/Box";
import Text from "./core/Text";

type OrderLabelStatus = "cancel" | "payed" | "processing" | "error" | "default";
type OrderLabelProps = {
  label: string;
  status?: OrderLabelStatus;
};

const labelToColors = {
  cancel: {
    bg: "yellow1",
    color: "yellow7",
  },
  payed: {
    bg: "purple1",
    color: "purple7",
  },
  processing: {
    bg: "green1",
    color: "green7",
  },
  error: {
    bg: "red1",
    color: "red7",
  },
  default: {
    bg: "grey1",
    color: "grey7",
  },
} as const;
const OrderLabel = ({ label, status = "default" }: OrderLabelProps) => {
  const { bg, color } = labelToColors[status];
  return (
    <Box
      borderRadius="m"
      flexShrink={1}
      height={26}
      justifyContent="center"
      alignItems="center"
      px="2"
      bg={bg}
    >
      <Text numberOfLines={1} lineHeight={26} color={color}>
        {label}
      </Text>
    </Box>
  );
};

export default OrderLabel;
