import Box, { BoxProps } from "@Core/Box";
import Text from "@Core/Text";
import React from "react";
import { useTranslation } from "react-i18next";

const Separator = (props: BoxProps) => (
  <Box height={1} width="100%" bg="grey2" {...props} />
);

const OrSeparator = (props: BoxProps) => {
  const { t } = useTranslation();
  return (
    <Box
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      flex={1}
      {...props}
      gap="4"
    >
      <Separator height={2} bg="grey6" width={undefined} flex={1} />
      <Box>
        <Text color="grey6">{t("common.or").toUpperCase()}</Text>
      </Box>
      <Separator height={2} bg="grey6" width={undefined} flex={1} />
    </Box>
  );
};

export { OrSeparator };

export default Separator;
