import { WINDOW_DIMENSION } from "@Utils/dimensions";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal, StyleSheet, TouchableOpacity, ViewStyle } from "react-native";
import {
  Gesture,
  GestureDetector,
  gestureHandlerRootHOC,
} from "react-native-gesture-handler";
import Animated, {
  Easing,
  runOnJS,
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import Box from "./Box";
import Icon from "./Icon";
import Pressable from "./Pressable";

type ConfigProps = {
  margin: number;
  padding: number;
  borderRadius: number;
  backdropColor: string;
  backgroundColor: string;
  maxHeight: number;
};

type BottomModalProps = {
  visible: boolean;
  onClose: () => void;
  /** SHOULD BE A CALLBACK */
  onHasClosed?: () => void;
  hasCloseButton?: boolean;
  children: React.ReactNode;
} & Partial<ConfigProps>;

const defaultConfigProps: ConfigProps = {
  margin: 16,
  padding: 0,
  borderRadius: 16,
  backdropColor: "rgba(31,41,51,0.25)",
  backgroundColor: "white",
  maxHeight: WINDOW_DIMENSION.height,
};

const BottomModal = ({
  onClose,
  visible,
  onHasClosed,
  hasCloseButton,
  children = null,
  ...configProps
}: BottomModalProps) => {
  const {
    margin,
    padding,
    borderRadius,
    backdropColor,
    backgroundColor,
    maxHeight,
  } = { ...defaultConfigProps, ...configProps };
  /**
   * yPan corresponds to the current position of the bottom sheet when it is being dragged.
   * **yPan=0** means the bottom sheet is **at the top**.
   * **yPan=bottomSheetHeight** means the bottom sheet is **at the bottom**.
   * Initial value is 1000 to make sure the first animation is from the bottom
   */
  const yPan = useSharedValue(1000);
  const yVelocity = useSharedValue(0);
  const bottomSheetHeight = useSharedValue(0);
  const [internalVisible, setInternalVisible] = useState(visible);

  const { bottom } = useSafeAreaInsets();

  const transition = useDerivedValue(
    () =>
      (bottomSheetHeight.value - yPan.value + 1) /
      (bottomSheetHeight.value + 1),
    [bottomSheetHeight.value, yPan.value]
  );

  const marginStyle: ViewStyle = useMemo(
    () => ({
      margin,
      marginBottom: margin ? margin + bottom : 0,
      padding,
      paddingBottom: margin ? padding : padding + bottom,
      borderRadius: margin === 0 ? 0 : borderRadius,
      borderTopLeftRadius: borderRadius,
      overflow: "hidden",
      borderTopRightRadius: borderRadius,
      width: WINDOW_DIMENSION.width - margin * 2,
      backgroundColor,
    }),
    [margin, bottom, padding, borderRadius, backgroundColor]
  );

  const onCloseModal = useCallback(() => {
    "worklet";

    yPan.value = withTiming(
      bottomSheetHeight.value + 100,
      { duration: 200, easing: Easing.inOut(Easing.ease) },
      () => {
        runOnJS(onClose)();
        runOnJS(setInternalVisible)(false);
      }
    );
  }, [bottomSheetHeight.value, onClose, yPan]);

  const onOpenModal = useCallback(() => {
    "worklet";

    runOnJS(setInternalVisible)(true);
    yPan.value = withTiming(0, {
      duration: 200,
      easing: Easing.inOut(Easing.ease),
    });
  }, [yPan]);

  const pan = Gesture.Pan()
    .onChange(({ translationY }) => {
      yVelocity.value = translationY - yPan.value;
      yPan.value = Math.max(translationY, 0);
    })
    .onEnd(({ translationY }) => {
      if (translationY > bottomSheetHeight.value / 2 || yVelocity.value > 5) {
        onCloseModal();
      } else {
        onOpenModal();
      }
    });

  const modalStyle = useAnimatedStyle(() => ({
    transform: [{ translateY: yPan.value }],
  }));

  const backgroundStyle = useAnimatedStyle(
    () => ({
      backgroundColor: backdropColor,
      opacity: transition.value,
    }),
    [backdropColor, transition]
  );

  const contentHeight = useDerivedValue(() =>
    bottomSheetHeight.value > maxHeight
      ? maxHeight - 2 * padding - bottom
      : undefined
  );

  const scrollViewStyle = useAnimatedStyle(() => ({
    height: contentHeight.value,
  }));

  useEffect(() => {
    if (visible) {
      onOpenModal();
    } else {
      onCloseModal();
    }
  }, [onOpenModal, visible, onCloseModal]);

  useEffect(() => {
    if (!visible && !internalVisible) {
      onHasClosed?.();
    }
  }, [internalVisible, onHasClosed, visible]);

  return (
    <Modal
      visible={visible || internalVisible}
      transparent
      animationType="none"
      onRequestClose={onCloseModal}
      statusBarTranslucent
    >
      {/**  @ts-ignore https://github.com/software-mansion/react-native-gesture-handler/pull/2413 */}
      <HocContainer>
        {visible || internalVisible ? (
          <>
            <Animated.View style={[styles.container, backgroundStyle]} />
            <GestureDetector gesture={pan}>
              <Animated.View
                onLayout={(e) => {
                  // eslint-disable-next-line no-param-reassign
                  bottomSheetHeight.value = e.nativeEvent.layout.height;
                }}
                style={[styles.modal, modalStyle, marginStyle]}
              >
                <Animated.ScrollView
                  bounces={false}
                  showsVerticalScrollIndicator={false}
                  style={scrollViewStyle}
                >
                  <Box collapsable={false}>
                    {children}
                    {hasCloseButton && (
                      <Pressable
                        position="absolute"
                        top={0}
                        right={0}
                        mt="4"
                        mr="4"
                        onPress={onCloseModal}
                      >
                        <Icon name="close-outline" size={32} color="grey10" />
                      </Pressable>
                    )}
                  </Box>
                </Animated.ScrollView>
              </Animated.View>
            </GestureDetector>
            <TouchableOpacity style={styles.container} onPress={onCloseModal} />
          </>
        ) : null}
      </HocContainer>
    </Modal>
  );
};

const HocContainer = gestureHandlerRootHOC(
  // @ts-ignore https://github.com/software-mansion/react-native-gesture-handler/pull/2413
  ({ children }: { children: React.ReactNode }) => children
);
const styles = StyleSheet.create({
  container: {
    position: "absolute",
    top: 0,
    bottom: 0,
    backgroundColor: "transparent",
    left: 0,
    right: 0,
  },
  modal: {
    position: "absolute",
    zIndex: 2,
    maxWidth: 800,
    alignSelf: "center",
    bottom: 0,
  },
});

export default BottomModal;
