import { postUser, userQueryInfo } from "@Api/user.api";
import Box from "@Core/Box";
import Button from "@Core/Button";
import { ControlledInput } from "@Core/Input";
import useHideQrCode from "@Hooks/useHideQrCode";
import useUserStore from "@Stores/user.store";
import { signUpNameSchema } from "@Types/users";
import { useTheme } from "@Utils/theme";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { StatusBar, TextInput } from "react-native";
import { z } from "zod";

const SignUpName = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const queryClient = useQueryClient();

  const firstName = useUserStore((state) => state.signInUser?.firstName);
  const lastName = useUserStore((state) => state.signInUser?.lastName);

  const ref = useRef<TextInput>(null);

  const updateInfo = useUserStore((state) => state.updateInfo);
  const loggedOut = useUserStore((state) => state.loggedOut);

  const { mutate } = useMutation({
    mutationFn: postUser,
    onSuccess: ({ firstName: updatedFirstName, lastName: updatedLastName }) => {
      updateInfo({ firstName: updatedFirstName, lastName: updatedLastName });
      queryClient.invalidateQueries(userQueryInfo.queryKey);
    },
    onError: loggedOut,
  });

  useHideQrCode();

  type FormValues = z.infer<typeof signUpNameSchema>;

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    mode: "onSubmit",
    resolver: zodResolver(signUpNameSchema),
    defaultValues: {
      firstName: firstName?.trim(),
      lastName: lastName?.trim(),
    },
  });

  const onSubmit = useCallback(() => {
    handleSubmit(({ firstName: updatedFirstName, lastName: updatedLastName }) =>
      mutate({
        firstName: updatedFirstName,
        lastName: updatedLastName,
      })
    )();
  }, [handleSubmit, mutate]);

  return (
    <Box flex={1} bg="white" p="4">
      <ControlledInput
        autoFocus
        control={control}
        name="lastName"
        onSubmitEditing={() => {
          ref.current?.focus();
        }}
        autoComplete="name-family"
        returnKeyType="next"
        error={t(errors.lastName?.message as string)}
        label={t("SignUpName.labelLastName")}
        underlineLabel={t("SignUpName.underlineLabelLastName")}
        labelVariant="large"
        placeholder={t("SignUpName.placeholderLastName")}
      />
      <Box height={16} />
      <ControlledInput
        autoComplete="name-given"
        control={control}
        inputRef={ref}
        name="firstName"
        error={t(errors.firstName?.message as string)}
        label={t("SignUpName.labelFirstName")}
        underlineLabel={t("SignUpName.underlineLabelFirstName")}
        labelVariant="large"
        placeholder={t("SignUpName.placeholderFirstName")}
        onSubmitEditing={onSubmit}
      />

      <Button
        flex={0}
        variant={isValid ? "primary" : "disabled"}
        alignSelf="flex-end"
        onPress={onSubmit}
      >
        {t("SignUpName.next")}
      </Button>
      <StatusBar
        barStyle="light-content"
        backgroundColor={theme.colors.green6}
      />
    </Box>
  );
};

export default SignUpName;
