import Box from "@Core/Box";
import Button from "@Core/Button";
import Icon from "@Core/Icon";
import Pressable from "@Core/Pressable";
import Text from "@Core/Text";
import { WINDOW_DIMENSION } from "@Utils/dimensions";
import React from "react";
import { useTranslation } from "react-i18next";

const HEIGHT = 152;

type RestaurantTicketThumbProps = {
  onAddRestaurantTicket: () => void;
  onNoRestaurantTicket: () => void;
};
const RestaurantTicketThumb = ({
  onAddRestaurantTicket,
  onNoRestaurantTicket,
}: RestaurantTicketThumbProps) => {
  const { t } = useTranslation();
  return (
    <Box
      bg="white"
      mx="4"
      borderWidth={1}
      borderColor="grey2"
      height={HEIGHT}
      width={WINDOW_DIMENSION.width - 32}
      borderRadius="xl"
      flexDirection="column"
      p="3"
      justifyContent="space-between"
      g="2"
    >
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text variant="button">{t("home.restaurantTicketTitle")}</Text>
        <Pressable
          onPress={onNoRestaurantTicket}
          hitSlop={{
            top: 10,
            bottom: 10,
            left: 10,
            right: 10,
          }}
        >
          <Icon name="close" size={24} color="grey6" />
        </Pressable>
      </Box>
      <Text>{t("home.restaurantTicketText")}</Text>
      <Box flexDirection="row" gap="2">
        <Button
          horizontalFlex={1}
          variant="secondary"
          onPress={onNoRestaurantTicket}
        >
          {t("home.restaurantTicketButton2")}
        </Button>
        <Button
          horizontalFlex={1}
          variant="primary"
          onPress={onAddRestaurantTicket}
        >
          {t("home.restaurantTicketButton")}
        </Button>
      </Box>
    </Box>
  );
};

export default RestaurantTicketThumb;
