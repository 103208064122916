import { z } from "zod";
import dayjs from "dayjs";
import { apiWrapper, digitizeClient } from "../config/axios.config";

// ------------------ Schema ------------------

const orderStatus = {
  "Commande payée": "payed",
  "Votre récapitulatif de commande": "processing",
  "Commande annulée": "cancel",
  "Erreur de paiement": "error",
} as const;

const isOrderStatus = (status: string): status is keyof typeof orderStatus =>
  status in orderStatus;

const OrderSchema = z
  .object({
    id: z.number(),
    reference: z.string(),
    status: z.string(),
    date: z.string().transform((date) => dayjs(date)),
    device: z.object({ id: z.number(), name: z.string() }),
    amount: z.number(),
    payment_methods: z.array(
      z.object({ name: z.string().nullable(), amount: z.number().nullable() })
    ),
    rate: z.number().nullable(),
    products: z.array(
      z.object({
        id: z.number(),
        name: z.string(),
        order_detail_id: z.number(),
        price: z.number(),
        vat: z.number().nullable(),
        vat_rate: z.number().nullable(),
        quantity: z.number(),
        rate: z.number().nullable(),
        content: z
          .array(
            z.object({
              id: z.number(),
              name: z.string().optional(),
              quantity: z.number(),
              digitiz_order_combination_id: z.number(),
              rate: z.number().nullable(),
            })
          )
          .nullable(),
      })
    ),
  })
  .transform((order) => ({
    ...order,
    amount:
      order.amount -
      (order.payment_methods.find(
        (payment_method) => payment_method.name === "ENTREPRISE"
      )?.amount ?? 0),
    subventions:
      order.payment_methods.find(
        (payment_method) => payment_method.name === "ENTREPRISE"
      )?.amount ?? 0,

    status: isOrderStatus(order.status)
      ? orderStatus[order.status]
      : ("default" as const),
    label: order.status,
  }));

const InvoiceSchema = z
  .object({
    invoice: z.string(),
  })
  .transform((invoice) => invoice.invoice);
// ------------------ TYPE ------------------

export type OrderLabelStatus =
  | "cancel"
  | "payed"
  | "processing"
  | "error"
  | "default";

export type OrderApi = z.infer<typeof OrderSchema>;

export type SerializedOrderApi = Omit<OrderApi, "date"> & {
  date: string;
};

// ------------------ API ------------------

const getOrders = () =>
  apiWrapper(digitizeClient.get("/orders"), z.array(OrderSchema).parse);

export const getInvoice = (id: number) =>
  apiWrapper(digitizeClient.get(`/orders/${id}/invoice`), InvoiceSchema.parse);

export const postRate = ({
  id,
  payload,
}: {
  id: number;
  payload: {
    score: number;
    products: {
      order_detail_id?: null | number;
      digitiz_order_combination_id?: null | number;
      value: number;
    }[];
  };
}) => apiWrapper(digitizeClient.post(`orders/${id}/rate`, payload));

// ------------------ QUERY ------------------

export const ordersQuery = {
  queryKey: ["orders"],
  queryFn: getOrders,
};
