import useTracking from "@Hooks/useTracking";
import RootNavigator, { configRootNavigator } from "@Navigation/RootNaviagtor";
import navigationRef from "@Navigation/navigationRef";
import { RootStackParamList } from "@Navigation/routes";

import theme from "@Utils/theme";
import {
  Lato_400Regular,
  Lato_400Regular_Italic,
  Lato_700Bold,
  useFonts,
} from "@expo-google-fonts/lato";
import { LinkingOptions, NavigationContainer } from "@react-navigation/native";
import { ThemeProvider } from "@shopify/restyle";
import * as NavigationBar from "expo-navigation-bar";
import * as SplashScreen from "expo-splash-screen";
import { Suspense, useCallback, useRef } from "react";
import { Platform } from "react-native";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { enableFreeze } from "react-native-screens";
// CONFIGURATIONS Files
import "@Config/i18n.config";
import queryClient, { useHandleFocus } from "@Config/query.config";
import "@Utils/sentry";
import { QueryClientProvider } from "@tanstack/react-query";
import dayjs from "dayjs";

const customParseFormat = require("dayjs/plugin/customParseFormat");

require("dayjs/locale/fr");

if (Platform.OS === "android") {
  NavigationBar.setBackgroundColorAsync(theme.colors.grey1);
}

dayjs.locale("fr");
dayjs.extend(customParseFormat);

enableFreeze(true);

SplashScreen.preventAutoHideAsync();

if (!__DEV__) {
  /*   // eslint-disable-next-line no-console
  console.log = () => {};
  // eslint-disable-next-line no-console
  console.error = () => {};
  // eslint-disable-next-line no-console
  console.warn = () => {}; */
}

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [],
  config: configRootNavigator,
};

const App = () => {
  const [fontsLoaded] = useFonts({
    Lato_400Regular,
    Lato_400Regular_Italic,
    Lato_700Bold,
  });

  useHandleFocus();

  const routeNameRef = useRef<string>();
  const { trackEvent } = useTracking();

  const onLayoutRootView = useCallback(async () => {
    if (fontsLoaded) {
      await SplashScreen.hideAsync();
    }
  }, [fontsLoaded]);

  if (!fontsLoaded) {
    return null;
  }
  return (
    <Suspense>
      <SafeAreaProvider>
        <GestureHandlerRootView onLayout={onLayoutRootView} style={{ flex: 1 }}>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
              <NavigationContainer<RootStackParamList>
                linking={linking}
                onReady={() => {
                  routeNameRef.current =
                    navigationRef.current?.getCurrentRoute()?.name;
                }}
                onStateChange={() => {
                  const previousRouteName = routeNameRef.current;
                  const screenName =
                    navigationRef.current?.getCurrentRoute()?.name;
                  if (previousRouteName !== screenName && screenName) {
                    trackEvent("screenView", { screenName });
                    routeNameRef.current = screenName;
                  }
                }}
                ref={navigationRef}
              >
                <RootNavigator />
              </NavigationContainer>
            </ThemeProvider>
          </QueryClientProvider>
        </GestureHandlerRootView>
      </SafeAreaProvider>
    </Suspense>
  );
};

export default App;
