import { Path, Svg } from "react-native-svg";
import { Icon } from "./type";

const Alcohol = ({ color, size }: Icon) => (
  <Svg width={size} height={size} viewBox="0 0 14 18" fill="none">
    <Path
      d="M0.666626 4.66667V2.66667L1.33329 1H12.6666L13.3333 2.66667V4.66667M0.666626 4.66667H13.3333M0.666626 4.66667L0.999959 6.33333L1.99996 8L2.99996 9.33333L4.66663 10.3333L5.33329 10.6667H6.33329M13.3333 4.66667L13 6.33333L12 8L11 9.33333L9.33329 10.3333L8.66663 10.6667H6.33329M6.33329 10.6667V14.6667L3.33329 16.6667V17H10.6666V16.6667L7.66663 14.6667V10.8333"
      stroke={color}
    />
  </Svg>
);

export default Alcohol;
