import { z } from "zod";
import { apiWrapper, digitizeClient } from "../config/axios.config";

// -------------------------------- SCHEMAS --------------------------------
const FridgeSchema = z.object({
  device_id: z.number(),
  device_name: z.string(),
  type: z.string().nullable(),
  is_open: z.boolean(),
  menus: z.array(
    z.object({
      id: z.number(),
      img_url: z.string(),
      title: z.string(),
      price: z.number(),
      old_price: z.number(),
    })
  ),
  for_you: z.array(
    z.object({
      id: z.number(),
      img_url: z.string(),
      title: z.string(),
      price: z.number(),
      old_price: z.number(),
    })
  ),
  categories: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      img_url: z.string(),
      products: z.array(
        z.object({
          id: z.number(),
          img_url: z.string(),
          name: z.string(),
          stock: z.number(),
          old_price: z.number(),
          price: z.number(),
          labels: z.array(
            z.object({
              id: z.number(),
              name: z.string(),
              img_url: z.string(),
            })
          ),
        })
      ),
    })
  ),
  medias: z.object({
    img_cover_url: z.string(),
  }),
});

// -------------------------------- TYPES --------------------------------

export type Fridge = z.infer<typeof FridgeSchema>;

export type FridgeProduct = Fridge["categories"][0]["products"][0];

// -------------------------------- API --------------------------------

const getFridge = (id: number) =>
  apiWrapper(digitizeClient.get(`/catalog/devices/${id}`), (data) =>
    FridgeSchema.parse(data)
  );

// -------------------------------- QUERIES --------------------------------

export const getFridgeQuery = (id: number) => ({
  queryKey: ["fridges", id],
  queryFn: () => getFridge(id),
  refetchOnMount: true,
});
