import Box from "@Core/Box";
import Text from "@Core/Text";
import { useTranslation } from "react-i18next";
import { fonts } from "@Utils/theme";
import Container from "./Container";

const FootprintMonthlyGraph = ({
  monthSaved,
}: {
  monthSaved: { carbon: number; month: string }[];
}) => {
  const { t } = useTranslation();
  const maxSaved = Math.max(...monthSaved.map((m) => m.carbon));

  const SAVED_HEIGHT = 200;

  const monthCarbonText = (monthCarbon: number) => {
    if (monthCarbon > 100) {
      return `${monthCarbon / 1000}kg`;
    }
    if (monthCarbon === 0) return "";
    return `${monthCarbon}g`;
  };

  return (
    <Container gap="3" title={t("CarbonFootprint.monthlyTitle")}>
      <Box flexDirection="row">
        <Box gap="2" flex={1}>
          <Box
            flexDirection="row"
            alignItems="flex-end"
            justifyContent="space-around"
          >
            {monthSaved.map((month) => (
              <Box alignItems="center" gap="1" key={month.month}>
                <Text fontSize={12} textAlign="center" color="grey8">
                  {monthCarbonText(month.carbon)}
                </Text>
                <Box
                  key={month.month}
                  width={10}
                  height={SAVED_HEIGHT * (month.carbon / maxSaved)}
                  bg="purple3"
                  borderRadius="full"
                />
              </Box>
            ))}
          </Box>
          <Box height={2} bg="grey5" borderRadius="full" />
          <Box flexDirection="row" justifyContent="space-around">
            {monthSaved.map((month) => (
              <Text key={month.month} variant="legend" color="grey8">
                {month.month}
              </Text>
            ))}
          </Box>
        </Box>
      </Box>
      <Text textAlign="center" fontFamily={fonts.regularItalic} color="grey8">
        {t("CarbonFootprint.monthlyLegend")}
      </Text>
    </Container>
  );
};
export default FootprintMonthlyGraph;
