import Box from "@Core/Box";
import Icon from "@Core/Icon";
import Text from "@Core/Text";
import { IconsName } from "@Icons/type";
import { WINDOW_DIMENSION } from "@Utils/dimensions";
import { fonts } from "@Utils/theme";

import React from "react";

export type NewsProps = {
  title: string;
  content: string;
  icon: IconsName;
  variant: "purple" | "green" | "red" | "yellow";
};

const NEWS_HEIGHT = 152;

const News = ({ title, content, icon, variant }: NewsProps) => {
  const variantColor = {
    purple: {
      text: "purple6",
      icon: "purple3",
      bgIcon: "purple1",
    },
    green: {
      text: "green6",
      icon: "green4",
      bgIcon: "green1",
    },
    red: {
      text: "red6",
      icon: "red4",
      bgIcon: "red1",
    },
    yellow: {
      text: "yellow8",
      icon: "yellow5",
      bgIcon: "yellow1",
    },
  } as const;

  const { text, icon: iconColor, bgIcon } = variantColor[variant];

  return (
    <Box
      bg="white"
      mx="4"
      borderWidth={1}
      borderColor="grey2"
      height={NEWS_HEIGHT}
      width={WINDOW_DIMENSION.width - 32}
      borderRadius="xl"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      p="2"
      g="2"
    >
      <Box
        bg={bgIcon}
        borderRadius="full"
        alignItems="center"
        justifyContent="center"
        width={64}
        height={64}
      >
        <Icon name={icon} size={32} color={iconColor} />
      </Box>
      <Box flex={1} gap="2" flexDirection="column">
        <Text
          color={text}
          fontSize={20}
          fontFamily={fonts.bold}
          numberOfLines={2}
        >
          {title}
        </Text>

        <Text textAlign="justify" color={text}>
          {content}
        </Text>
      </Box>
    </Box>
  );
};
export default News;
