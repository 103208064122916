import Icon from "@Core/Icon";
import React, { ComponentProps, Fragment, memo } from "react";
import { useTranslation } from "react-i18next";
import Animated, {
  useAnimatedStyle,
  useDerivedValue,
  withTiming,
} from "react-native-reanimated";
import { priceToString } from "@Utils/fn";
import { fonts } from "@Utils/theme";
import OrderLabel from "./OrderLabel";
import Box from "./core/Box";
import Button from "./core/Button";
import Pressable from "./core/Pressable";
import Text from "./core/Text";

const orderLabel = {
  "Commande payée": "payed",
  "Votre récapitulatif de commande": "processing",
};

export type OrderPropsBase = {
  /** date in the format DD/MM/YYYY */
  date: string;
  /** order id */
  id: number;
  /** order label */
  label: string;
  /** order status */
  status: ComponentProps<typeof OrderLabel>["status"];
  /** order total price */
  total: number;
  /** products */
  products?: {
    /** product name */
    name: string;
    /** product quantity */
    quantity: number;
    /** product price */
    price: number;
    /** product sub products */
    subProducts: {
      /** sub product name */
      name?: string;
    }[];
  }[];
  /** product total price without subventions and reduction */
  productTotal: number;
  /** subventions for the order (employer) */
  subventions?: number;
  /** reduction for the order (friday discount) */
  reduction?: number;
  /** location fridge of the order */
  locationFridge?: string;
  /** if the order has already been rated */
  alreadyRated?: boolean;
  /** order contact us */
  onPressContactUs: () => void;
  /** order download */
  onPressDownload: (id: number) => void;
  /** order rating */
  onPressRating: () => void;
  /** onExpend */
  onExpend: (id: number) => void;
};

const AnimatedBox = Animated.createAnimatedComponent(Box);

const Order = ({
  date,
  id,
  label,
  alreadyRated,
  status,
  total,
  products,
  reduction,
  productTotal,
  subventions,
  locationFridge,
  onPressContactUs,
  onPressRating,
  onPressDownload,
  onExpend,
}: OrderPropsBase) => {
  const { t } = useTranslation();

  const chevronRotation = useDerivedValue(
    () => withTiming(products?.length ? 180 : 0, { duration: 200 }),
    [products?.length]
  );

  const animatedStyle = useAnimatedStyle(
    () => ({
      transform: [{ rotate: `${chevronRotation.value}deg` }],
    }),
    []
  );
  const treatedLabel =
    label === "Votre récapitulatif de commande" || label === "Commande payée"
      ? t(`History.${orderLabel[label]}`)
      : label;

  return (
    <Box py="4" bg="white" px="4" gap="2">
      <Box>
        <Pressable
          g="3"
          onPress={() => onExpend(id)}
          flexDirection="row"
          alignItems="center"
          style={({ pressed }) => ({
            opacity: pressed ? 0.5 : 1,
          })}
          hitSlop={10}
        >
          <Text color="grey10">{date}</Text>
          <Text variant="button" color="grey10">
            {priceToString(total)}
          </Text>
          <Box flex={1} />
          <OrderLabel label={treatedLabel} status={status} />
          <AnimatedBox style={animatedStyle}>
            <Icon name="chevron-down-outline" size={24} color="grey4" />
          </AnimatedBox>
        </Pressable>
      </Box>
      {!!products?.length && (
        <>
          <AnimatedBox g="2" alignItems="baseline" flexDirection="row">
            <Box bg="grey1" p="1" g="1" borderRadius="m">
              <Text color="grey7">#{id}</Text>
            </Box>
            <Text variant="legend" color="grey10">
              {t("order.orderInfo", {
                date,
                location: locationFridge,
              })}
            </Text>
          </AnimatedBox>
          <Text color="grey10">{t("order.products")}</Text>
          <Box flex={1}>
            {products?.map((product, index) => (
              <Fragment
                key={`${product.quantity === 1 ? "" : `${product.quantity} `}${
                  product.name
                }-${index + 1}`}
              >
                <Line
                  isGrey
                  label={`${product.name}`}
                  value={`${product.quantity}x ${priceToString(product.price)}`}
                />
                {product.subProducts
                  ?.filter((subProduct) => !!subProduct.name)
                  .map((subProduct, indexSub) => (
                    <Text
                      ml="2"
                      key={`${subProduct.name}-${indexSub + 1}`}
                      color="grey6"
                    >
                      {subProduct.name}
                    </Text>
                  ))}
              </Fragment>
            ))}
          </Box>
          <Box height={1} bg="grey2" />

          <Line
            label={t("order.totalProducts")}
            value={`${priceToString(productTotal)}`}
          />

          <Line
            isDisplay={!!reduction}
            label={t("order.reductions")}
            value={`-${priceToString(reduction)}`}
          />

          <Line
            isDisplay={!!subventions}
            label={t("order.subventions")}
            value={`-${priceToString(subventions)}`}
          />
          <Box height={1} bg="grey2" />
          <Line
            label={t("order.totalPaid")}
            value={priceToString(total)}
            isBold
          />
          <Box
            g="2"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text color="grey10">{t("order.issue")}</Text>
            <Box flex={1} />
            <Button
              size="small"
              width={104}
              alignItems="center"
              justifyContent="center"
              variant="secondary"
              onPress={onPressContactUs}
            >
              {t("order.contactUs")}
            </Button>
          </Box>
          {!alreadyRated ? (
            <Box
              g="2"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text color="grey10">{t("order.rateTitle")}</Text>
              <Box flex={1} />
              <Button size="small" onPress={onPressRating}>
                {t("order.rate")}
              </Button>
            </Box>
          ) : null}
          <Box
            g="2"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text color="grey10">{t("order.bill")}</Text>
            <Box flex={1} />
            <Pressable onPress={() => onPressDownload?.(id)}>
              <Text color="green6">{t("order.download")}</Text>
            </Pressable>
          </Box>
        </>
      )}
    </Box>
  );
};

const Line = ({
  label,
  value,
  isBold,
  isDisplay = true,
  isGrey,
}: {
  isDisplay?: boolean;
  label: string;
  value: string;
  isBold?: boolean;
  isGrey?: boolean;
}) =>
  isDisplay ? (
    <Box
      flexDirection="row"
      justifyContent="space-between"
      alignItems="baseline"
    >
      <Box flex={1}>
        <Text
          numberOfLines={1}
          fontFamily={isBold ? fonts.bold : fonts.regular}
          color={isGrey ? "grey6" : "grey10"}
        >
          {label}
        </Text>
      </Box>

      <Text
        fontFamily={isBold ? fonts.bold : fonts.regular}
        color={isGrey ? "grey6" : "grey10"}
      >
        {value}
      </Text>
    </Box>
  ) : null;

export default memo(
  Order,
  (prev, next) =>
    prev.products?.length === next.products?.length &&
    prev.alreadyRated === next.alreadyRated &&
    prev.id === next.id
);
