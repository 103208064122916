import Box from "@Core/Box";
import Icon from "@Core/Icon";
import Text from "@Core/Text";
import { IconsName } from "@Icons/type";
import { fonts } from "@Utils/theme";

import React, { ReactNode } from "react";

type Props = {
  title: string;
  text: ReactNode;
  icon: IconsName;
};

const EmptyState = ({ icon, text, title }: Props) => (
  <Box px="4" alignItems="center" gap="2" flex={1} justifyContent="center">
    <Box p="4" borderRadius="full" bg="purple1">
      <Icon name={icon} size={24} color="purple5" />
    </Box>
    <Text textAlign="center" variant="title">
      {title}
    </Text>
    <Text textAlign="justify" variant="subhead" fontFamily={fonts.regular}>
      {text}
    </Text>
  </Box>
);

export default EmptyState;
