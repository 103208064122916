import { OrderApi, getInvoice, ordersQuery } from "@Api/order.api";
import Container from "@Components/Container";
import EmptyState from "@Components/EmptyState";
import Order from "@Components/Order";
import Box from "@Core/Box";
import Footer, { FOOTER_HEIGHT } from "@Core/Footer";
import Loader from "@Core/Loader";
import Text from "@Core/Text";
import { AUTH_NAMES, AuthStackScreenProp } from "@Navigation/routes";
import { WINDOW_DIMENSION, defaultHeaderHeight } from "@Utils/dimensions";
import { capitalizeFirstLetter, groupBy } from "@Utils/fn";
import share from "@Utils/share";
import { FlashList, ListRenderItem } from "@shopify/flash-list";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

const ORDER_HEIGHT = 58;

const History = ({ navigation }: AuthStackScreenProp<"History">) => {
  const { t } = useTranslation();
  const { bottom, top } = useSafeAreaInsets();

  const [openedItem, setOpenedItem] = useState<number | undefined>(undefined);

  const { data: ordersData, isLoading: isLoadingOrders } =
    useQuery(ordersQuery);

  const { mutate, isLoading: isLoadingMutation } = useMutation(getInvoice, {
    onSuccess: (data, id) => share.openPdf(data, `facture_${id}`),
  });

  const handleItem = useCallback(
    (item: OrderApi) => ({
      date: item.date.format("DD/MM/YYYY"),
      id: item.id,
      productTotal: item.products.reduce((a, p) => a + p.price * p.quantity, 0),
      status: item.status,
      locationFridge: item.device.name,
      label: item.label,
      total: item.amount,
      subventions: item.subventions,
      products: item.products.map((product) => ({
        name: product.name,
        price: product.price,
        quantity: product.quantity,
        subProducts:
          product.content?.map((subProduct) => ({
            name: subProduct.name,
            price: subProduct.quantity,
          })) || [],
      })),
    }),
    []
  );

  const orders = useMemo(() => {
    if (!ordersData) {
      return [];
    }

    return Object.entries(
      groupBy(ordersData, (item) =>
        capitalizeFirstLetter(item.date.format("MMMM YYYY"))
      )
    ).flatMap(([month, items]) => [month, ...items]);
  }, [ordersData]);

  const onPressContactUs = useCallback(() => {
    navigation.navigate(AUTH_NAMES.CRISP_CHAT);
  }, [navigation]);

  const onExpendItem = useCallback(
    (id: number) => {
      setOpenedItem((prev) => (prev === id ? undefined : id));
    },
    [setOpenedItem]
  );

  const renderItems: ListRenderItem<OrderApi | string> = ({ item }) => {
    if (typeof item === "string") {
      return (
        <Box bg="white">
          <Text textAlign="center" color="grey6" pt="4">
            {item}
          </Text>
        </Box>
      );
    }

    const treatedData = handleItem(item);

    return (
      <Order
        {...treatedData}
        products={
          openedItem === treatedData.id ? treatedData.products : undefined
        }
        alreadyRated={typeof item.rate === "number"}
        onExpend={onExpendItem}
        onPressContactUs={onPressContactUs}
        onPressDownload={mutate}
        onPressRating={() => {
          navigation.navigate(AUTH_NAMES.USER_OPINION, {
            order: { ...item, date: treatedData.date },
          });
        }}
      />
    );
  };

  const EMPTY_HEIGHT =
    WINDOW_DIMENSION.height -
    FOOTER_HEIGHT -
    6 * 4 -
    bottom -
    top -
    (Platform.OS === "ios" ? defaultHeaderHeight : 0);

  return (
    <Box bg="grey1" flex={1}>
      <FlashList
        data={orders}
        extraData={openedItem}
        estimatedItemSize={ORDER_HEIGHT}
        ListHeaderComponent={<Box height={1} mt="6" bg="grey2" />}
        showsVerticalScrollIndicator={false}
        renderItem={renderItems}
        ListEmptyComponent={
          isLoadingOrders ? (
            <Container minHeight={200}>
              <Loader />
            </Container>
          ) : (
            <Container minHeight={EMPTY_HEIGHT} px="0">
              <EmptyState
                icon="leaf"
                text={t("History.emptyText")}
                title={t("History.emptyTitle")}
              />
            </Container>
          )
        }
        ListFooterComponent={
          <>
            <Box height={1} bg="grey2" />
            <Footer />
          </>
        }
      />
      {isLoadingMutation && (
        <Box
          bg="grey10"
          opacity={0.5}
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
        />
      )}
    </Box>
  );
};

export default History;
