// eslint-disable-next-line no-restricted-imports
import { createTheme, useTheme as useThemeShopify } from "@shopify/restyle";

export const fonts = {
  bold: "Lato_700Bold",
  regular: "Lato_400Regular",
  regularItalic: "Lato_400Regular_Italic",
};

export const palette = {
  green10: "#054021",
  green9: "#0e5831",
  green8: "#207246",
  green7: "#2f8155",
  green6: "#3f9165",
  green5: "#57ae80",
  green4: "#7bc49d",
  green3: "#a3d9bc",
  green2: "#c1ead5",
  green1: "#e3f9ee",
  red10: "#610404",
  red9: "#780a0a",
  red8: "#911111",
  red7: "#a61b1b",
  red6: "#ba2525",
  red5: "#d64545",
  red4: "#e66a6a",
  red3: "#f29b9b",
  red2: "#facdcd",
  red1: "#ffeeee",
  yellow10: "#513c06",
  yellow9: "#7c5e10",
  yellow8: "#a27c1a",
  yellow7: "#c99a2e",
  yellow6: "#e9b949",
  yellow5: "#f7d070",
  yellow4: "#f9da8b",
  yellow3: "#f8e3a3",
  yellow2: "#fcefc7",
  yellow1: "#fffaeb",
  purple10: "#240754",
  purple9: "#34126f",
  purple8: "#421987",
  purple7: "#51279b",
  purple6: "#653cad",
  purple5: "#724bb7",
  purple4: "#8662c7",
  purple3: "#a081d9",
  purple2: "#cfbcf2",
  purple1: "#eae2f8",
  grey1: "#f5f7fa",
  grey2: "#e4e7eb",
  grey3: "#cbd2d9",
  grey4: "#9aa5b1",
  grey5: "#7b8794",
  grey6: "#616e7c",
  grey7: "#52606d",
  grey8: "#3e4c59",
  grey9: "#323f4b",
  grey10: "#1f2933",
  white: "#FFFF",
} as const;

const theme = createTheme({
  colors: palette,
  spacing: {
    "0": 0,
    "1": 4,
    "2": 8,
    "3": 12,
    "4": 16,
    "5": 20,
    "6": 24,
    "7": 28,
    "8": 32,
    "9": 48,
    "10": 64,
    "11": 96,
  },
  breakpoints: {
    small: 0,
    phone: 390,
  },
  borderRadii: {
    full: 9999,
    m: 4,
    l: 8,
    xl: 16,
  },
  textVariants: {
    title: {
      fontSize: 32,
      lineHeight: 38,
      fontFamily: fonts.bold,
      color: "grey10",
    },
    defaults: {
      fontSize: 16,
      lineHeight: 19,
      fontFamily: fonts.regular,
      color: "grey10",
    },
    button: {
      fontSize: 16,
      lineHeight: 19,
      fontFamily: fonts.bold,
      color: "grey10",
    },
    headline: {
      fontSize: 24,
      lineHeight: 29,
      fontFamily: fonts.bold,
      color: "grey10",
    },
    subhead: {
      fontSize: 20,
      lineHeight: 24,

      color: "grey10",
    },
    input: {
      fontSize: 14,
      lineHeight: 17,
      fontFamily: fonts.regular,
      color: "grey10",
    },
    legend: {
      fontSize: 12,
      lineHeight: 14,
      fontFamily: fonts.regular,
      color: "grey10",
    },
  },
} as const);

export type Theme = typeof theme;

export type Colors = keyof Theme["colors"];

export const useTheme = useThemeShopify<Theme>;
export default theme;
