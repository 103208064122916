import { rechargeWalletQuery, walletBalanceQuery } from "@Api/wallet.api";
import Box from "@Core/Box";
import Loader from "@Core/Loader";
import { AUTH_NAMES, AuthStackScreenProp } from "@Navigation/routes";
import BankForm from "@SmartComponents/BankForm";
import { useConfettiStore } from "@SmartComponents/Confetti";
import { useShowToast } from "@SmartComponents/Toast";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useTranslation } from "react-i18next";

type WalletProps = AuthStackScreenProp<"WalletCharge">;

const WalletCharge = ({
  route: {
    params: { amount },
  },
  navigation,
}: WalletProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const showToast = useShowToast();
  const onStartAnimation = useConfettiStore((state) => state.onStartAnimation);
  const { data: walletData } = useQuery(rechargeWalletQuery(amount * 100));

  if (!walletData) return <Loader />;

  return (
    <Box flex={1} bg="white">
      <BankForm
        canGoBack={navigation.canGoBack}
        goBack={navigation.goBack}
        url={walletData.url}
        onError={() => {
          showToast({
            type: "error",
            icon: "close-circle",
            message: t("WalletCharge.errorMessage"),
            title: t("WalletCharge.errorTitle"),
          });
        }}
        onSuccess={() => {
          if (navigation.canGoBack()) navigation.goBack();
          else {
            navigation.replace(AUTH_NAMES.HOME);
          }
          setTimeout(
            () =>
              queryClient
                .refetchQueries(walletBalanceQuery.queryKey)
                .finally(() => {
                  onStartAnimation();
                }),
            2000
          );
        }}
      />
    </Box>
  );
};

export default WalletCharge;
