import { Circle, G, Svg } from "react-native-svg";
import Box from "@Core/Box";
import Text from "@Core/Text";
import { fonts, useTheme } from "@Utils/theme";
import { useTranslation } from "react-i18next";
import Container from "./Container";

const FootprintMealEmission = ({
  material,
  transport,
  packaging,
  storage,
  fridge,
}: {
  material: number;
  transport: number;
  packaging: number;
  storage: number;
  fridge: number;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const radius = 70;
  const circleCircumference = 2 * Math.PI * radius;
  const carbonEmissions = [
    {
      name: "CarbonFootprint.mealEmissionRawMaterial",
      carbon: material,
      color: theme.colors.purple6,
    },
    {
      name: "CarbonFootprint.mealEmissionTransport",
      carbon: transport,
      color: theme.colors.purple4,
    },
    {
      name: "CarbonFootprint.mealEmissionStorage",
      carbon: storage,
      color: theme.colors.purple3,
    },
    {
      name: "CarbonFootprint.mealEmissionPackaging",
      carbon: packaging,
      color: theme.colors.purple2,
    },
    {
      name: "CarbonFootprint.mealEmissionFridge",
      carbon: fridge,
      color: theme.colors.purple1,
    },
  ];
  const total = carbonEmissions.reduce((acc, curr) => acc + curr.carbon, 0);

  const treated = carbonEmissions
    .map((item) => ({
      ...item,
      percentage: (item.carbon / total) * 100,
      dashOffset:
        circleCircumference -
        (circleCircumference * (item.carbon / total) * 100) / 100,
      angle: (item.carbon / total) * 360,
    }))
    .map((item, index, array) => ({
      ...item,
      angle: array.slice(0, index).reduce((acc, curr) => acc + curr.angle, 0),
    }));

  return (
    <Container
      title={t("CarbonFootprint.mealEmission")}
      alignItems="center"
      gap="3"
    >
      <Svg height="160" width="160" viewBox="0 0 180 180">
        <G rotation={-90} originX="90" originY="90">
          {treated.map((item, index) => (
            <Circle
              key={`${index + 1}`}
              cx="50%"
              cy="50%"
              r={radius}
              stroke={item.color}
              fill="transparent"
              strokeWidth="40"
              strokeDasharray={circleCircumference}
              strokeDashoffset={item.dashOffset}
              rotation={item.angle}
              originX="90"
              originY="90"
            />
          ))}
        </G>
      </Svg>
      {treated.map((item) => (
        <Box
          alignSelf="flex-start"
          flexDirection="row"
          alignItems="center"
          gap="2"
          key={item.name}
        >
          <Box
            width={24}
            height={24}
            borderRadius="m"
            style={{
              backgroundColor: item.color,
            }}
          />
          <Text variant="legend">
            {t(item.name, {
              value: Math.round(item.percentage * 100) / 100,
            })}
          </Text>
        </Box>
      ))}
      <Text textAlign="center" fontFamily={fonts.regularItalic} color="grey8">
        {t("CarbonFootprint.mealEmissionLegend")}
      </Text>
    </Container>
  );
};

export default FootprintMealEmission;
