import Box from "@Core/Box";
import Text from "@Core/Text";
import { MEAL_TYPES, MealReheatOption, MealType } from "@Types/product";
import { priceToString } from "@Utils/fn";
import { Colors, fonts } from "@Utils/theme";
import React from "react";
import { useTranslation } from "react-i18next";

type ProductHeaderProps = {
  name: string;
  price: number;
  weight: number;
  type: MealType;
  reheat: MealReheatOption;
  isLoading: boolean;
};

const ProductHeader = ({
  name,
  type,
  price,
  weight,
  reheat,
  isLoading,
}: ProductHeaderProps) => {
  const { t } = useTranslation();

  const typeToColor = {
    [MEAL_TYPES.fish]: "yellow7",
    [MEAL_TYPES.meat]: "red7",
    [MEAL_TYPES.veggie]: "green7",
    [MEAL_TYPES.vegan]: "green7",
    [MEAL_TYPES.unknown]: "grey6",
  } satisfies Record<MealType, Colors>;

  return (
    <Box g="2">
      <Text variant="title">{name}</Text>
      <Box flexDirection="row" gap="2" alignItems="baseline">
        <Text variant="headline" fontFamily={fonts.bold}>
          {priceToString(price)}
        </Text>
        {!isLoading && (
          <>
            {type !== "unknown" && (
              <Text color={typeToColor[type]}>
                {t(`productDetails.${type}`)}
              </Text>
            )}

            <Box flex={1} />
            <Text color="grey6">
              {weight ? `${weight}g` : null}
              {reheat !== "unknown" && ` • ${t(`productDetails.${reheat}`)}`}
            </Text>
          </>
        )}
      </Box>
    </Box>
  );
};

export default ProductHeader;
