import Box from "@Core/Box";
import { WINDOW_DIMENSION } from "@Utils/dimensions";
import { useIsFocused } from "@react-navigation/native";
import React, { memo, useCallback, useEffect, useMemo, useRef } from "react";
import {
  NativeScrollEvent,
  NativeSyntheticEvent,
  ScrollView,
} from "react-native";
import Animated, {
  Easing,
  interpolate,
  runOnJS,
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import News, { NewsProps } from "./News";

type NewsCarouselProps = {
  news: (NewsProps & {
    timing: number;
  })[];
  customItems: {
    item: JSX.Element;
    timing: number;
  }[];
};

const AnimatedBox = Animated.createAnimatedComponent(Box);

const NewsCarousel = ({ news, customItems }: NewsCarouselProps) => {
  const isFocused = useIsFocused();
  const currentItem = useSharedValue(0);
  const timer = useSharedValue(0);
  const isFirstRender = useSharedValue(true);
  const ref = useRef<ScrollView>(null);

  const timings = useMemo(
    () => [
      ...customItems.map((item) => item.timing),
      ...news.map((item) => item.timing),
    ],
    [customItems, news]
  );

  const updateItem = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
    const { contentOffset } = e.nativeEvent;
    const { x } = contentOffset;

    const index = Math.round(x / WINDOW_DIMENSION.width);

    if (index === currentItem.value) return;

    currentItem.value = index;
    timer.value = 0;
  };

  const scroll = useCallback((index: number) => {
    ref.current?.scrollTo({
      x: index * WINDOW_DIMENSION.width,
      animated: true,
    });
  }, []);

  useDerivedValue(() => {
    if (timer.value === 0 && !isFirstRender.value) {
      timer.value = withTiming(1, { duration: timings[currentItem.value] });
    }
  }, [timer, isFirstRender]);

  useDerivedValue(() => {
    if (timer.value === 1) {
      if (currentItem.value === timings.length - 1) currentItem.value = 0;
      else currentItem.value += 1;
      timer.value = 0;
    }
  });

  useDerivedValue(() => {
    runOnJS(scroll)(currentItem.value);
  }, [currentItem]);

  useEffect(() => {
    if (!isFocused) {
      const currentTimer = timer.value;
      timer.value = currentTimer;
    } else {
      timer.value = withTiming(1, {
        duration: (1 - timer.value) * timings[currentItem.value],
        easing: Easing.linear,
      });
    }
    isFirstRender.value = false;
  }, [isFocused, timer, timings, isFirstRender, currentItem]);

  return (
    <Box gap="4">
      <ScrollView
        horizontal
        onTouchStart={() => {
          const currentTimer = timer.value;
          timer.value = currentTimer;
        }}
        onTouchEnd={() => {
          timer.value = withTiming(1, {
            duration: (1 - timer.value) * timings[currentItem.value],
            easing: Easing.linear,
          });
        }}
        onScrollEndDrag={() => {
          timer.value = withTiming(1, {
            duration: (1 - timer.value) * timings[currentItem.value],
            easing: Easing.linear,
          });
        }}
        ref={ref}
        onMomentumScrollEnd={updateItem}
        pagingEnabled
        showsHorizontalScrollIndicator={false}
      >
        {customItems.map((item) => item.item)}
        {news.map((item, index) => (
          <News key={`${index + 1}`} {...item} />
        ))}
      </ScrollView>
      <Box flexDirection="row" alignSelf="center" gap="2">
        {timings.map((_, index) => (
          <CarouselPoint
            key={`${index + 1}`}
            index={index}
            currentItem={currentItem}
            timer={timer}
            numberOfItems={timings.length}
          />
        ))}
      </Box>
    </Box>
  );
};

const CarouselPoint = ({
  index,
  currentItem,
  timer,
  numberOfItems,
}: {
  index: number;
  numberOfItems: number;
  currentItem: Animated.SharedValue<number>;
  timer: Animated.SharedValue<number>;
}) => {
  const width = Math.min(
    50,
    (WINDOW_DIMENSION.width - 32 - (numberOfItems - 1) * 8) /
      (numberOfItems || 1)
  );
  const animatedStyle = useAnimatedStyle(() => {
    const isCurrent = Math.round(currentItem.value) === index;
    const isBefore = currentItem.value > index;

    if (isBefore) return { width };

    if (isCurrent)
      return { width: interpolate(timer.value, [0, 1], [0, width]) };

    return { width: 0 };
  });

  return (
    <Box width={width} height={4} borderRadius="full" bg="grey3">
      <AnimatedBox
        height={4}
        bg="grey7"
        borderRadius="full"
        style={animatedStyle}
      />
    </Box>
  );
};

export default memo(NewsCarousel);
