import { SignInUser, UpdateUser } from "@Api/user.api";
import { StateCreator, create } from "zustand";
import { persist } from "zustand/middleware";
import { useEffect, useState } from "react";
import { getStorage } from "./persist";

type AuthStore = {
  signInUser?: SignInUser;
  uid?: string;
  pairingCode?: string;
  /** this parameter is used to know if the user is using the app for the first time, to show the CB screen */
  firstUsed: boolean;
  setFirstUsed: () => void;

  setUidAndCode: (uid: string, pairingCode: string) => void;
  loggedIn: (data: SignInUser) => void;
  updateInfo: (data: UpdateUser) => void;
  clear: () => void;
};

const authSlice: StateCreator<AuthStore> = (set) => ({
  signInUser: undefined,
  uid: undefined,
  loggedIn: (data) => {
    set({ signInUser: data });
  },
  setFirstUsed: () => set({ firstUsed: false }),
  firstUsed: true,
  setUidAndCode: (uid, pairingCode) => set({ uid, pairingCode }),
  clear: () =>
    set({
      signInUser: undefined,
      uid: undefined,
      pairingCode: undefined,
      firstUsed: true,
    }),
  updateInfo: (data) =>
    set((state) => ({
      ...state,
      signInUser: state.signInUser
        ? { ...state.signInUser, ...data }
        : undefined,
    })),
});

type FridgeStore = {
  fridges: number[];
  displayRestaurantTicket: boolean;
  setDisplayRestaurantTicket: (displayRestaurantTicket: boolean) => void;
  setFridges: (fridges: number[]) => void;
  clear: () => void;
};

const fridgeSlice: StateCreator<FridgeStore> = (set) => ({
  fridges: [],
  displayRestaurantTicket: true,
  setDisplayRestaurantTicket: (displayRestaurantTicket) =>
    set({ displayRestaurantTicket }),
  setFridges: (fridges) => set({ fridges }),
  clear: () => set({ fridges: [], displayRestaurantTicket: true }),
});

type LoggedOut = {
  loggedOut: () => void;
};
type UserStore = AuthStore & FridgeStore & LoggedOut;

const useUserStore = create(
  persist<UserStore>(
    (set, ...a) => ({
      ...authSlice(set, ...a),
      ...fridgeSlice(set, ...a),
      loggedOut: () => {
        // @ts-ignore
        authSlice(set).clear();
        // @ts-ignore
        fridgeSlice(set).clear();
      },
    }),
    getStorage("user")
  )
);
const useHydration = () => {
  const [hydrated, setHydrated] = useState(false);

  useEffect(() => {
    const unsubFinishHydration = useUserStore.persist.onFinishHydration(() =>
      setHydrated(true)
    );

    setHydrated(useUserStore.persist.hasHydrated());

    return () => {
      unsubFinishHydration();
    };
  }, []);

  return hydrated;
};
export { useHydration };
export default useUserStore;
