import dayjs from "dayjs";
import jwtDecode from "jwt-decode";
import { z } from "zod";

const tokenSchema = z.object({
  exp: z.number(),
  id: z.number(),
  email: z.string(),
  username: z.string(),
});

export const parseJwt = (token: string) => tokenSchema.parse(jwtDecode(token));

const getTokenExp = (token?: z.infer<typeof tokenSchema>) =>
  (token?.exp || 0) * 1000;

export const getIsTokenValid = (token?: z.infer<typeof tokenSchema>) =>
  !!(getTokenExp(token) > dayjs().valueOf());

export const getTokenExpDelay = (token?: z.infer<typeof tokenSchema>) =>
  getTokenExp(token) - dayjs().valueOf();
