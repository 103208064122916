import { EventName, TRACKING_EVENTS } from "@Utils/tracking";
import { init, track } from "@amplitude/analytics-browser";
import { useCallback } from "react";

const useTracking = () => {
  const initTracking = useCallback((id?: string) => {
    init("f4a5c16d39aa6f71eede15c4f16fd974", id, { serverZone: "EU" });
  }, []);

  const trackEvent = useCallback(
    <T extends EventName>(
      eventName: T,
      args: Parameters<typeof TRACKING_EVENTS[T]>[0]
    ) => {
      // @ts-ignore
      const event = TRACKING_EVENTS[eventName](args);
      track(event.name, event.properties);
    },
    []
  );
  return {
    initTracking,
    trackEvent,
  };
};

export default useTracking;
