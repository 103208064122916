import AuthCode from "@Screens/AuthCode";
import AuthEmail from "@Screens/AuthEmail";
import Welcome from "@Screens/Welcome";
import { PathConfig, PathConfigMap } from "@react-navigation/native";
import {
  TransitionPresets,
  createStackNavigator,
} from "@react-navigation/stack";

import FridgeSelection from "@Screens/FridgeSelection";
import { useGetScreenName, useHeaderGreen } from "./header.helper";
import { UN_AUTH_NAMES, UnAuthStackParamList } from "./routes";

const Stack = createStackNavigator<UnAuthStackParamList>();

export const pathConfigMap: PathConfigMap<UnAuthStackParamList> = {
  [UN_AUTH_NAMES.WELCOME]: "/",
  [UN_AUTH_NAMES.AUTH_EMAIL]: "email",
  [UN_AUTH_NAMES.AUTH_CODE]: "code",
};

export const configUnAuthNavigator = {
  screens: pathConfigMap,
  path: "/onboarding",
  initialRouteName: UN_AUTH_NAMES.WELCOME,
} satisfies PathConfig<UnAuthStackParamList>;

const UnAuthNavigator = () => {
  const { getScreenName } = useGetScreenName();
  const headerStyle = useHeaderGreen();

  return (
    <Stack.Navigator
      screenOptions={{
        ...headerStyle,
        ...TransitionPresets.SlideFromRightIOS,
      }}
    >
      <Stack.Screen
        name={UN_AUTH_NAMES.WELCOME}
        component={Welcome}
        options={{
          headerShown: false,
          ...getScreenName(UN_AUTH_NAMES.WELCOME),
        }}
      />
      <Stack.Screen
        name={UN_AUTH_NAMES.AUTH_EMAIL}
        component={AuthEmail}
        options={getScreenName(UN_AUTH_NAMES.AUTH_EMAIL)}
      />
      <Stack.Screen
        name={UN_AUTH_NAMES.AUTH_CODE}
        component={AuthCode}
        options={getScreenName(UN_AUTH_NAMES.AUTH_CODE)}
      />
      <Stack.Screen
        name={UN_AUTH_NAMES.FRIDGE_SELECTION}
        component={FridgeSelection}
        options={getScreenName(UN_AUTH_NAMES.FRIDGE_SELECTION)}
      />
    </Stack.Navigator>
  );
};

export default UnAuthNavigator;
