import Container from "@Components/Container";
import Image from "@Core/Image";
import Text from "@Core/Text";
import React from "react";
import { Linking, Platform } from "react-native";

import apple from "@Assets/image/stores/app-store.png";
import google from "@Assets/image/stores/google-play.png";
import Box from "@Core/Box";
import Pressable from "@Core/Pressable";
import { useTranslation } from "react-i18next";

const STORES = [
  {
    image: google,
    link: "https://play.google.com/store/apps/details?id=me.digitiz.ideelgarden",
  },
  {
    image: apple,
    link: "https://apps.apple.com/fr/app/ideel-garden/id1543899642",
  },
];
const StoreRedirection = () => {
  const isWeb = Platform.OS === "web";
  const { t } = useTranslation();
  return isWeb ? (
    <Container g="2" title={t("home.mobileFirstTitle")}>
      <Text textAlign="justify">{t("home.mobileFirstText")}</Text>
      <Box flexDirection="row" justifyContent="space-around">
        {STORES.map(({ image, link }) => (
          <Pressable key={link} onPress={() => Linking.openURL(link)}>
            <Image width={170} height={53} source={image} />
          </Pressable>
        ))}
      </Box>
    </Container>
  ) : null;
};

export default StoreRedirection;
