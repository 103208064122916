import { Colors, useTheme } from "@Utils/theme";
import React from "react";
import Animated, {
  Easing,
  interpolateColor,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import Box from "./Box";
import Pressable from "./Pressable";
import Text from "./Text";
import Loader from "./Loader";

/** TYPES */
type ButtonVariant =
  | "primary"
  | "secondary"
  | "disabled"
  | "errorPrimary"
  | "errorSecondary";

type ButtonSize = "small" | "medium";

type ButtonProps = {
  children: string;
  variant?: ButtonVariant;
  size?: ButtonSize;
  flex?: number;
  isLoading?: boolean;
  /**
   * @description When the layout is vertical you don't want to use this prop
   */
  horizontalFlex?: number;
} & React.ComponentProps<typeof Pressable>;

/** COMPONENT */
const AnimatedPressable = Animated.createAnimatedComponent(Pressable);
const AnimatedText = Animated.createAnimatedComponent(Text);

const Button = ({
  children,
  variant = "primary",
  size = "medium",
  flex = 1,
  isLoading,
  horizontalFlex,
  ...props
}: ButtonProps) => {
  const theme = useTheme();

  const colorStyles: Record<
    ButtonVariant | "loading",
    {
      bg: { origin: Colors; target: Colors };
      text: { origin: Colors; target: Colors };
    }
  > = {
    primary: {
      bg: { origin: "green6", target: "green3" },
      text: { origin: "white", target: "green7" },
    },
    secondary: {
      bg: { origin: "green1", target: "green3" },
      text: { origin: "green7", target: "green5" },
    },
    disabled: {
      bg: { origin: "grey2", target: "grey3" },
      text: { origin: "white", target: "grey5" },
    },
    loading: {
      bg: { origin: "grey2", target: "grey3" },
      text: { origin: "white", target: "grey5" },
    },
    errorPrimary: {
      bg: { origin: "red6", target: "red3" },
      text: { origin: "white", target: "red7" },
    },
    errorSecondary: {
      bg: { origin: "red1", target: "red3" },
      text: { origin: "red7", target: "red5" },
    },
  } as const;

  const { bg: interpolateBg, text: interpolateText } =
    colorStyles[isLoading ? "loading" : variant];
  const sizeStyles = {
    small: {
      paddingVertical: "1",
      paddingHorizontal: "2",
      textVariant: "legend",
      borderRadius: "m",
    },
    medium: {
      paddingVertical: "3",
      paddingHorizontal: "4",
      textVariant: "button",
      borderRadius: "l",
    },
  } as const;
  const { paddingVertical, paddingHorizontal, textVariant, borderRadius } =
    sizeStyles[size];

  const pressedValue = useSharedValue(1);

  const pressableStyle = useAnimatedStyle(
    () => ({
      transform: [{ scale: pressedValue.value }],
      backgroundColor: interpolateColor(
        pressedValue.value,
        [0.96, 1],
        [theme.colors[interpolateBg.target], theme.colors[interpolateBg.origin]]
      ),
    }),
    [variant]
  );

  const textStyles = useAnimatedStyle(
    () => ({
      color: interpolateColor(
        pressedValue.value,
        [0.96, 1],
        [
          theme.colors[interpolateText.target],
          theme.colors[interpolateText.origin],
        ]
      ),
    }),
    [variant]
  );

  return (
    <AnimatedPressable
      disabled={isLoading}
      onPressIn={() => {
        pressedValue.value = withTiming(0.96, {
          duration: 200,
          easing: Easing.ease,
        });
      }}
      onPressOut={() => {
        pressedValue.value = withTiming(1, {
          duration: 200,
          easing: Easing.ease,
        });
      }}
      overflow="hidden"
      paddingVertical={paddingVertical}
      paddingHorizontal={paddingHorizontal}
      borderRadius={borderRadius}
      style={pressableStyle}
      flexDirection="row"
      {...props}
      flex={horizontalFlex}
    >
      <>
        {flex === 1 && size !== "small" && <Box flex={1} />}
        <AnimatedText variant={textVariant} style={textStyles}>
          {children}
        </AnimatedText>
        {flex === 1 && size !== "small" && <Box flex={1} />}
        {isLoading && (
          <Box
            position="absolute"
            left={0}
            right={0}
            bottom={0}
            top={0}
            style={{ backgroundColor: interpolateBg.origin }}
          >
            <Loader noLeaf borderColor="white" size={0} padding={12} />
          </Box>
        )}
      </>
    </AnimatedPressable>
  );
};
export default Button;
