import useTracking from "@Hooks/useTracking";
import Toast from "@SmartComponents/Toast";
import useUserStore, { useHydration } from "@Stores/user.store";
import WebWrapper from "@Wrappers/WebWrapper";
import { PathConfig } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { useEffect } from "react";
import Confetti from "@SmartComponents/Confetti";
import Loader from "@Core/Loader";
import AuthNavigator, { configAuthNavigator } from "./AuthNavigator";
import UnAuthNavigator, { configUnAuthNavigator } from "./UnAuthNavigator";
import { RootStackParamList } from "./routes";

const Stack = createStackNavigator<RootStackParamList>();

export const configRootNavigator = {
  screens: {
    auth: configAuthNavigator,
    unAuth: configUnAuthNavigator,
  },
} satisfies PathConfig<RootStackParamList>;

const RootNavigator = () => {
  const id = useUserStore((state) => state.signInUser?.email);
  const token = useUserStore((state) => state.signInUser?.token);

  const hydrated = useHydration();

  const isSignUp = !!token;

  const { initTracking } = useTracking();

  useEffect(() => {
    initTracking(id);
  }, [id, initTracking]);

  if (!hydrated) return <Loader />;

  return (
    <WebWrapper>
      <Stack.Navigator screenOptions={{ headerShown: false }}>
        {isSignUp ? (
          <Stack.Screen name="auth" component={AuthNavigator} />
        ) : (
          <Stack.Screen name="unAuth" component={UnAuthNavigator} />
        )}
      </Stack.Navigator>
      <Toast />
      <Confetti />
    </WebWrapper>
  );
};

export default RootNavigator;
