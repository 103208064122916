import { Path, Svg } from "react-native-svg";
import { Icon } from "./type";

const Pork = ({ color, size }: Icon) => (
  <Svg width={size} height={size} viewBox="0 0 20 16" fill="none">
    <Path
      d="M5.3773 11.8302L1.41504 9.71698V7.07547H1.94334L2.47164 6.81132L2.99994 6.54717L3.7924 6.01887L4.05655 5.49057L4.58485 4.69811L5.11315 4.16981L6.16976 3.37736L6.43391 1.26415L6.69806 1L7.22636 1.26415L7.75466 1.79245L8.28296 2.32075L8.81127 3.11321L9.86787 2.84906L11.4528 2.58491H13.0377H14.0943L15.1509 2.84906L16.2075 3.37736L16.7358 3.90566L17.5282 4.96226L18.3207 6.54717L18.5849 8.13208V9.71698L18.3207 10.7736L18.0565 11.8302L17.5282 12.8868L16.9999 13.9434L15.9433 15H14.0943L13.8301 14.4717L13.566 12.8868L13.0377 12.3585L11.4528 12.6226H10.9245L9.07542 12.3585L8.28296 12.8868V14.4717L8.01881 15H6.43391L5.90561 14.4717L5.3773 11.8302Z"
      stroke={color}
    />
  </Svg>
);

export default Pork;
