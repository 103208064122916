import { Fridge, FridgeProduct, getFridgeQuery } from "@Api/fridge.api";
import { userQueryInfo } from "@Api/user.api";
import CarbonThumb from "@Components/CarbonThumb";
import Container from "@Components/Container";
import EmptyState from "@Components/EmptyState";
import HomeHeader from "@Components/HomeHeader";
import NewsCarousel from "@Components/NewsCarousel";
import ProductCard from "@Components/ProductCard";
import RestaurantTicketThumb from "@Components/RestaurantTicketThumb";
import Box from "@Core/Box";
import Footer, { FOOTER_HEIGHT } from "@Core/Footer";
import Icon from "@Core/Icon";
import Loader from "@Core/Loader";
import Text from "@Core/Text";
import useTracking from "@Hooks/useTracking";
import { AUTH_NAMES, AuthStackScreenProp } from "@Navigation/routes";
import AskOpinion from "@SmartComponents/AskOpinion";
import StoreRedirection from "@SmartComponents/StoreRedirection";
import useUserStore from "@Stores/user.store";
import { fonts } from "@Utils/theme";
import { FlashList, ListRenderItem } from "@shopify/flash-list";
import { useQueries, useQuery } from "@tanstack/react-query";
import { produce } from "immer";
import React, { ComponentProps, useCallback, useEffect, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { StatusBar } from "react-native";
import Animated, { FadeInUp } from "react-native-reanimated";
import { useSafeAreaInsets } from "react-native-safe-area-context";

const news = [
  {
    title: "Réduction du vendredi après midi",
    content:
      "Pour éviter le gâchis alimentaire, tout les produits sont à moins 50% tout les vendredi après midi. ",
    icon: "rocket",
    variant: "purple",
    timing: 3000,
  },
  {
    title: "Nouvelle recette",
    content:
      "Viens gouter notre nouvelle recette, Orgeotto au Bleu (AOP), Champignons & Noix (P). Et donne nous ton avis après.",
    icon: "newspaper",
    variant: "green",
    timing: 3000,
  },
  {
    title: "Réduit ton empreinte cabrone",
    content:
      "En mangeant Ideel Garden tu à la garentie d'êmettre plein de CO2 en moins. Et tu réduit tes déchets. Elle est pas belle la vie ?",
    icon: "happy",
    variant: "yellow",
    timing: 3000,
  },
  {
    title: "Pas de boeuf",
    content:
      "On ne sert pas de boeuf dans nos plats. Ses émissions de gaz à effet de serre sont 10 fois plus élevées que celles du porc et du poulet.",
    icon: "leaf",
    variant: "red",
    timing: 3000,
  },
] satisfies ComponentProps<typeof NewsCarousel>["news"];

const AnimatedBox = Animated.createAnimatedComponent(Box);

type HomeProps = AuthStackScreenProp<typeof AUTH_NAMES.HOME>;

const Home = ({ navigation }: HomeProps) => {
  const { trackEvent } = useTracking();
  const { bottom } = useSafeAreaInsets();

  const setFirstUsed = useUserStore((state) => state.setFirstUsed);

  const { t } = useTranslation();

  const fridges = useUserStore((state) => state.fridges);
  const setFridges = useUserStore((state) => state.setFridges);
  const loggedOut = useUserStore((state) => state.loggedOut);
  const displayRestaurantTicket = useUserStore(
    (state) => state.displayRestaurantTicket
  );
  const setDisplayRestaurantTicket = useUserStore(
    (state) => state.setDisplayRestaurantTicket
  );

  const { data: userFridges, error } = useQuery({
    ...userQueryInfo,
    select: (data) => data.devices,
  });

  const fridgesQueries = useQueries({
    queries: fridges.map((fridge) => getFridgeQuery(fridge)),
  });

  useEffect(() => {
    if (error) {
      loggedOut();
    }
  }, [error, loggedOut]);

  const getCategory = (category: string) =>
    ({
      type: "category",
      name: category,
    } as const);
  const getProduct = (length: number, product: FridgeProduct, index: number) =>
    ({
      name: product.name,
      price: product.price,
      image: product.img_url,
      stock: product.stock,
      hasBorderTop: index === 0,
      hasBorderBottom: index === length - 1,
      isVegetarian: product.labels.some(({ id }) => id === 2),
      id: product.id,
      type: "product",
    } as const);

  const productsData = produce(
    [] as {
      category: string;
      products: Fridge["categories"][0]["products"];
    }[],
    (draft) => {
      fridgesQueries
        .flatMap(({ data }) => data)
        .filter(Boolean)
        .forEach(({ categories }) => {
          categories.forEach((category) => {
            const indexCategory = draft.findIndex(
              (item) => item.category === category.name
            );
            if (indexCategory === -1) {
              draft.push({
                category: category.name,
                products: category.products
                  .filter((product) => product.stock !== 0)
                  .map((i) => ({ ...i })),
              });
            } else {
              category.products
                .filter((product) => product.stock !== 0)
                .forEach((product) => {
                  const indexProduct = draft[indexCategory].products.findIndex(
                    (item) => item.id === product.id
                  );
                  if (indexProduct === -1) {
                    draft[indexCategory].products.push({ ...product });
                  } else {
                    // eslint-disable-next-line no-param-reassign
                    draft[indexCategory].products[indexProduct].stock +=
                      product.stock;
                  }
                });
            }
          });
        });
    }
  )
    .filter(({ products }) => products.length !== 0)
    .flatMap(({ category, products }) => [
      getCategory(category),
      ...products.map((product, index) =>
        getProduct(products.length, product, index)
      ),
    ]);

  useEffect(() => {
    if (userFridges && fridges) {
      const newFridges = userFridges.map((device) => device.id);

      if (
        newFridges.some((fridge) => !fridges.includes(fridge)) ||
        fridges.some((fridge) => !newFridges.includes(fridge))
      ) {
        setFridges(userFridges.map((device) => device.id));
      }
    }
  }, [userFridges, setFridges, fridges]);

  useEffect(() => {
    setFirstUsed();
  }, [setFirstUsed]);

  const renderItem: ListRenderItem<(typeof productsData)[number]> = useCallback(
    ({ item }) => {
      if (item.type === "category") {
        return (
          <Box borderColor="grey2">
            <Text variant="button" ml="4" mb="2" mt="8">
              {item.name}
            </Text>
          </Box>
        );
      }

      return (
        <ProductCard
          {...item}
          onPress={() => {
            trackEvent("clickOnProductDetails", {
              id: item.id,
              name: item.name,
            });
            navigation.navigate(AUTH_NAMES.PRODUCT_DETAILS, {
              id: item.id,
              image: item.image,
              price: item.price,
              name: item.name,
            });
          }}
        />
      );
    },
    [navigation, trackEvent]
  );

  const customItems = useMemo(
    () => [
      ...(displayRestaurantTicket
        ? [
            {
              item: (
                <RestaurantTicketThumb
                  key="restaurantTicket"
                  onAddRestaurantTicket={() =>
                    navigation.navigate(AUTH_NAMES.SETTINGS)
                  }
                  onNoRestaurantTicket={() => setDisplayRestaurantTicket(false)}
                />
              ),
              timing: 5000,
            },
          ]
        : []),
      {
        item: (
          <CarbonThumb
            carbonFootprint={320}
            key="carbonFootprint"
            onPress={() => navigation.navigate(AUTH_NAMES.CARBON_FOOTPRINT)}
          />
        ),
        timing: 10000,
      },
    ],
    [navigation, setDisplayRestaurantTicket, displayRestaurantTicket]
  );

  if (!userFridges && !fridges.length) {
    return <Loader />;
  }

  if (userFridges && !fridges.length && !userFridges.length) {
    return (
      <Box flex={1} bg="grey1">
        <HomeHeader onPress={() => navigation.navigate(AUTH_NAMES.SETTINGS)} />
        <Box flex={1} px="3" mb="9">
          <EmptyState
            text={
              <Trans
                t={t}
                i18nKey="home.emptyStateText"
                components={{
                  s: <Text variant="subhead" fontFamily={fonts.bold} />,
                }}
              />
            }
            title={t("home.emptyStateTitle")}
            icon="cart-outline"
          />
        </Box>

        <AnimatedBox
          entering={FadeInUp.delay(1000)}
          position="absolute"
          bottom={FOOTER_HEIGHT + bottom}
          alignItems="center"
          width={222}
          right={16}
        >
          <Icon name="arrow-down" size={48} color="green4" />
        </AnimatedBox>
        <StatusBar
          translucent
          barStyle="dark-content"
          backgroundColor="white"
        />
      </Box>
    );
  }

  const isFridgesLoading = fridgesQueries.some(({ isFetching }) => isFetching);

  return (
    <Box bg="grey1" flex={1}>
      <HomeHeader onPress={() => navigation.navigate(AUTH_NAMES.SETTINGS)} />

      <FlashList
        showsVerticalScrollIndicator={false}
        ListHeaderComponent={
          <>
            <StoreRedirection />
            <Box mt="8" />
            <NewsCarousel news={news} customItems={customItems} />
            <AskOpinion
              onRate={(rate, order) => {
                trackEvent("clickOnAskOpinion", {
                  opinion: rate,
                  orderId: order.id,
                });
                navigation.navigate(AUTH_NAMES.USER_OPINION, {
                  initialRating: rate,
                  order,
                });
              }}
            />
          </>
        }
        data={productsData}
        ListFooterComponent={Footer}
        ListEmptyComponent={
          isFridgesLoading ? (
            <Container title={t("home.starters")} height={300}>
              <Box borderColor="grey2" />
              <Loader />
            </Container>
          ) : (
            <Container mt="8" height={300}>
              <EmptyState
                text={t("home.emptyFridgeText")}
                title={t("home.emptyFridgeTitle")}
                icon="sad-outline"
              />
            </Container>
          )
        }
        estimatedItemSize={100}
        renderItem={renderItem}
      />

      <StatusBar translucent barStyle="dark-content" backgroundColor="white" />
    </Box>
  );
};

export default Home;
