import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import fr from "../locales/fr.json";

i18n.use(initReactI18next).init({
  compatibilityJSON: "v3",
  resources: {
    fr: {
      translation: fr,
    },
  },

  fallbackLng: "fr",
  lng: "fr",
  debug: process.env.NODE_ENV === "development",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
