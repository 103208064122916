import Box from "@Core/Box";
import Button from "@Core/Button";
import Text from "@Core/Text";
import { WINDOW_DIMENSION } from "@Utils/dimensions";
import { footPrintToString } from "@Utils/fn";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { fonts } from "@Utils/theme";
import CarbonLeaf from "./CarbonLeaf";

const HEIGHT = 152;

export type CarbonThumbProps = {
  carbonFootprint: number;
  onPress: () => void;
};

const CarbonThumb = ({ carbonFootprint, onPress }: CarbonThumbProps) => {
  const { t } = useTranslation();
  const { i18nKey, tOptions } = footPrintToString(carbonFootprint);
  return (
    <Box
      bg="white"
      mx="4"
      borderWidth={1}
      borderColor="grey2"
      height={HEIGHT}
      width={WINDOW_DIMENSION.width - 32}
      borderRadius="xl"
      flexDirection="column"
      p="3"
      g="2"
    >
      <Box flexDirection="row" gap="2" alignItems="center">
        <CarbonLeaf />
        <Box flex={1} flexDirection="column">
          <Text variant="headline" fontFamily={fonts.regular} color="green6">
            <Trans
              tOptions={tOptions}
              t={t}
              i18nKey={i18nKey}
              components={{ b: <Text variant="title" color="green6" /> }}
            />
          </Text>
          <Text>{t("home.footprintMessage")}</Text>
        </Box>
      </Box>
      <Button variant="primary" onPress={onPress}>
        {t("home.footprintButton")}
      </Button>
    </Box>
  );
};
export default CarbonThumb;
