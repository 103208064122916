// custom Image component with box props

import { Theme } from "@Utils/theme";
import { AllProps, all, createRestyleComponent } from "@shopify/restyle";
import React from "react";
// eslint-disable-next-line
import { Image as RNImage } from "expo-image";

const Image = createRestyleComponent<
  React.ComponentProps<typeof RNImage> & AllProps<Theme>,
  Theme
  // @ts-ignore
>([all], RNImage);

export default Image;
