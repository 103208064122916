import { SerializedOrderApi } from "@Api/order.api";
import { NavigatorScreenParams } from "@react-navigation/native";
import { StackScreenProps } from "@react-navigation/stack";

export const AUTH_NAMES = {
  HOME: "Home",
  SETTINGS: "Settings",
  PROFILE: "Profile",
  HISTORY: "History",
  PRODUCT_DETAILS: "ProductDetails",
  CRISP_CHAT: "CrispChat",
  USER_OPINION: "UserOpinion",
  WALLET: "Wallet",
  ON_BOARDING_CREDIT_CARD: "OnBoardingCreditCard",
  ADD_CREDIT_CARD: "AddCreditCard",
  CREDIT_CARD_LIST: "CreditCardList",
  SIGN_UP_NAME: "SignUpName",
  WALLET_CHARGE: "WalletCharge",
  CARBON_FOOTPRINT: "CarbonFootprint",
} as const;

export const UN_AUTH_NAMES = {
  WELCOME: "Welcome",
  AUTH_EMAIL: "AuthEmail",
  AUTH_CODE: "AuthCode",
  FRIDGE_SELECTION: "FridgeSelection",
} as const;

export type AuthStackParamList = {
  [AUTH_NAMES.HOME]: undefined;
  [AUTH_NAMES.SETTINGS]: undefined;
  [AUTH_NAMES.PROFILE]: undefined;
  [AUTH_NAMES.HISTORY]: undefined;
  [AUTH_NAMES.PRODUCT_DETAILS]: {
    id: number;
    price: number;
    image: string;
    name: string;
  };
  [AUTH_NAMES.CRISP_CHAT]: undefined;
  [AUTH_NAMES.USER_OPINION]: {
    initialRating?: number;
    order: SerializedOrderApi;
  };
  [AUTH_NAMES.WALLET]: undefined;
  [AUTH_NAMES.ON_BOARDING_CREDIT_CARD]: undefined;
  [AUTH_NAMES.ADD_CREDIT_CARD]: undefined;
  [AUTH_NAMES.CREDIT_CARD_LIST]: undefined;
  [AUTH_NAMES.SIGN_UP_NAME]: undefined;
  [AUTH_NAMES.WALLET_CHARGE]: { amount: number };
  [AUTH_NAMES.CARBON_FOOTPRINT]: undefined;
};

export type UnAuthStackParamList = {
  [UN_AUTH_NAMES.WELCOME]: undefined;
  [UN_AUTH_NAMES.AUTH_EMAIL]: undefined;
  [UN_AUTH_NAMES.AUTH_CODE]: undefined;
  [UN_AUTH_NAMES.FRIDGE_SELECTION]: undefined;
};

export const SCREEN_NAMES = {
  ...AUTH_NAMES,
  ...UN_AUTH_NAMES,
};

export type AuthStackScreenProp<
  T extends (typeof AUTH_NAMES)[keyof typeof AUTH_NAMES]
> = StackScreenProps<AuthStackParamList, T>;

export type UnAuthStackScreenProp<
  T extends (typeof UN_AUTH_NAMES)[keyof typeof UN_AUTH_NAMES]
> = StackScreenProps<UnAuthStackParamList, T>;

export type RootStackParamList = {
  auth: NavigatorScreenParams<AuthStackParamList>;
  unAuth: NavigatorScreenParams<UnAuthStackParamList>;
};

declare global {
  namespace ReactNavigation {
    interface RootParamList extends RootStackParamList {}
  }
}
